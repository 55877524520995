// src/context/RadioContext.jsx

import { createContext, useState } from "react";

export const RadioContext = createContext();

export const RadioProvider = ({ children }) => {
  const [isRadioPlaying, setIsRadioPlaying] = useState(false);
  const [currentStationIndex, setCurrentStationIndex] = useState(0);
  const [currentStationId, setCurrentStationId] = useState(null);
  const [isRadioVisible, setIsRadioVisible] = useState(false);

  const toggleRadio = () => {
    setIsRadioVisible((prev) => {
      console.log("Toggling Radio Visibility:", !prev);
      return !prev;
    });
    setIsRadioPlaying((prev) => !prev);
  };

  const toggleRadioVisibility = () => {
    setIsRadioVisible((prev) => !prev);
  };

  const toggleRadioPlayback = () => {
    setIsRadioPlaying((prev) => !prev);
  };

  // New helper function to update the global channel
  const setChannel = (stationId, stationIndex) => {
    console.log("RadioContext: Setting channel to stationId:", stationId, "and index:", stationIndex);
    setCurrentStationId(stationId);
    setCurrentStationIndex(stationIndex);
  };

  return (
    <RadioContext.Provider
      value={{
        isRadioPlaying,
        setIsRadioPlaying,
        currentStationIndex,
        setCurrentStationIndex,
        currentStationId,
        setCurrentStationId,
        isRadioVisible,
        setIsRadioVisible,
        toggleRadio,
        toggleRadioVisibility,
        toggleRadioPlayback,
        setChannel, // Ensure this is provided to consumers.
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};