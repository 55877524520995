import { collection, addDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { saveMentions } from './saveMentions';
import { db } from '../Firebase';
/**
 * Add a comment or a reply to a post.
 * @param {string} postId - The ID of the post being commented on.
 * @param {string} commentContent - The content of the comment.
 * @param {string} userId - The ID of the user making the comment.
 * @param {string} parentCommentId - (Optional) The ID of the parent comment if this is a reply.
 */

export const addComment = async (postId, commentContent, userId, parentCommentId = null) => {
  try {
    // Validate input
    if (!postId || !userId) {
      throw new Error('Post ID or User ID is missing');
    }
    if (!commentContent.trim()) {
      console.warn('Comment content is empty. Skipping addComment.');
      return;
    }

    console.log('Adding comment:', { postId, userId, commentContent, parentCommentId });

    // Reference to the appropriate collection
    let commentsRef;
    if (parentCommentId) {
      // If it's a reply, add it to the "replies" subcollection inside the parent comment
      commentsRef = collection(db, 'posts', postId, 'comments', parentCommentId, 'replies');
    } else {
      // Otherwise, it's a direct comment on the post
      commentsRef = collection(db, 'posts', postId, 'comments');
    }

    // Add the comment/reply to Firestore
    const commentDocRef = await addDoc(commentsRef, {
      userID: userId,
      postID: postId,
      parentCommentID: parentCommentId || null, // Store parent ID if it's a reply
      content: commentContent.trim(),
      timestamp: serverTimestamp(),
    });

    console.log('Comment added successfully:', commentDocRef.id);

    // Notify the post or parent comment owner
    let recipientId;
    if (parentCommentId) {
      // Fetch the parent comment to notify its owner
      const parentCommentRef = doc(db, 'posts', postId, 'comments', parentCommentId);
      const parentComment = await getDoc(parentCommentRef);

      if (parentComment.exists()) {
        recipientId = parentComment.data().userID;
      }
    } else {
      // Notify the original post owner
      const postDocRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postDocRef);

      if (postDoc.exists()) {
        recipientId = postDoc.data().userID;
      }
    }

    // Avoid notifying the user if they're replying to their own comment/post
    if (recipientId && recipientId !== userId) {
      const notificationsRef = collection(db, 'notifications');
      await addDoc(notificationsRef, {
        read: false,
        recipientId,
        sourceId: commentDocRef.id,
        type: parentCommentId ? 'replied to' : 'commented on',
        senderId: userId,
        timestamp: serverTimestamp(),
        object: parentCommentId ? 'your comment' : 'your post',
      });

      console.log(`Notification created for user (ID: ${recipientId}) about the comment.`);
    }

    // Save mentions in the comment if any
    await saveMentions(commentContent, userId, postId);

    return commentDocRef.id; // Return the ID of the created comment
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};