//#### React ####//
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
//import { getDownloadURL, ref } from 'firebase/storage'; 

//#### Styling ####//
import styles from '../styles/SinglePostPopup.css';
import * as Icon from 'react-feather';

//####  Components ####//
import BubblePill from './BubblePill';
import CreateComment from './SinglePostPopup/CreateComment';
import Comment from './Comment';
import CommentSection from './SinglePostPopup/CommentSection';
import MessagePrompt from './MessagePrompt';
import PostActions from './PostActions';
import PostHeader from "./PostHeader";
import PostOptionsModal from './SinglePostPopup/PostOptionsModal';
import RepliesSection from './SinglePostPopup/RepliesSection';
import SecondaryButton from './SecondaryButton';
import SharePostModal from './SharePostModal';
import TrackPost from "./TrackPost";
import EventPost from "./EventPost";
import TertiaryButton from './TertiaryButton';
import profilePlaceholder from '../assets/profile-placeholder.svg';

//#### Abstracted Functions #####//
import { addComment } from '../utils/addComment';
import { fetchComments } from '../utils/fetchComments';
import { fetchLikes } from '../utils/fetchLikes';
import { fetchCommentCount } from '../utils/fetchCommentCount';
import { toggleFollow } from '../utils/toggleFollow'
import { toggleLike } from '../utils/toggleLike';
import ShimmerComment from './ShimmerComment';

const SinglePostPopup = ({ isVisible, onClose, post, isLiked, setIsLiked }) => {
  const [completePost, setCompletePost] = useState(post);
  const [comments, setComments] = useState([]);
  const [commentContent, setCommentContent] = useState('');
  const [commentCount, setCommentCount] = useState(0);
  const [commentLikes, setCommentLikes] = useState({});
  const [commentReplies, setCommentReplies] = useState({});
  const [editingPostId, setEditingPostId] = useState(null);
  const [isCommentLiked, setIsCommentLiked] = useState({});
  const [isFollowing, setIsFollowing] = useState("")
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [likeCount, setLikeCount] = useState(post.likesCount || 0);
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [promptBody, setPromptBody] = useState("Default Body");
  const [replyingToComment, setReplyingToComment] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [visibleCommentOptionsId, setVisibleCommentOptionsId] = useState(null);
  const [visibleReplyOptionsId, setVisibleReplyOptionsId] = useState(null);

  // -------------------- Modals -------------------- //
  const togglePostOptionsModal = () => setIsPostOptionsVisible(!isPostOptionsVisible);
  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);
  
  // --------------------  Post Actions -------------------- //
  const openMessagePrompt = () => {
    setPromptTitle("Delete Post");
    setPromptBody("Are you sure you want to delete this message? Once deleted you won't be able to undo it.");
    setIsMessagePromptVisible(true);
  };

  const closeMessagePrompt = () => {
    setIsMessagePromptVisible(false);
  };

  const toggleEditMode = (postId) => {
    console.log("Entering edit mode for post:", postId);
    setEditingPostId(postId);
  };
  
  const deletePostWithConfirmation = async (postId) => {
    if (!window.confirm("Are you sure you want to delete this post? This action cannot be undone.")) {
      return;
    }
  
    try {
      await deleteDoc(doc(db, "posts", postId));
      console.log(`Post ${postId} deleted successfully.`);
      onClose(); 
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  // --------------------  Core Functions -------------------- //
  useEffect(() => {
    if (post && post.id) {
      const likesRef = collection(db, 'posts', post.id, 'likes');
      const unsubscribe = onSnapshot(likesRef, (snapshot) => {
        console.log("Likes snapshot size for post", post.id, ":", snapshot.size);
        setLikeCount(snapshot.size);
      });
      return () => unsubscribe();
    }
  }, [post]);
  
  useEffect (() => {
    console.log("completePost updated:", completePost);
  }, [completePost]);
  
  useEffect(() => {
    // Check if completePost is defined and is missing required fields (e.g. userHandle)
     if (completePost && !completePost.userHandle) {
     const fetchCompletePost = async () => {
           try {
             // Use completePost.id to fetch full data from Firestore
             const docRef = doc(db, 'posts', completePost.id);
             const postSnap = await getDoc(docRef);
              if (postSnap.exists()) {
                setCompletePost({ id: postSnap.id, ...postSnap.data() });
                console.log("Fetched complete post:", { id: postSnap.id, ...postSnap.data() });
              } else {
                console.warn('Complete post not found for id:', completePost.id);
              }
            } catch (error) {
              console.error('Error fetching complete post:', error);
            }
          };
          fetchCompletePost();
      }
    }, [completePost]);

  useEffect(() => {
    console.log("Received value for isLiked: ", isLiked )
    if (!post || !post.id) {
        console.error("Invalid post object passed to SinglePostPopup:", post);
        return;
    }

    console.log("Fetching comments for post:", post.id);

    const commentsRef = collection(db, 'posts', post.id, 'comments');

    const unsubscribeComments = onSnapshot(commentsRef, async (snapshot) => {
        setCommentCount(snapshot.size);

        const fetchedComments = await fetchComments(post.id, db, storage);
        setComments(fetchedComments);

        fetchedComments.forEach((comment) => fetchCommentLikes(post.id, comment.id));
    });

    return () => {
        unsubscribeComments();
    };
}, [post]);

  const fetchCommentLikes = (postId, commentId) => {
    const likesRef = collection(db, 'posts', postId, 'comments', commentId, 'likes');

    return onSnapshot(likesRef, (snapshot) => {
            setCommentLikes((prevLikes) => ({
                ...prevLikes,
                [commentId]: snapshot.size, 
            }));


            setIsCommentLiked((prevLikes) => ({
                ...prevLikes,
                [commentId]: snapshot.docs.some((doc) => doc.id === auth.currentUser?.uid) 
            }));
        });
    };

    useEffect(() => {
        if (!post || !post.id || !isVisible) {
            return;
        }
    
        console.log("Fetching comment likes on modal open for post:", post.id);
    
        const unsubscribeCommentLikes = comments.map((comment) => 
            fetchCommentLikes(post.id, comment.id)
        );
    
        return () => {
            unsubscribeCommentLikes.forEach((unsubscribe) => unsubscribe());
        };
    }, [isVisible, post, comments]);

    const toggleCommentLike = async (postId, commentId) => {
        if (!auth.currentUser?.uid) {
            console.error("User not authenticated");
            return;
        }
    
        const likeRef = doc(db, 'posts', postId, 'comments', commentId, 'likes', auth.currentUser.uid);
    
        try {
            const likeDoc = await getDoc(likeRef);
            if (likeDoc.exists()) {
                
                await deleteDoc(likeRef);
                console.log(`Comment ${commentId} unliked by user ${auth.currentUser.uid}`);
    
                setIsCommentLiked((prev) => ({
                    ...prev,
                    [commentId]: false,  
                }));
    
                setCommentLikes((prev) => ({
                    ...prev,
                    [commentId]: Math.max((prev[commentId] || 1) - 1, 0), 
                }));
            } else {
                // Like (create a new like document)
                await setDoc(likeRef, { userId: auth.currentUser.uid, timestamp: serverTimestamp() });
                console.log(`Comment ${commentId} liked by user ${auth.currentUser.uid}`);
    
                setIsCommentLiked((prev) => ({
                    ...prev,
                    [commentId]: true, 
                }));
    
                setCommentLikes((prev) => ({
                    ...prev,
                    [commentId]: (prev[commentId] || 0) + 1, 
                }));
            }
        } catch (error) {
            console.error("Error toggling comment like:", error);
        }
    };
  
  useEffect(() => {
    
    if (!post?.userID || !auth.currentUser?.uid) {
        console.warn("Skipping follow status fetch: Missing post.userID or auth.currentUser.uid");
        return;
    }

    console.log(`Fetching follow status for userID: ${post.userID}`);

    const userRef = doc(db, "users", auth.currentUser?.uid);

    const fetchFollowStatus = async () => {
        try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const followingList = docSnap.data().following || [];
                setIsFollowing({ [post.userID]: followingList.includes(post.userID) });
                console.log(`Follow status updated: ${followingList.includes(post.userID)}`);
            }
        } catch (error) {
            console.error("Error fetching following status:", error);
        }
    };

    fetchFollowStatus();

    }, [post?.userID, auth.currentUser?.uid]); 
  
  const toggleFollow = async (userId) => {
    try {
      const userRef = doc(db, "users", auth.currentUser?.uid);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        let following = userDoc.data().following || [];
  
        if (following.includes(userId)) {
          following = following.filter((id) => id !== userId);
        } else {
          following.push(userId);
        }
  
        await setDoc(userRef, { following }, { merge: true });
        setIsFollowing((prev) => ({ ...prev, [userId]: !prev[userId] }));
      }
    } catch (error) {
      console.error("Error toggling follow:", error);
    }
  };

  if (!isVisible) return null;


/////////////////////////////////////////////////// RETURN \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ 
  return (
    <div className="single-post-overlay">
    
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <div className="container-header">
          <Link 
              to={{
                pathname: `/${completePost.userHandle}`  // Use post.userID dynamically in the URL
              }}
              onClick={() => console.log("Navigating to userID: ", post.userID)}
              style={{ textDecorationLine: 'none' }}
          >
            {/* <h2>{completePost.userHandle}</h2> */}
            {completePost && completePost.userHandle ? (
              <p>
                {completePost.userHandle}{completePost.userHandle.endsWith('s') ? "'" : "'s"} post
              </p>
              ) : (
                <p>Loading post data...</p>
            )}
           {/* <p>
              {completePost.userHandle}{completePost.userHandle.endsWith('s') ? "'" : "'s"} post
            </p> */}
          </Link>
          <div onClick={onClose}>
            <Icon.X size={22} />
          </div>
        </div>

        <hr />

        <div className="single-post-content">
          <div className="posted-by">

            <div className="posted-by-image">
              <Link 
                to={{
                  pathname: `/${completePost.userHandle}`  // Use post.userID dynamically in the URL
                }}
                onClick={() => console.log("Navigating to userID: ", post.userID)}
                style={{ textDecorationLine: 'none' }}
              >
                <img src={post.userProfilePic || profilePlaceholder} alt="User Avatar" />
              </Link>
            </div>

            <div className="posted-by-details">
              <div className="posted-by-user">
                <Link 
                  to={{
                    pathname: `/${completePost.userHandle}`  // Use post.userID dynamically in the URL
                  }}
                  onClick={() => console.log("Navigating to userID: ", post.userID)}
                  style={{ textDecorationLine: 'none' }}
                >
                  <p className="posted-by-name">{completePost.userHandle}</p>
                </Link>
                <div className="follow-button">
                  <TertiaryButton buttonLabel="+ Follow" />
                </div>
              </div>
              <span className="posted-by-date">
                
                {post.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}  
                {post.timestamp?.toDate().toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })}
              </span>
            </div>
            
            <div className="post-more-icon" onClick={togglePostOptionsModal}>
                <Icon.MoreVertical size={20} />
            </div>

            {/******************** OPTIONS MODAL *********************/}
            <div className="post-more-icon" onClick={togglePostOptionsModal}>
              <Icon.MoreVertical size={20} />
            </div>

            <PostOptionsModal
              isVisible={isPostOptionsVisible}
              togglePostOptionsModal={togglePostOptionsModal}
              post={post}
              isFollowing={isFollowing}
              toggleFollow={toggleFollow}
              toggleEditMode={toggleEditMode}
              deletePostWithConfirmation={deletePostWithConfirmation}
            />
          </div>

          {post.postType === 'track' ? (
            <TrackPost trackId={post.trackId} />
          ) : (
            <p className="post-body">{typeof post.content === 'string' ? post.content : "Content not available"}</p>
          )}

          {/* Hidden sections for TrackPost and EventPost removed */}
            
          <div className="post-actions-modal">
              
              <div className="post-data-count">
                  <span>{likeCount} Likes</span>
                  {console.log("Like Count: ", likeCount)}
                  <span> • </span>
                  <span>{commentCount} Comments</span>
              </div>
              
              <div className="like-comment-modal">
                <button onClick={toggleLike} className="like-button">
                  <Icon.ThumbsUp 
                    size={21} 
                    fill={isLiked ? '#1D599C' : 'none'} 
                    color={isLiked ? '#0094D4' : '#fff'}
                  />
                </button>
                <button className="comment-button">
                  <Icon.MessageSquare size={22} />
                </button>
                <button onClick={toggleSharePostModal} className="share-button">
                  <Icon.Share2 size={22} aria-label="Share Post" />
                </button>
              </div>
              
          </div>
      </div>

      <hr />
      <CreateComment
        postId={post.id}
        fetchCommentCount={fetchCommentCount}
        addComment={addComment}
      />
      <hr className="divider-bottom" />
      {/************************** COMMENT SECTION ***************************/}
      <CommentSection
          comments={comments}
          post={post}
          commentLikes={commentLikes}
          isCommentLiked={isCommentLiked}
          toggleCommentLike={toggleCommentLike}
          setReplyingToComment={setReplyingToComment}
          replyingToComment={replyingToComment}
          addComment={addComment}
          fetchCommentCount={fetchCommentCount}
          commentContent={commentContent}
          setCommentContent={setCommentContent}
          visibleCommentOptionsId={visibleCommentOptionsId}
          setVisibleCommentOptionsId={setVisibleCommentOptionsId}
          visibleReplyOptionsId={visibleReplyOptionsId}
          setVisibleReplyOptionsId={setVisibleReplyOptionsId}
      />
      </div>

      {isMessagePromptVisible && (
        <MessagePrompt 
          isVisible={isMessagePromptVisible} 
          onClose={closeMessagePrompt}
          cancelBtn
          promptTitle={promptTitle}
          promptBody={promptBody}
        />
      )}

      {isSharePostModalVisible && (
        <SharePostModal 
          onClose={toggleSharePostModal}
        />
      )}
    </div>
  );
};

export default SinglePostPopup;