import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";

/**
 * Saves an edited post by updating Firestore with the new content.
 * Maintains an edit history by storing previous versions.
 * 
 * @param {string} postId - The ID of the post to be edited.
 * @param {string} editedContent - The updated content of the post.
 * @param {object} db - Firestore database instance.
 * @param {Function} setPosts - Function to update the posts state in the UI.
 */

export const saveEditedPost = async (postId, editedContent, db, setPosts) => {
    console.log("saveEditedPost called with:", { postId, editedContent });
    
    try {
      if (!editedContent || typeof editedContent !== "string") {
        console.error("Edited content is missing or not a string. Update aborted.");
        return;
      }
  
      const trimmedContent = editedContent.trim();
      if (!trimmedContent) {
        console.error("Edited content is empty after trimming. Update aborted.");
        return;
      }
  
      const postDocRef = doc(db, "posts", postId);
      const postSnapshot = await getDoc(postDocRef);
  
      if (!postSnapshot.exists()) {
        console.error("Post not found in Firestore.");
        return;
      }
  
      const postData = postSnapshot.data();
      const { content, editedContent: editedContentHistory = [] } = postData;
  
      if (typeof content !== "string") {
        console.error("The `content` field in Firestore is not a string.");
        return;
      }
  
      const updatedEditedContent = [...editedContentHistory, content];
  
      await updateDoc(postDocRef, {
        content: trimmedContent,
        editedContent: updatedEditedContent,
        editedTimestamp: serverTimestamp(),
      });
  
      console.log("Post updated successfully!");
  
      // Update the local state to reflect the changes
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, content: trimmedContent, editedTimestamp: new Date() }
            : post
        )
      );
  
    } catch (error) {
      console.error("Error updating post:", error);
    }
};