import styles from '../styles/EventPost.css'
import { useEffect, useState, useRef } from "react";
import SecondaryButton from './SecondaryButton';
import EventDetailsModal from '../components/EventDetailsModal'
import * as Icon from 'react-feather';
import { db, storage } from '../Firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import eventPlaceholder from '../assets/event-placeholder.svg';

const getValidThumbnailUrl = async (thumbnail) => {
    if (!thumbnail) return null;
    if (thumbnail.startsWith('gs://')) {
        try {
            const thumbRef = ref(storage, thumbnail);
            return await getDownloadURL(thumbRef);
        } catch (error) {
            console.error('Error fetching thumbnail URL:', error);
            return null;
        }
    }
    return thumbnail;
};

const EventPost = () => {
    const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
    const [latestEvent, setLatestEvent] = useState(null);

    useEffect(() => {
        const fetchLatestEvent = async () => {
            try {
                const eventsRef = collection(db, 'events');
                const q = query(eventsRef, orderBy('timestamp', 'desc'), limit(1));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const eventData = querySnapshot.docs[0].data();
                    const resolvedThumbnail = await getValidThumbnailUrl(eventData.thumbnail);
                    setLatestEvent({
                        ...eventData,
                        thumbnail: resolvedThumbnail
                    });
                }
            } catch (error) {
                console.error('Error fetching latest event:', error);
            }
        };
        fetchLatestEvent();
    }, []);

    const toggleEventDetails = () => {
        setIsEventDetailsVisible(prev => !prev);
    };
    return (
        <div className="event-post-component">
            <div className="event-content">

                <div className='event-image'>
                    <img src={latestEvent?.thumbnail || eventPlaceholder} alt="Event" />
                </div>

                <div className='event-info'>
                    <div className='event-deets'>
                        <div className='event-body'>
                            <p className='event-title'>{latestEvent?.title || 'Event Title'}</p>
                            <p className='event-description'>{latestEvent?.description || 'No description available.'}</p>
                        </div>

                        <div className='cta-btn' onClick={toggleEventDetails}>
                            <SecondaryButton buttonLabel="Details" />
                        </div>
                    </div>
                </div>

            </div>

            <EventDetailsModal
                isVisible={isEventDetailsVisible}
                onClose={toggleEventDetails}
            />
        </div>
    );
}

export default EventPost;
