import { useEffect, useState } from 'react';
import styles from '../styles/DiscoverPeoplePanel.css';
import UserCard from '../components/UserCard';
import { doc, getDoc, getFirestore, collection, query, where, limit, getDocs } from 'firebase/firestore';
import { auth, db } from '../Firebase'; // Ensure auth is imported
const firestore = getFirestore();

const DiscoverPeoplePanel = ({ userData }) => {
  const [suggestedUsers, setSuggestedUsers] = useState([]);

  useEffect(() => {
    const fetchSuggestedUsers = async () => {
      if (userData && Array.isArray(userData.following) && userData.following.length > 0) {
        // Randomly select a user from the following list
        const randomFollowingUserId = userData.following[Math.floor(Math.random() * userData.following.length)];
        const currentUserId = auth.currentUser?.uid; // Get the authenticated user's ID

        try {
          // Fetch the user document of the randomly selected following user
          const followingUserDoc = await getDoc(doc(db, 'users', randomFollowingUserId));
          if (followingUserDoc.exists()) {
            const followingUserData = followingUserDoc.data();

            // Filter suggestions from this user's following list, excluding the authenticated user and original user's following
            const suggestions = (followingUserData.following || [])
              .filter((userId) => userId !== currentUserId && !userData.following.includes(userId))
              .slice(0, 6);

            const suggestedUsersData = await Promise.all(
              suggestions.map(async (suggestedUserId) => {
                const suggestedUserDoc = await getDoc(doc(db, 'users', suggestedUserId));
                return suggestedUserDoc.exists() ? { uid: suggestedUserId, ...suggestedUserDoc.data() } : null;
              })
            );

            setSuggestedUsers(suggestedUsersData.filter(Boolean));
          }
        } catch (error) {
          console.error('Error fetching suggested users:', error);
        }
      } else {
        console.log("No followers, fetching radio stations for friends")
        try {
          const usersQuery = query(
            collection(db, 'users'),
            where('accountType', '==', 'radioStation'),
            limit(6)
          );
          const snapshot = await getDocs(usersQuery);
          const radioStations = [];

          snapshot.forEach((doc) => {
            radioStations.push({ uid: doc.id, ...doc.data() });
          });

          setSuggestedUsers(radioStations);
          console.log("set suggestSuggestedUsers: ", suggestedUsers)
        } catch (error) {
          console.error('Error fetching radio stations:', error);
        }
      }
    };

    fetchSuggestedUsers();
  }, [userData]);

  return (
    <div className="discover-people-panel component">
      <div className="cards-container">
        <h5>Discover People and Artists</h5>

        <div className="user-cards">
          {suggestedUsers.map((user) => (
            <UserCard key={user.uid} user={user} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverPeoplePanel;