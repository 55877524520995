import styles from '../../styles/CommentSection.css';
import * as Icon from "react-feather";
import { auth, db } from '../../Firebase';
import { doc, deleteDoc, updateDoc } from "firebase/firestore";

const CommentOptionsModal = ({
  isVisible,
  setVisibleCommentOptionsId,
  comment,
  setEditingCommentId,
  setEditCommentContent,
  handleDeleteComment,
}) => {
  
  const handleSaveEditComment = async (postId, commentId, updatedContent) => {
    if (!updatedContent.trim()) return;
  
    try {
      console.log("attempting to update:", postId, " comments ", commentId)
      const commentRef = doc(db, "posts", postId, "comments", commentId); // ✅ Correct Firestore path
      //await updateDoc(commentRef, { content: updatedContent });
  
      console.log("Comment updated:", commentId);
      setEditingCommentId(null);
      setEditCommentContent("");
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="comment-options-modal">
      <ul className="comment-options-list">
        {/* Show Edit & Delete options only for the comment owner */}
        {comment.userID === auth.currentUser?.uid && (
          <>
            {/* Edit Comment Option */}
            <li
              onClick={() => {
                setEditingCommentId(comment.id);
                setEditCommentContent(comment.content);
                setVisibleCommentOptionsId(null);
              }}
              className="comment-option-li"
            >
              <Icon.Edit3 size={14} className="icon" />
              <p className="option-name">Edit Comment</p>
            </li>

            {/* Delete Comment Option */}
            <li
              onClick={() => {
                handleDeleteComment(comment.id);
                setVisibleCommentOptionsId(null);
              }}
              className="comment-option-li"
            >
              <Icon.Trash size={14} className="icon" />
              <p className="option-name">Delete Comment</p>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default CommentOptionsModal;