//********************** React **********************************//
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { collection, doc, query, where, getDocs, getDoc, updateDoc, deleteDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

//********************** Styling **********************************//
import styles from '../styles/PostFeed.css';
import * as Icon from 'react-feather';
import profilePlaceholder from '../assets/profile-placeholder.svg';

//********************** Components **********************************//
import BubblePill from './BubblePill';
import SinglePostPopup from './SinglePostPopup';
import MessagePrompt from './MessagePrompt';
import SharePostModal from './SharePostModal';
import SecondaryButton from "./SecondaryButton";
import TertiaryButton from "./TertiaryButton";

//********************** Abstracted Functions **********************************//
import { addComment } from "../utils/addComment";
import { fetchLikes } from "../utils/fetchLikes";
import parseMentions from "../utils/parseMentions";
import { toggleFollow } from "../utils/toggleFollow";
import { toggleLike } from "../utils/toggleLike";
import { saveEditedPost } from "../utils/saveEditedPost";
import TrackPost from "./TrackPost";
import EventPost from "./EventPost";
import ShimmerComment from "./ShimmerComment";

function PostFeed({ initialSelectedPostId, isTrendingActive, isFollowingActive, trendingTopic, passedUID, refreshKey, setRefreshKey }) {
  const [commentContent, setCommentContent] = useState('');
  const [commentCounts, setCommentCounts] = useState({});
  const [editingPostId, setEditingPostId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const { handle } = useParams();
  const [isFollowing, setIsFollowing] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const isProfileView = passedUID || handle;
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [isSinglePostPopupVisible, setIsSinglePostPopupVisible] = useState(false);
  const location = useLocation();
  const [likeCounts, setLikeCounts] = useState({});
  const [originalContent, setOriginalContent] = useState('');
  const [popupVisibleForPost, setPopupVisibleForPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [promptBody, setPromptBody] = useState("Default Body");
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [selectedPost, setSelectedPost] = useState(null);
  const [userData, setUserData] = useState(null);
  const [visiblePostOptionsId, setVisiblePostOptionsId] = useState(null);


  //**********************************  Modals **********************************//
  const openSinglePostPopup = (post) => {
    setSelectedPost(post);
    setIsSinglePostPopupVisible(true);
  };

  const closeSinglePostPopup = () => {
    setIsSinglePostPopupVisible(false);
    setSelectedPost(null);
  };

  const togglePostOptionsModal = (postId) => {
    setVisiblePostOptionsId((prev) => (prev === postId ? null : postId));
  };

  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);

  //********************************** Edit and Delete Posts **********************************//
  const toggleEditMode = (postId) => {
    setVisiblePostOptionsId(null);
    setEditingPostId((prevId) => (prevId === postId ? null : postId));

    const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);

    if (!posts || posts.length === 0) {
      console.warn("toggleEditMode: posts state is empty, cannot find post.");
      return;
    }

    console.log("Current posts state:", posts);

    const postToEdit = posts.find((post) => post.id === postId);
    console.log("toggleEditMode called for post:", postId, "Found post:", postToEdit);

    if (postToEdit && typeof postToEdit.content === "string") {
      setEditedContent(postToEdit.content);
    } else {
      console.warn("toggleEditMode: Post content is missing or not a string:", postToEdit?.content);
      setEditedContent('');
    }
  };

  const handleSaveEditedPost = async (postId) => {
    console.log("handleSaveEditedPost called with:", postId, "Edited Content:", editedContent);
  
    if (!editedContent || typeof editedContent !== "string") {
      console.error("handleSaveEditedPost: Edited content is invalid:", editedContent);
      return;
    }
  
    try {
      await saveEditedPost(postId, editedContent, db);
      await fetchPosts(); // Ensure latest data is pulled from Firestore
  
      // Trigger a full refresh instead of attempting to update a single post manually
      setRefreshKey(prev => prev + 1);
    } catch (error) {
      console.error("Error saving edited post:", error);
    }
  
    setEditingPostId(null);
    setEditedContent("");
  };

  const cancelEdit = () => {
    setEditingPostId(null); // Exit edit mode by clearing the editingPostId
    setEditedContent(''); // Reset the edited content state
  };

  const handleDeletePost = async (postId) => {
    const wasDeleted = await deletePostWithConfirmation(postId);
    if (wasDeleted) {
      setPosts((prev) => prev.filter((post) => post.id !== postId));
      setFilteredPosts((prev) => prev.filter((post) => post.id !== postId));
      setSelectedPost((prev) => (prev && prev.id === postId ? null : prev));
      setRefreshKey((prev) => prev + 1); // Force refetch
    }
  };

  const deletePostWithConfirmation = async (postId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    if (!confirmDelete) return false;
  
    try {
      const postDocRef = doc(db, 'posts', postId);
      const postSnapshot = await getDoc(postDocRef);
  
      if (!postSnapshot.exists()) {
        console.warn("Post not found or already deleted.");
        return false;
      }
  
      const postData = postSnapshot.data();
      const deletedDocRef = doc(db, 'deletedPosts', postId);
  
      await setDoc(deletedDocRef, {
        ...postData,
        deletedTimestamp: serverTimestamp(),
      });
  
      await deleteDoc(postDocRef);
  
      console.log(`Post ${postId} successfully deleted and archived.`);
      return true;
    } catch (error) {
      console.error("Failed to delete post:", error);
      return false;
    }
  };

  //********************************** Core Functions **********************************//
  const fetchCommentCount = async (postId) => {
    try {
      console.log("Fetching comments for post ID:", postId);
      const commentsRef = collection(db, 'posts', postId, 'comments');
      console.log("Comments collection reference:", commentsRef);

      const commentsSnapshot = await getDocs(commentsRef);
      console.log("Comments snapshot size:", commentsSnapshot.size);

      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: commentsSnapshot.size,
      }));
    } catch (error) {
      console.error("Error fetching comment count:", error);
    }
  };

  const fetchPosts = async () => {
    try {
      const postsRef = collection(db, "posts");
      let querySnapshot;

      if (isProfileView) {
        console.log("Fetching posts for profile view...");
        querySnapshot = await getDocs(query(postsRef, where("userID", "==", passedUID || auth.currentUser?.uid)));
      } else if (isTrendingActive) {
        console.log("Fetching trending posts...");
        querySnapshot = trendingTopic
          ? await getDocs(query(postsRef, where('topics', 'array-contains', trendingTopic)))
          : await getDocs(postsRef);
      } else if (isFollowingActive) {
        console.log("Fetching following posts...");
        const userDocRef = doc(db, 'users', auth.currentUser?.uid);
        const userDoc = await getDoc(userDocRef);
        const following = userDoc.exists() ? userDoc.data().following : [];

        if (following.length === 0) {
          console.log("User is not following anyone. No posts to display.");
          setPosts([]);
          setFilteredPosts([]); // Ensure filteredPosts updates too
          return;
        }

        querySnapshot = await getDocs(query(postsRef, where('userID', 'in', following)));
      }

      if (!querySnapshot || querySnapshot.empty) {
        console.log("No posts found.");
        setPosts([]);
        setFilteredPosts([]); // Ensure filteredPosts updates too
        return;
      }

      console.log(`Found ${querySnapshot.docs.length} posts.`);

      const postsWithData = await Promise.all(querySnapshot.docs.map(async (postDoc) => {
        const postData = postDoc.data();
        const userDocRef = doc(db, 'users', postData.userId || postData.userID);
        const userDoc = await getDoc(userDocRef);

        return {
          id: postDoc.id,
          ...postData,
          userHandle: userDoc.exists() ? userDoc.data().handle : 'Unknown User',
          userProfilePic: userDoc.exists() ? userDoc.data().profilePic : profilePlaceholder,
        };
      }));

      postsWithData.sort((a, b) => {
        return b.timestamp?.toDate() - a.timestamp?.toDate(); // Newest first
      });

      setPosts(postsWithData);
      setFilteredPosts(postsWithData); // Update filteredPosts as well

      postsWithData.forEach(post => fetchCommentCount(post.id));
      postsWithData.forEach(post => fetchLikes(post.id));

    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [isFollowingActive, isTrendingActive, trendingTopic, passedUID, handle, refreshKey]);

  useEffect(() => {
    if (posts.length > 0) {
      posts.forEach(post => {
        fetchLikes(post.id, db, setLikeCounts, setIsLiked, auth.currentUser?.uid);
      });
    }
  }, [posts]);


  /*
  useEffect(() => {
    if (location.state?.selectedPostId && Array.isArray(posts)) {
      const postToOpen = posts.find((post) => post.id === location.state.selectedPostId);
      console.log("Post to open:", postToOpen);
      if (postToOpen) {
        setSelectedPost(postToOpen);
        setIsSinglePostPopupVisible(true);
      }
    }
  }, [location.state?.selectedPostId, posts]); 
  */

  useEffect(() => {
    if (initialSelectedPostId && Array.isArray(posts)) {
      const postToOpen = posts.find((post) => post.id === initialSelectedPostId);
      console.log("Post to open (from initialSelectedPostId):", postToOpen);
      if (postToOpen) {
        setSelectedPost(postToOpen);
        setIsSinglePostPopupVisible(true);
      }
    }
  }, [initialSelectedPostId, posts]);

  return (
    <div className="newsfeed-card component">
      {filteredPosts.length > 0 ? filteredPosts.map((post) => (
        <div key={post.id} className="newsfeed-card-container">
          <div className="user-image">
            <Link to={`/${post.userHandle}`} style={{ textDecorationLine: 'none' }}>
              <img src={post.userProfilePic || profilePlaceholder} alt="User profile" />
            </Link>
          </div>
          <div className="post-content">
            <span className="time-ago">
              {post.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}
              {post.timestamp?.toDate().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </span>

            {/***************************************** POST OPTIONS ***********************************************/}
            <div className="post-more-icon" onClick={() => {
              console.log("Toggling modal for post ID:", post.id);
              console.log("Current visiblePostOptionsId before toggle:", visiblePostOptionsId);
              togglePostOptionsModal(post.id)
            }}>
              <Icon.MoreVertical size={20} />
            </div>
            {visiblePostOptionsId === post.id && (
              <div className="post-options-modal">
                <ul className="post-options-list">
                  {/* Conditionally render Edit and Delete options */}
                  {post.userID === auth.currentUser?.uid && (
                    <>
                      <li onClick={() => {
                        console.log("Edit Post clicked, post.id:", post.id);
                        if (!post.id) {
                          console.error("Error: post.id is undefined or invalid.");
                          return;
                        }
                        toggleEditMode(post.id);
                      }}>
                        <Icon.Edit3 size={14} className="icon" />
                        <span className="option-name">Edit Post</span>
                      </li>
                      <li onClick={() => handleDeletePost(post.id)}>
                        <Icon.Trash size={14} className="icon" />
                        <span className="option-name">Delete Post</span>
                      </li>
                    </>
                  )}
                  {auth.currentUser?.uid !== post.userID && (
                    <li>
                      {isFollowing[post.userID] ? (
                        <button
                          onClick={() => {
                            console.log("Unfollowing user with ID:", post.userID);
                            toggleFollow(post.userID, setFollowingList, setIsFollowing, followingList);
                          }}
                        >
                          <Icon.UserMinus size={14} />
                          <span className="option-name">Unfollow @{post.userHandle}</span>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            console.log("Following user with ID:", post.userID);
                            toggleFollow(post.userID, setFollowingList, setIsFollowing, followingList);
                          }}
                        >
                          <Icon.UserPlus size={14} />
                          <span className="option-name">Follow @{post.userHandle}</span>
                        </button>
                      )}
                    </li>
                  )}
                  <li>
                    <span>💩</span>
                    <span className="option-name" style={{ cursor: "not-allowed" }}>Poop this post</span>
                  </li>
                </ul>
              </div>
            )}

            <Link to={`/${post.userHandle}`} style={{ textDecorationLine: 'none' }}>
              <p className="user-handle">{post.userHandle || 'Unknown User'}</p>
            </Link>

            <div className="content-area">
              {editingPostId === post.id ? (
                <div className="edit-post-area">
                  {console.log("Rendering textarea, editedContent:", editedContent)}
                  <textarea
                    value={editedContent || ""}
                    onChange={(e) => {
                      console.log("Textarea input:", e.target.value);
                      setEditedContent(e.target.value);
                    }}
                    placeholder="Edit your post..."
                  />
                  <div className="edit-post-actions">
                    {console.log("Rendering Save button for post:", post.id)}
{/*                    <button onClick={() => {
                      console.log("Save clicked. Edited content before passing:", editedContent);
                      saveEditedPost(post.id, editedContent, db, setPosts).then(() => {
                        console.log("Post saved successfully! Exiting edit mode.");
                        setEditingPostId(null);
                        setEditedContent("");
                      }).catch((error) => {
                        console.error("Error saving edited post:", error);
                      });
                    }} className="save-button">
                      Save
                    </button>
*/}
                    <button onClick={() => handleSaveEditedPost(post.id)} className="save-button">
                      Save
                    </button>

                    <button onClick={() => cancelEdit()} className="cancel-button">
                      Cancel
                    </button>
                  </div>
                </div>
                ) : post.postType === 'track' ? (
                  <TrackPost trackId={post.trackId} />
                ) : post.postType === 'event' ? (
                  <EventPost eventId={post.eventId} />
                ) : (
                <p className="content-text">{parseMentions(post.content)}</p>
              )}
            </div>

            {/* ENABLE WHEN POST IS CATEGORIZED AS TRACK */}
            <div style={{ display: 'none' }}>
              <TrackPost />
            </div>

            {/* ENABLE WHEN POST IS CATEGORIZED AS EVENT */}
            <div style={{ display: 'none' }}>
              <EventPost />
            </div>

            {post.mediaURL && (
              <div className="photo-grid">
                                <img src={post.mediaURL} alt="Post media" style={{ maxWidth: '100%', height: 'auto', maxHeight: '500px', objectFit: 'cover' }} />
              </div>
            )}

            <div className="pill">
              {post.topics && post.topics.length > 0 && (
                post.topics
                  .filter((topic) => topic !== "Minnesota")
                  .map((filteredTopic, index) => (
                    <BubblePill key={index} bubbleText={filteredTopic} />
                  ))
              )}
            </div>

            {/***************************************** POST ACTIONS ***********************************************/}
            <div className="post-actions">
              <div className="post-data-count">
                <span>{likeCounts[post.id] || 0} Likes</span>
                <span> • </span>
                <button className="comments-view" onClick={() => openSinglePostPopup(post)}>
                  <span>{commentCounts[post.id] || 0} Comments</span>
                </button>
              </div>
              <div className="like-comment">
                {console.log("Like Status: ", isLiked[post.id])}
                <button onClick={() => toggleLike(post.id, fetchLikes)} className="like-button" title="Like">
                  {console.log("is Liked button value: ", isLiked)}
                  <Icon.ThumbsUp
                    size={21}
                    fill={isLiked[post.id] ? '#1D599C' : 'none'}
                    color={isLiked[post.id] ? '#0094D4' : '#fff'}
                  />
                </button>
                <button onClick={() => setPopupVisibleForPost(post.id)} className="comment-button" title="Comment">
                  <Icon.MessageSquare size={22} aria-label="Comment On Post" />
                </button>
                <button onClick={toggleSharePostModal} className="share-button" title="Share" style={{ cursor: 'not-allowed'}}>
                  <Icon.Share2 size={22} aria-label="Share Post" />
                </button>
              </div>
            </div>

            {popupVisibleForPost === post.id && (
              <div className="comment-popup">
                <textarea
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Say something..."
                />
                <SecondaryButton
                  buttonLabel="Submit"
                  onClick={() => {
                    console.log('Post ID:', post.id);
                    console.log('Comment Content:', commentContent);
                    console.log('User ID:', auth.currentUser?.uid);
                    addComment(post.id, commentContent, auth.currentUser?.uid)
                      .then(() => {
                        setCommentContent(''); // Clear the input field after success
                        fetchCommentCount(post.id); // Refresh comment count
                        console.log('Comment added successfully!');
                      })
                      .catch((err) => console.error('Error while adding comment:', err));
                  }}
                />
                <TertiaryButton
                  buttonLabel="Cancel"
                  onClick={() => {
                    console.log("Cancel button clicked");
                    setPopupVisibleForPost(null);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )) : (
        // <p className="no-result">No posts available</p>
        <div><ShimmerComment /></div>
      )}

      {isSinglePostPopupVisible && selectedPost && (() => {
        console.log("Passing isLiked value for selectedPost:", isLiked[selectedPost.id] || false);

        return (
          <SinglePostPopup
            isVisible={isSinglePostPopupVisible}
            onClose={closeSinglePostPopup}
            post={selectedPost}
            isLiked={isLiked[selectedPost.id] || false}
            setIsLiked={setIsLiked}
          />
        );
      })()}
    </div>
  );
}

export default PostFeed;