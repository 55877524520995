import { useEffect, useState } from 'react';
import { link, useNavigate } from 'react-router-dom';
import { collection, doc, updateDoc, getDocs, orderBy, query, where, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { auth, db, storage } from '../Firebase';
import styles from '../styles/NotificationPopup.css';
import userAvatar from '../assets/avatar.jpg'; // Placeholder avatar

const NotificationPopup = () => {
  const [notifications, setNotifications] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const navigate = useNavigate();

  // Function to fetch user data (handle and profile picture) from Firestore
  const fetchUserData = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) return { handle: 'Unknown User', profilePic: userAvatar };

      const userData = userDoc.data();
      let profilePic = userData.profilePic || userAvatar;

      // If profile picture is stored in Firebase Storage (gs://), retrieve URL
      if (profilePic.startsWith('gs://')) {
        const profilePicRef = ref(storage, profilePic);
        profilePic = await getDownloadURL(profilePicRef);
      }

      return { handle: userData.handle || 'Unknown User', profilePic };
    } catch (error) {
      console.error('Error fetching user data:', error);
      return { handle: 'Unknown User', profilePic: userAvatar };
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated.');
        return;
      }
  
      console.log('Fetching notifications with visibleCount:', visibleCount);
  
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('recipientId', '==', user.uid),
        orderBy('timestamp', 'desc')
      );
  
      try {
        const snapshot = await getDocs(q);
        console.log('Fetched documents count:', snapshot.docs.length);
  
        if (snapshot.docs.length === 0) {
          console.warn('No additional notifications found.');
        }
  
        const batchUpdates = [];
        const notificationsList = await Promise.all(snapshot.docs.map(async (notificationDoc) => {
          const notifData = notificationDoc.data();
          console.log('Processing notification:', notifData);
          
          const senderData = await fetchUserData(notifData.senderId);
          const notificationTimestamp = notifData.timestamp?.toDate
            ? notifData.timestamp.toDate()
            : new Date();

          if (!notifData.read) {
            batchUpdates.push(updateDoc(doc(db, 'notifications', notificationDoc.id), { read: true }));
          }
          
          return {
            ...notifData,
            id: notificationDoc.id,
            senderHandle: senderData.handle,
            senderPic: senderData.profilePic,
            timestamp: notificationTimestamp,
            sourceId: notifData.postId ? notifData.postId : notifData.sourceId,
            postId: notifData.postId,
          };
        }));

        if (batchUpdates.length > 0) {
          await Promise.all(batchUpdates);
        }
  
        console.log('Notifications list:', notificationsList);
        setNotifications(notificationsList);
        console.log('State updated with new notifications.');
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotifications();
  }, [visibleCount]); // Ensures notifications reload when visibleCount changes

  const markAsRead = async (notificationId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });
  };

  return (
  <div className="notification-popup component">
    <div className="container">
      {notifications.length > 0 ? (
        notifications.slice(0, visibleCount).map((notif) => (
          <div
            style={{ cursor: 'pointer' }}  
            key={notif.id}
            className={`notification-row ${notif.read ? '' : 'unread'}`}
            onClick={() => {
              if (notif.id) {
                markAsRead(notif.id);
              }
              if (notif.type === 'followed') {
                console.log('Navigating to profile:', notif.senderHandle);
                navigate(`/${notif.senderHandle}`);
              } else {
                console.log('Navigating to post:', notif.sourceId);
                navigate('/', { state: { selectedPostId: notif.sourceId } });
              }
            }}
          >
            <div className="left-aligned-content">
              <div className="notif-thumbnail">
                <img
                  src={notif.senderPic || userAvatar} 
                  alt="User Avatar"
                  className="notif-avatar"
                />
              </div>
              <div className="notif-content">
              <p className="notif-body">
                {notif.senderHandle} {notif.type}{' '}
                {notif.object
                  ? notif.object
                  : notif.type === 'followed'
                  ? 'you'
                  : notif.postId
                  ? 'your comment'
                  : 'your post'}
              </p>
              </div>
            </div>

            <div className="right-aligned-content">
              <span className="notif-timestamp">
                {notif.timestamp
                  ? new Date(notif.timestamp).toLocaleDateString('en-US') + ' | ' +
                    new Date(notif.timestamp).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })
                  : 'Just now'}
              </span>
              {!notif.read && <div className="new-notif-indicator"></div>}
            </div>
          </div>
        ))
      ) : (
        <p className="no-notifications">No new notifications</p>
      )}

      {/* Ensure the "More" button is inside the modal container */}
      {console.log("Notifications length:", notifications.length)}
      {console.log("Visible count:", visibleCount)}
      {notifications.length > visibleCount && (
        <button className="load-more-btn" onClick={() => {
          setVisibleCount(prev => {
            const newCount = prev + 5;
            console.log('Loading more notifications, new visibleCount:', newCount);
            return newCount;
          });
        }}>
          More
        </button>
      )}
    </div>
  </div>
);
};

export default NotificationPopup;