import React from 'react';
import { Link } from 'react-router-dom'; 

const parseMentions = (text) => {
  if (typeof text !== 'string') {
    console.error('Invalid input to parseMentions:', text);
    return ''; // Return an empty string if the input is not valid
  }

  const mentionRegex = /@([a-zA-Z0-9_]+)/g; // Regex to match mentions like @username
  return text.split(mentionRegex).map((part, index) => {
    if (index % 2 === 1) {
      // It's a username match
      return (       
        <Link
          to={`/${part}`} // Link to user profile
          className="mention-link"
        >
          @{part}
        </Link>
      );
    }
    return part; // Return plain text for non-mention parts
  });
};

export default parseMentions;
