import styles from '../styles/PagesMenu.css'
import Header from '../components/Header';
import eventsIcon from '../assets/menuEvents.svg'
import { Link } from 'react-router-dom';
import radioIcon from '../assets/menuRadio.svg'
import logoutIcon from '../assets/menuLogout.svg'
import * as Icon from 'react-feather';

import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';

const PagesMenu = () => {

  const navigate = useNavigate();

  // Sign out functionality
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      alert("You have successfully signed out.");
      navigate('/signin'); // Redirect to sign-in page after sign-out
    } catch (error) {
      console.error("Error signing out:", error);
      alert("An error occurred while signing out. Please try again.");
    }
  };

  return (
    <div className="pages-menu">
      <div className="menu-list">
        <h5 className="heading">Entertainment</h5>

        <Link to="/events" className="link-text">
          <div className="menu-item disabled">
              <div className="menu-icon">
                <img src={eventsIcon} />
              </div>
              <p>Events</p>
              <span style={{color:'var(--text-grey)'}}>Coming Soon</span>
          </div>
        </Link>

        <Link className="link-text">
          <div className="menu-item disabled">
              <div className="menu-icon">
                <img src={radioIcon} />
              </div>
              <p>Radio</p>
              <span style={{color:'var(--text-grey)'}}>Coming Soon</span>
          </div>
        </Link>

        <hr style={{display: 'block'}} />

        <div to="/radio" className="link-text" onClick={handleSignOut}>
          <div className="menu-logout">
              <div className="menu-icon">
                {/*<img src={logoutIcon} />*/}
              <Icon.LogOut size={16} />
              </div>
              <p>Sign Out</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PagesMenu;
