import { collection, doc, getDocs, getDoc, query, orderBy } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import profilePlaceholder from '../assets/profile-placeholder.svg';

/**
 * Fetches comments for a post along with user data for each comment.
 * @param {string} postId - The ID of the post.
 * @param {object} db - Firestore database instance.
 * @param {object} storage - Firebase storage instance.
 * @returns {Promise<Array>} - List of comments with user data.
 */
export const fetchComments = async (postId, db, storage) => {
  try {
    if (!postId || typeof postId !== 'string') {
      console.error("fetchComments error: Invalid postId", postId);
      return [];
    }

    console.log(`Fetching comments for post ID: ${postId}`);

    const commentsRef = collection(db, 'posts', postId, 'comments');
    const q = query(commentsRef, orderBy('timestamp', 'desc'));
    const commentsSnapshot = await getDocs(q);

    console.log(`Fetched ${commentsSnapshot.size} comments.`);

    const comments = await Promise.all(
      commentsSnapshot.docs.map(async (commentDoc) => {
        const commentData = commentDoc.data();

        if (!commentData.content || typeof commentData.content !== 'string') {
          console.warn(`Invalid content in comment ID: ${commentDoc.id}`, commentData.content);
          commentData.content = "Invalid comment content"; 
        }

        let commentUserHandle = 'Unknown User';
        let commentUserProfilePic = profilePlaceholder;

        if (commentData.userID) {
          const commentUserDocRef = doc(db, 'users', commentData.userID);
          const commentUserDoc = await getDoc(commentUserDocRef);

          if (commentUserDoc.exists()) {
            const commentUserData = commentUserDoc.data();
            commentUserHandle = commentUserData.handle || 'Unknown User';

            let profilePicUrl = commentUserData.profilePic || profilePlaceholder;
            if (profilePicUrl.startsWith('gs://')) {
              try {
                const profilePicRef = ref(storage, profilePicUrl);
                profilePicUrl = await getDownloadURL(profilePicRef);
              } catch (error) {
                console.error("Error fetching profile picture:", error);
                profilePicUrl = profilePlaceholder;
              }
            }
            commentUserProfilePic = profilePicUrl;
          } else {
            console.warn(`User document not found for userID: ${commentData.userID}`);
          }
        } else {
          console.warn(`Comment ID ${commentDoc.id} has no userID.`);
        }

        const repliesRef = collection(db, 'posts', postId, 'comments', commentDoc.id, 'replies');
        const repliesSnapshot = await getDocs(query(repliesRef, orderBy('timestamp', 'asc')));

        const replies = await Promise.all(
          repliesSnapshot.docs.map(async (replyDoc) => {
            const replyData = replyDoc.data();

            let replyUserHandle = 'Unknown User';
            let replyUserProfilePic = profilePlaceholder;

            if (replyData.userID) {
              const replyUserDocRef = doc(db, 'users', replyData.userID);
              const replyUserDoc = await getDoc(replyUserDocRef);

              if (replyUserDoc.exists()) {
                const replyUserData = replyUserDoc.data();
                replyUserHandle = replyUserData.handle || 'Unknown User';
                let profilePicUrl = replyUserData.profilePic || profilePlaceholder;

                if (profilePicUrl.startsWith('gs://')) {
                  try {
                    const profilePicRef = ref(storage, profilePicUrl);
                    profilePicUrl = await getDownloadURL(profilePicRef);
                  } catch (error) {
                    console.error("Error fetching profile picture:", error);
                    profilePicUrl = profilePlaceholder;
                  }
                }
                replyUserProfilePic = profilePicUrl;
              }
            }

            return {
              id: replyDoc.id,
              content: replyData.content,
              userID: replyData.userID || "unknown",
              replyUserHandle,
              replyUserProfilePic,
              timestamp: replyData.timestamp || null,
            };
          })
        );

        return {
          id: commentDoc.id,
          content: commentData.content,
          userID: commentData.userID || "unknown",
          commentUserHandle,
          commentUserProfilePic,
          timestamp: commentData.timestamp || null,
          replies, 
        };
      })
    );

    console.log("Processed comments with replies:", comments);
    return comments;
  } catch (error) {
    console.error('Error fetching comments and replies:', error);
    return [];
  }
};