import styles from '../styles/Profile.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { collection, documentId, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

import Header from '../components/Header';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import Follows from '../components/Profile/Follows';
import ProfileNewsFeedCard from '../components/NewsfeedCard-profile';
import CreatePost from '../components/CreatePost';
import TopBubbles from '../components/TopBubbles';
import DiscoverPeoplePanel from '../components/DiscoverPeoplePanel';
import FeaturedSection from '../components/Profile/FeaturedSection';
import CreateMessageModal from '../components/CreateMessageModal';
import PostFeed from '../components/PostFeed';  

import profilePlaceholder from '../assets/profile-placeholder.svg';
import postsIconActive from '../assets/feed-icon-active.svg';
import postsIconInactive from '../assets/feed-icon-inactive.svg';
import userIconActive from '../assets/user-icon-active.svg';
import userIconInactive from '../assets/user-icon-inactive.svg';
import peopleIconActive from '../assets/people-icon-active.svg';
import peopleIconInactive from '../assets/people-icon-inactive.svg';
import quoteIcon from '../assets/quote-icon.svg';
import mapPinIcon from '../assets/map-pin-icon.svg';
import BubblePill from '../components/BubblePill';
import TertiaryButton from '../components/TertiaryButton';

const Profile = ({ showPosts, showInfo, showFollows }) => {
  const [admins, setAdmins] = useState('');
  const { handle } = useParams(); // Get handle from URL
  const [UID, setUID] = useState(null); // UID will be set after fetching based on handle

  const [viewUserName, setViewUserName] = useState('');
  const [viewProfilePic, setViewProfilePic] = useState('');
  const [viewUserHandle, setViewUserHandle] = useState('');
  const [viewUserAccountType, setViewUserAccountType] = useState('');
  const [viewUserBio, setViewUserBio] = useState('');
  const [viewUserLocation, setViewUserLocation] = useState('');
  const [trendingTopic, setTrendingTopic] = useState('');

  const [isPostsActive, setIsPostsActive] = useState(false);
  const [isInfoActive, setIsInfoActive] = useState(true);
  const [isFollowsActive, setIsFollowsActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);

  const authUser = auth.currentUser;
  const isCurrentUserProfile = authUser?.uid === UID;

  const [isCreateMessageVisible, setIsCreateMessageVisible] = useState(false);
  const toggleCreateMessagePopup = () => setIsCreateMessageVisible(!isCreateMessageVisible);
  
  // Fetch UID based on handle
  useEffect(() => {
    const fetchUID = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('handle', '==', handle));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setUID(userDoc.id); // Set UID from the document ID
        } else {
          console.warn("No user found with handle:", handle);
        }
      } catch (error) {
        console.error('Error fetching UID based on handle:', error);
      }
    };

    fetchUID();
  }, [handle]);

  useEffect(() => {
    const fetchAdmins = async() => {
      const adminsRef = collection(db, 'auth')
      const q = query(adminsRef, where(documentId(), '==', "admins"))
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        if (Array.isArray(docData.Global)) {
          setAdmins(docData.Global);
          console.log("admins: ", docData.Global);
        } else {
          console.warn("Global field is not an array:", docData.Global);
        }
      }
    }

   fetchAdmins(); 
  })

  // Fetch user data after UID is set
  useEffect(() => {
    const fetchUserData = async () => {
      if (!UID) return;

      try {
        const userDocRef = doc(db, 'users', UID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setViewUserName(userData.userName);
          setViewUserHandle(userData.handle);
          setViewUserBio(userData.bio);
          setViewUserLocation(userData.location);
          setViewUserAccountType(userData.accountType);

          const profilePicUrl = userData.profilePic
            ? await getDownloadURL(ref(storage, userData.profilePic))
            : profilePlaceholder;
          setViewProfilePic(profilePicUrl);
        } else {
          console.log('No document found for UID:', UID);
        }
      } catch (error) {
        console.error('Error fetching user data from Firestore:', error);
      }
    };

    fetchUserData();
  }, [UID]);

  const addPost = (newPost) => {
    setPosts((prevPosts) => [newPost, ...prevPosts]);
  }

  const postsActive = () => {
    setIsPostsActive(true);
    setIsInfoActive(false);
    setIsFollowsActive(false);
  };

  const infoActive = () => {
    setIsPostsActive(false);
    setIsInfoActive(true);
    setIsFollowsActive(false);
  };

  const followsActive = () => {
    setIsPostsActive(false);
    setIsInfoActive(false);
    setIsFollowsActive(true);
  };

  return (
    <div className="profile-page"> 
      <Header goBack pageTitle="Profile" />
      <div className="profile-page-content">
        
        <div className="profile-mobile-head">
          <div className="profile-mobile-userphoto">
            <img src={viewProfilePic || profilePlaceholder} className="header-avatar" alt="Profile" />
          </div>

          <div className="profile-mobile-navigation">
            <div className="posts-icon nav-icon" onClick={postsActive}>
              <img src={isPostsActive ? postsIconActive : postsIconInactive} alt="Posts Icon" />
              <div className="profile-nav-indicator" style={{ backgroundColor: isPostsActive ? 'var(--acc-blue)' : 'transparent' }}></div>
            </div>

            <div className="info-icon nav-icon" onClick={infoActive}>
              <img src={isInfoActive ? userIconActive : userIconInactive} alt="Info Icon" />
              <div className="profile-nav-indicator" style={{ backgroundColor: isInfoActive ? 'var(--acc-blue)' : 'transparent' }}></div>
            </div>

            <div className="people-icon nav-icon" onClick={followsActive}>
              <img src={isFollowsActive ? peopleIconActive : peopleIconInactive} alt="People Icon" />
              <div className="profile-nav-indicator" style={{ backgroundColor: isFollowsActive ? 'var(--acc-blue)' : 'transparent' }}></div>
            </div>
          </div>
        </div>

        {isPostsActive && (
          <div className="user-posts">
            {isCurrentUserProfile && <CreatePost userData={userData} addPost={addPost}/>}
            
            {isPostsActive && trendingTopic !== '' && (
              <div className="active-bubble">
                <div className="left-texts">
                  <p className="text">Showing posts of</p>
                  <BubblePill bubbleText={trendingTopic} />
                  <p className="text">bubble.</p>
                </div>
                <div className="right-texts">
                  <TertiaryButton buttonLabel="Clear" onClick={() => setTrendingTopic('')} />
                </div>
              </div>
            )}
            
            <PostFeed passedUID={UID} trendingTopic={trendingTopic} /> {/* Pass UID directly */}
          </div>
        )}

       

        {isInfoActive && (
          <div className="profile-main-content">
            <div className="user-profile-info">
              <div className="name-and-cta">
                <div className="names">
                  <h3 className="name">{viewUserName || 'Anonymous User'}</h3>
                  <p className="userhandle">@{viewUserHandle || 'no-handle'}</p>
                </div>

                <div className="cta">
                  {isCurrentUserProfile || admins.includes(authUser?.uid) && 
                  <div className="edit-profile-button">
                    <Link to="/edit_profile" style={{ textDecorationLine: 'none' }} state={{ editUID: UID}}>
                      <SecondaryButton buttonLabel="Edit Profile" />
                    </Link>
                  </div>
                  }

                  {!isCurrentUserProfile && (
                  <div className="follow-unfollow-button user-info-cta">
                    <PrimaryButton buttonLabel="+ Follow" />
                  </div>
                  )}

                  {!isCurrentUserProfile && (<div className="message-button user-info-cta">
                    <Link to="" style={{ textDecorationLine: 'none' }}>
                      <SecondaryButton buttonLabel="Message" onClick={toggleCreateMessagePopup} />
                    </Link>
                  </div>
                  )}
                </div>
                  
              </div>

              <hr />

              <div className="user-profile-details">
                <img src={quoteIcon} alt="Quote Icon" style={{marginTop:'3px'}}/>
                <p>{viewUserBio}</p>
                <img src={quoteIcon} alt="Quote Icon" style={{marginTop:'3px'}}/> {/*I put this here on purpose */}
              </div>

              <hr />

              <div className="user-profile-details">
                <img src={mapPinIcon} alt="Location Icon" style={{marginTop:'3px'}}/>
                <p>{viewUserLocation}</p>
              </div>
            </div>

            <div className="profile-featured-section">
              {UID && <FeaturedSection  viewUserName={viewUserName} userId={UID}/>}
              {console.log("Passing UID: ", UID)}
            </div>
          </div>
        )}

        {isFollowsActive && (
          <div className="user-profile-follows">
            <Follows passedUID={UID} />
          </div>
        )}

        <div className="discover-panel">
        <TopBubbles setTrendingTopic={setTrendingTopic} />
          <DiscoverPeoplePanel />
        </div>
      </div>
    </div>
  );
};

export default Profile;