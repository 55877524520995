// utils/toggleLike.js
import { auth, db } from '../Firebase'; // Ensure the correct path
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  setDoc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Toggles the like status for a post or a comment and sends a notification if necessary.
 * @param {string} postId - The ID of the post being liked/unliked.
 * @param {string} targetId - The ID of the post or comment being liked/unliked.
 * @param {boolean} isComment - True if liking a comment, false if liking a post.
 * @param {function} fetchLikes - Callback to refresh the like count.
 */
export const toggleLike = async (postId, targetId, isComment = false, fetchLikes) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    // Determine Firestore path based on whether it's a post or a comment
    const likesRef = isComment
      ? collection(db, 'posts', postId, 'comments', targetId, 'likes')
      : collection(db, 'posts', postId, 'likes');

    const likeDocRef = doc(likesRef, user.uid);
    const likeDoc = await getDoc(likeDocRef);
    console.log(`${isComment ? "Comment" : "Post"} ID: `, targetId);

    // Get the post or comment owner
    let ownerId = null;
    if (isComment) {
      const commentDocRef = doc(db, 'posts', postId, 'comments', targetId);
      const commentDoc = await getDoc(commentDocRef);
      if (!commentDoc.exists()) {
        console.warn(`Comment with ID ${targetId} not found. Skipping like action.`);
        return;
      }
      ownerId = commentDoc.data().userID;
    } else {
      const postDocRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postDocRef);
      if (!postDoc.exists()) {
        console.warn(`Post with ID ${postId} not found. Skipping like action.`);
        return;
      }
      ownerId = postDoc.data().userID;
    }

    const notificationsCollection = collection(db, 'notifications');

    if (likeDoc.exists()) {
      // Unlike: Remove like and delete notification
      await deleteDoc(likeDocRef);
      console.log("Like removed.");

      // Remove existing like notification
      const existingNotifQuery = query(
        notificationsCollection,
        where("recipientId", "==", ownerId),
        where("senderId", "==", user.uid),
        where("sourceId", "==", targetId),
        where("type", "==", isComment ? "liked_comment" : "liked")
      );

      const existingNotifs = await getDocs(existingNotifQuery);
      existingNotifs.forEach(async (notif) => {
        await deleteDoc(doc(db, 'notifications', notif.id));
      });

      // Preserve hasNotified flag so a relike doesn't spam notifications
      await setDoc(likeDocRef, { hasNotified: true }, { merge: true });

    } else {
      // Like: Add like and send notification (only if not already sent)
      await setDoc(likeDocRef, {
        userID: user.uid,
        postID: postId,
        timestamp: serverTimestamp(),
        hasNotified: true, // Prevent duplicate notifications
      });

      if (ownerId !== user.uid) {
        const existingNotifQuery = query(
          notificationsCollection,
          where("recipientId", "==", ownerId),
          where("senderId", "==", user.uid),
          where("sourceId", "==", targetId),
          where("type", "==", isComment ? "liked_comment" : "liked")
        );

        const existingNotifs = await getDocs(existingNotifQuery);
        
        if (existingNotifs.empty) {
          // Send notification only if it doesn't already exist
          await addDoc(notificationsCollection, {
            read: false,
            recipientId: ownerId,
            sourceId: targetId,
            type: isComment ? "liked_comment" : "liked",
            senderId: user.uid,
            timestamp: serverTimestamp(),
            object: isComment ? "your comment" : "your post",
          });

          console.log(`Notification created for ${isComment ? "comment" : "post"} owner (ID: ${ownerId}) about the like.`);
        }
      }
    }

    // Fetch the updated like count
    if (fetchLikes) {
      fetchLikes(postId, targetId, isComment);
    }
  } catch (error) {
    console.error("Error toggling like:", error);
  }
};