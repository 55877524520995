import React, { useEffect } from 'react';
import PrimaryButton from './PrimaryButton';

function EventAttendance({
  attendees,
  declines,
  fetchDeclines,
  selectedEvent,
  emailInput,
  handleEmailChange,
  handleEmailSubmit,
  fileContent,
  handleFileChange,
  handleFileSubmit
}) {
  
  // Fetch declines when the component mounts
  useEffect(() => {
    if (selectedEvent) {
      fetchDeclines(selectedEvent);
    }
  }, [selectedEvent, fetchDeclines]);

  return (
    <div className="attendace-wrapper">
        <div className="attendees-section">
        <h3>Attendees</h3>
        {attendees.length > 0 ? (
            <table className="attendees-table">
            <thead>
                <tr>
                <th>Name</th>
                <th>Handle</th>
                <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {attendees.map((attendee) => {
                console.log("Rendering attendee:", attendee);

                let formattedDate = "N/A";
                let formattedTime = "N/A";

                if (typeof attendee.timestamp === "string") {
                    try {
                    const timestamp = new Date(attendee.timestamp);
                    formattedDate = timestamp.toLocaleDateString('en-US');
                    formattedTime = timestamp.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    });
                    } catch (error) {
                    console.error("Error converting timestamp string for attendee:", attendee, error);
                    }
                } else if (attendee.timestamp?.seconds) {
                    try {
                    const timestamp = new Date(attendee.timestamp.seconds * 1000);
                    formattedDate = timestamp.toLocaleDateString('en-US');
                    formattedTime = timestamp.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    });
                    } catch (error) {
                    console.error("Error formatting Firestore timestamp for attendee:", attendee, error);
                    }
                } else {
                    console.warn("Missing or invalid timestamp for attendee:", attendee);
                }

                return (
                    <tr key={attendee.id}>
                    <td>{attendee.name}</td>
                    <td>
                        <a 
                        href={`/${attendee.handle}`}  
                        className="mention-link"
                        >
                        {attendee.handle}
                        </a>
                    </td>
                    <td>{formattedDate} {' | '} {formattedTime}</td>
                    </tr>
                );
                })}
            </tbody>
            </table>
        ) : (
            <p>No attendees yet.</p>
        )}

        {/* Input Email & File Upload Sections */}
        <div className="auth-container">
            <div className="input-email-section">
            <h3>Manual Add</h3>
            <div className="form">
                <input type="email" value={emailInput} onChange={handleEmailChange} placeholder="Enter email" />
                <div className="button">
                <PrimaryButton onClick={handleEmailSubmit} buttonLabel="Add Email" />
                </div>
            </div>
            </div>

            <div className="file-upload-section">
            <h3>Bulk Upload .txt or .csv File</h3>
            <div className="form">
                <input type="file" accept=".txt, .csv" onChange={handleFileChange} />
                <div className="button">
                <PrimaryButton onClick={handleFileSubmit} buttonLabel="Upload File" />
                </div>
            </div>
            </div>
        </div>

        </div>  

        <div className="declines-section">
        {/* Declines Section */}
        <h3>Haters</h3>
        {declines.length > 0 ? (
            <table className="declines-table">
            <thead>
                <tr>
                <th>Name</th>
                <th>Handle</th>
                <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {declines.map((decline) => {
                console.log("Rendering decline:", decline);

                let formattedDate = "N/A";
                let formattedTime = "N/A";

                if (typeof decline.timestamp === "string") {
                    try {
                    const timestamp = new Date(decline.timestamp);
                    formattedDate = timestamp.toLocaleDateString('en-US');
                    formattedTime = timestamp.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    });
                    } catch (error) {
                    console.error("Error converting timestamp string for decline:", decline, error);
                    }
                } else if (decline.timestamp?.seconds) {
                    try {
                    const timestamp = new Date(decline.timestamp.seconds * 1000);
                    formattedDate = timestamp.toLocaleDateString('en-US');
                    formattedTime = timestamp.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    });
                    } catch (error) {
                    console.error("Error formatting Firestore timestamp for decline:", decline, error);
                    }
                } else {
                    console.warn("Missing or invalid timestamp for decline:", decline);
                }

                return (
                    <tr key={decline.id}>
                    <td>{decline.name}</td>
                    <td>
                        <a 
                        href={`/${decline.handle}`}  
                        className="mention-link"
                        >
                        {decline.handle}
                        </a>
                    </td>
                    <td>{formattedDate} {' | '} {formattedTime}</td>
                    </tr>
                );
                })}
            </tbody>
            </table>
        ) : (
            <p>No declines yet.</p>
        )}
        </div>
    </div>
  );
}

export default EventAttendance;