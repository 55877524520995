import styles from '../../styles/CommentSection.css';
import { useState } from "react";
import { auth, db } from "../../Firebase";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import profilePlaceholder from "../../assets/profile-placeholder.svg";
import RepliesSection from "./RepliesSection";
import SecondaryButton from "../SecondaryButton";
import CommentOptionsModal from "./CommentOptionsModal";
import ShimmerComment from '../ShimmerComment';

const CommentSection = ({
  comments,
  post,
  commentLikes,
  isCommentLiked,
  toggleCommentLike,
  setReplyingToComment,
  replyingToComment,
  addComment,
  fetchCommentCount,
  commentContent,
  setCommentContent,
  visibleCommentOptionsId,
  setVisibleCommentOptionsId,
  visibleReplyOptionsId,
  setVisibleReplyOptionsId
}) => {
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState("");
  const [editingPostId, setEditingPostId] = useState('');
  const [replies, setReplies] = useState('');

  const handleEditComment = async (commentId) => {
    if (!editCommentContent.trim()) return;

    try {
      console.log("Attempting to save edit to: posts", post.id, " comments ", commentId)
      const commentRef = doc(db, "posts", post.id, "comments", commentId);
      await updateDoc(commentRef, { content: editCommentContent });

      console.log("Comment updated:", commentId);
      setEditingCommentId(null);
      setEditCommentContent("");
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const commentRef = doc(db, "posts", post.id, "comments", commentId);
      await deleteDoc(commentRef);

      console.log("Comment deleted:", commentId);
      setVisibleCommentOptionsId(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleToggleOptionModal = () => {
    setVisibleCommentOptionsId((prevState) => !prevState);
  }

  const maxLength = 350;
  const handleMaxLength = (e) => {

    let newValue = e.target.value;

    // Enforce maxLength by slicing the string if necessary
    if (newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setCommentContent(newValue);

    // if (e.target.value.length <= maxLength) {
    //   setCommentContent(e.target.value);
    // }
  };

  return (
    <div className="comment-section">
      {comments.map((comment) => (
        <div key={comment.id} className="single-comment">
          
          <div className="commented-by">
          
            {/* Profile Picture */}
            <div className="commented-by-image">
              <Link to={`/${comment.commentUserHandle}`}>
                <img
                  src={comment.commentUserProfilePic || profilePlaceholder}
                  alt="User Avatar"
                />
              </Link>
            </div>

            {/* Comment Details */}

            <div className='comment-and-replies'>
              <div className="commented-by-details">
                <div className="commented-by-user">
                  <div className="name-and-dots">

                    <div className='with-timestamp'>
                      <Link to={`/${comment.commentUserHandle}`} style={{ textDecoration: "none" }}>
                        <p className="commented-by-name">{comment.commentUserHandle || "Unknown User"}</p>
                      </Link>
                      <span className="commented-by-date">
                        {comment.timestamp && typeof comment.timestamp.toDate === "function"
                          ? `${new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }).format(comment.timestamp.toDate())} | ${
                              new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }).format(comment.timestamp.toDate())
                            }`
                          : "N/A"}
                      </span>
                    </div>
                    
                    {/* Three Dots Icon for Comment Options */}
                    <div
                      className="comment-more-icon"
                      onClick={() => setVisibleCommentOptionsId(comment.id)}
                    >
                      <Icon.MoreVertical size={16} />

                      <div className="options-modal">
                        <CommentOptionsModal
                          isVisible={visibleCommentOptionsId === comment.id}
                          setVisibleCommentOptionsId={setVisibleCommentOptionsId}
                          comment={comment}
                          setEditingCommentId={setEditingCommentId}
                          setEditCommentContent={setEditCommentContent}
                          handleDeleteComment={handleDeleteComment}
                        />
                      </div>

                      
                    </div>
                  </div>

                  
                </div>

                {editingCommentId === comment.id ? (
                  <div className="edit-comment-input">
                    <textarea
                      value={editCommentContent}
                      onChange={(e) => setEditCommentContent(e.target.value)}
                      placeholder="Edit your comment..."
                      maxLength={300}
                    />
                    <div className="edit-comment-buttons">
                      <button onClick={() => handleEditComment(comment.id)}>Post Comment</button>
                      <button onClick={() => setEditingCommentId(null)}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className="comment-body">
                    <p className='comment-text'>{typeof comment.content === "string" ? comment.content : "[Invalid comment data]"}</p>
                  </div>
                )}

                

                {/* Like & Reply Buttons */}
                <div className="like-comment">
                  {/* Like Button */}
                  <button onClick={() => toggleCommentLike(post.id, comment.id)} className="like-button">
                    <Icon.ThumbsUp
                      size={16}
                      fill={isCommentLiked[comment.id] ? "#1D599C" : "none"}
                      color={isCommentLiked[comment.id] ? "#0094D4" : "#fff"}
                    />
                    <p className='like-count'>{commentLikes[comment.id] || ''}</p>
                  </button>

                  {/* Reply Button */}
                  <button onClick={() => setReplyingToComment(comment.id)} className="reply-button">
                    <Icon.MessageSquare size={16} />
                  </button>
                </div>

                {/* Reply Input Field */}
                {replyingToComment === comment.id && (
                  <div className="reply-input">
                    <textarea
                      value={commentContent}
                      onChange={(e) => setCommentContent(e.target.value)}
                      placeholder="Reply to this comment..."
                      onKeyDown={handleMaxLength} // Handles Enter key submission
                      maxLength={maxLength} // Native enforcement
                    />
                    <p 
                      style={{fontSize: 'var(--text-xs)', width: '98%',textAlign: 'right', color: 'var(--text-grey)', margin: 'auto 0', padding: '0'}}>
                        {commentContent.length + " / " + maxLength}
                    </p>

                    <div className="reply-buttons">
                      {/* Save Button */}
                      <SecondaryButton
                        buttonLabel="Post Comment"
                        onClick={() => {
                          console.log("Replying to Comment ID:", comment.id);
                          addComment(post.id, commentContent, auth.currentUser?.uid, comment.id)
                            .then(() => {
                              setCommentContent("");
                              setReplyingToComment(null);
                              fetchCommentCount(post.id);
                            })
                            .catch((err) => console.error("Error while replying:", err));
                        }}
                      />

                      {/* Cancel Button */}
                      <SecondaryButton
                        buttonLabel="Cancel"
                        onClick={() => {
                          setCommentContent("");
                          setReplyingToComment(null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              {comment.replies && comment.replies.length > 0 && (
                <RepliesSection
                comment={comment}
                post={post}
                replies={comment.replies} 
                setReplies={setReplies}
                visibleReplyOptionsId={visibleReplyOptionsId}
                setVisibleReplyOptionsId={setVisibleReplyOptionsId}
              />
              )}
            </div>
            
          </div>
        </div>
      ))}
      
    </div>
  );
};

export default CommentSection;