import { useContext, useEffect, useState, useRef } from "react";
import { auth, db } from '../Firebase';
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';


import styles from '../styles/Header.css';
import * as Icon from 'react-feather';
import radioIcon from '../assets/radio-icon.svg';
import submergeLogo from '../assets/submerge-logo.png';
import submergeLogoMin from '../assets/submerge-app-icon.svg';
import userAvatar from '../assets/avatar.jpg';
import radioOn from '../assets/on.svg';
import radioOff from '../assets/off.svg';

import { RadioContext } from '../context/RadioContext';

import NotificationPopup from '../components/NotificationPopup';
import RadioPlayerNew from '../components/RadioPlayerNew';
import Layout from './Layout';

const Header = ({ goBack, pageTitle, avatar, radioButton, showLogo, hasSearchbar, hasUnreadMessages: propHasUnreadMessages }) => {
  const contentRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null); // Track current user dynamically
  
  //const [hasUnreadMessages, setHasUnreadMessages] = useState(false); // State for unread messages
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const [isRadioOn, setIsRadioOn] = useState(false);
  const [localHasUnreadMessages, setLocalHasUnreadMessages] = useState(false); 
  const navigate = useNavigate();
  const { toggleRadio, setIsRadioVisible } = useContext(RadioContext);
  // Handle navigation back
  const handleGoBack = () => {
    navigate(-1);
  };

  // Toggle radio 
  const handleToggleRadio = () => {
    toggleRadio();
    setIsRadioOn((prevState) => !prevState);

    if (isRadioOn) {
        setIsRadioVisible(false); // Ensure the player hides when stopping
    }
  };

  // Toggle notification modal
  const toggleNotifModal = () => {
    setIsNotifOpen((prevState) => !prevState);
  };

  // Close notification modal on outside click
  const handleClickOutside = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      setIsNotifOpen(false);
    }
  };

  useEffect(() => {
    if (!currentUser) return; // currentUser comes from your auth listener
  
    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('recipientId', '==', currentUser.uid),
      where('read', '==', false)
    );
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      // If there is at least one unread notification, set the flag to true
      setHasUnreadNotifications(snapshot.size > 0);
    });
  
    return () => unsubscribe();
  }, [currentUser]);
  
  useEffect(() => {
    if (isNotifOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotifOpen]);

  // Set up Firebase Auth listener for current user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Fetch unread messages for the logged-in user
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      if (!currentUser) {
        console.warn('No current user logged in.');
        return;
      }

      try {
        // Step 1: Get chat IDs for the logged-in user
        const chatsRef = collection(db, 'chats');
        const userChatsQuery = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
        const chatSnapshot = await getDocs(userChatsQuery);
        const chatIds = chatSnapshot.docs.map((doc) => doc.id);

        if (chatIds.length === 0) {
          console.log('No chats found for the current user.');
          setLocalHasUnreadMessages(false);
          return;
        }

        // Step 2: Query messages for those chats
        const messagesRef = collection(db, 'messages');
        const unreadQuery = query(
          messagesRef,
          where('chatId', 'in', chatIds) // Query only messages for relevant chats
        );
        const unreadSnapshot = await getDocs(unreadQuery);

        // Check if any messages do not include the user's UID in isReadBy
        const hasUnread = unreadSnapshot.docs.some((doc) => {
          const isReadBy = doc.data().isReadBy || [];
          return !isReadBy.includes(currentUser.uid);
        });

        setLocalHasUnreadMessages(hasUnread);
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };

    if (propHasUnreadMessages === undefined) {
      fetchUnreadMessages();
    } 
  }, [propHasUnreadMessages, currentUser]);

  const hasUnreadMessages = propHasUnreadMessages ?? localHasUnreadMessages

  return (
    <div className="header">
      
      <header className="header-web">
        {/* <div className="live-radio-button" onClick={toggleRadio} style={{ display: radioButton ? 'block' : 'none' }}>
          <img src={radioIcon} alt="Radio Icon" />
        </div> */}

        <div className='left-col'>

          <Link to={"/"} style={{ display: goBack ? 'block' : 'none' }}>
            <Icon.ChevronLeft style={{ margin: 'auto' }} />
          </Link>

          <Link to="/" className="link" style={{ display: showLogo ? 'flex' : 'none', margin: '0 auto' }}>
            <img
              className="submerge-logo"
              src={submergeLogo}
              alt="Submerge Logo"
            />
            <img
              className="submerge-logo-min"
              src={submergeLogoMin}
              alt="Submerge Logo"
            />
          </Link>

          <div className='header-search' style={{ display: hasSearchbar ? 'flex' : 'none' }}>
            <div className='search-icon'>
              <Icon.Search size={16} color='var(--text-grey)' />
            </div>
            <input 
              className='header-search-input' 
              placeholder='Search'
              style={{ cursor: "not-allowed" }} // Disable input for now
            />
          </div>

        </div>

        <div className="right-buttons-web" ref={contentRef}>
          
          {/* HEADER RADIO PLAYER */}
          <div className='radio-player-header' style={{display: ''}}>
            {isRadioOn && <Layout />}
          </div>

          {/* RADIO SWITCH TOGGLE */}
          {/* {isRadioOn ? (
            <div className="toggleRadioOn radio-switch" onClick={handleToggleRadio} style={{display:'none'}}>
              <div className="switch-icon">
                <img src={radioOn} />
              </div>
              <h4 className="text-def">Radio Playing</h4>
              <h4 className="text-min">Radio On</h4>
            </div>
          ) : (
            <div className="toggleRadioOff radio-switch" onClick={handleToggleRadio}>
              <div className="switch-icon">
                <img src={radioOff} />
              </div>
              <h4 className="text-def">Play Live Radio</h4>
              <h4 className="text-min">Radio</h4>
            </div>
          )} */}

          
          <Link to="/messages" className="messages-button-mobile right-button">
            <Icon.MessageCircle
              color={hasUnreadMessages ? 'var(--cyan)' : '#fff'}
              fill={hasUnreadMessages ? 'var(--ocean-blue-50)' : 'none'}
              size={20}
            />
            {hasUnreadMessages && <span className="indicator"></span>}
          </Link>
          <div className="open-notif-modal right-button" onClick={toggleNotifModal}>
            <Icon.Bell size={20} color="#fff"/>
            {hasUnreadNotifications && <span className="indicator"></span>}
          </div>


          {isNotifOpen && (
            <div className="notification-popup-modal">
              <NotificationPopup />
            </div>
          )}
        </div>
      </header>

      
    </div>
  );
};

export default Header;