import { useEffect, useState, useRef } from "react";
import { db } from '../Firebase'; 
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../Firebase';
import styles from '../styles/TrackPost.css';
import BubblePill from './BubblePill';
import * as Icon from 'react-feather';
import EventDetailsModal from "./EventDetailsModal";
import TrackDetails from "./TrackDetails";
import trackPlaceholder from '../assets/track-placeholder.svg'

const TrackPost = ({ trackId }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState("0:00");
    const [duration, setDuration] = useState("0:00");
    const [trackData, setTrackData] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    const [isTrackDetailsVisible, setIsTrackDetailsVisible] = useState(false);

    const toggleTrackDetailsPopup = () => {
        setIsTrackDetailsVisible(!isTrackDetailsVisible);
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const togglePlayPause = () => {
        if (!audioRef.current) return;
  
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch((error) => console.error("Playback error:", error));
        }
        
        setIsPlaying(!isPlaying);
    };

    const stopAudio = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    const updateProgress = () => {
        if (audioRef.current && audioRef.current.duration) {
          const percentage = (audioRef.current.currentTime / audioRef.current.duration) * 100;
          setProgress(percentage);
          setCurrentTime(formatTime(audioRef.current.currentTime));
        }
      };
    
    const setAudioDuration = () => {
        if (audioRef.current && audioRef.current.duration) {
            setDuration(formatTime(audioRef.current.duration));
        }
    };
    
    const handleAudioEnd = () => {
        setIsPlaying(false);
        setProgress(0);
        setCurrentTime("0:00");
        stopAudio();
    };

    const handleSliderChange = (e) => {
        const newTime = (e.target.value / 100) * audioRef.current.duration;
        audioRef.current.currentTime = newTime;
        setProgress(e.target.value);
        setCurrentTime(formatTime(newTime));
    };

    useEffect(() => {
        const fetchTrack = async () => {
          if (!trackId) return;
          try {
            const docRef = doc(db, "tracks", trackId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              setTrackData(docSnap.data());
            } else {
              console.warn("Track not found:", trackId);
            }
          } catch (error) {
            console.error("Failed to fetch track:", error);
          }
        };
      
        fetchTrack();
      }, [trackId]);

    useEffect(() => {
      if (trackData && trackData.thumbnail && trackData.thumbnail.startsWith('gs://')) {
        console.log("Generating download URL for thumbnail:", trackData.thumbnail);
        const storageRef = ref(storage, trackData.thumbnail);
        getDownloadURL(storageRef)
          .then(url => setThumbnailUrl(url))
          .catch(error => {
            console.error('Error fetching download URL for thumbnail:', error);
            setThumbnailUrl(trackData.thumbnail);
          });
      } else {
        setThumbnailUrl(trackData ? trackData.thumbnail : null);
      }
    }, [trackData]);

    useEffect(() => {
        if (audioRef.current && trackData?.url) {
          audioRef.current.load();
        }
      }, [trackData]);

    return (
        <div className="track-post-component">
            <div className="track-content">
                
                <div className="track-thumbnail" onClick={toggleTrackDetailsPopup} style={{cursor: 'pointer'}}>
                    <img src={thumbnailUrl ? thumbnailUrl : trackPlaceholder} alt="Track thumbnail" />
                </div>

                <div className="track-player">
                    <div className="track-info">
                        <div className="track-deets">
                        <p className="track-title-artist">
                            {trackData?.title} {trackData?.remixTitle && `(${trackData.remixTitle})`} – {trackData?.artists?.join(', ')}
                        </p>
                            <p className="track-genre">
                              {trackData?.genre || ''} {trackData?.subgenre ? `• ${trackData.subgenre}` : ''}  
                            </p>
                        </div>

                        <div className="play-pause" onClick={togglePlayPause}>
                            <div className="icon-btn">
                                {isPlaying ? (
                                    <Icon.PauseCircle size={22} />
                                ) : (
                                    <Icon.PlayCircle size={22} />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="slider">
                        <audio ref={audioRef} 
                            onTimeUpdate={updateProgress} 
                            preload="metadata" 
                            onLoadedMetadata={setAudioDuration}
                            onEnded={handleAudioEnd} >
                            <source src={trackData?.url} type="audio/mp3" />
                        </audio>
                        <span>{currentTime}</span>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={progress}
                            onChange={handleSliderChange}
                            className="progress-bar"
                            style={{
                                accentColor: "var(--cyan)",
                                cursor: "pointer",
                                WebkitAppearance: "none",
                                height: "3px",
                                borderRadius: "5px",
                                background: "var(--text-grey)",
                              }}
                        />
                        <span>{duration}</span>

                        
                    </div>
                </div>
                
                
            </div>

            <div className="popup-overlay">
                <TrackDetails isVisible={isTrackDetailsVisible} onClose={toggleTrackDetailsPopup} />

            </div>
        </div>
    )
}

export default TrackPost;
