import styles from '../styles/ContactUs.css'
import bg from '../assets/lp-bg.png'
import logo from '../assets/submerge-logo_original.png'
import PrimaryButton from '../components/PrimaryButton';
import * as Icon from 'react-feather';

const ContactUs = () => {
    return (
        <div className="contact-us-page">
            <div className='lpbg'>
                <img src={bg} />
            </div>

            <div className='contact-us-main'>
                <div className='submerge-logo'>
                    <img src={logo} />
                </div>

                <form className='contact-us-form'>
                    <div className='cu-input-field'>
                        <label className='label'>
                            Email Address
                        </label>
                        <input placeholder='Email Address' />
                    </div>

                    <div className='cu-input-field'>
                        <label className='label'>
                            Name
                        </label>
                        <input placeholder='Name' />
                    </div>

                    <div className='cu-input-field'>
                        <label htmlFor="message" className="label">Message</label>
                        <textarea
                            id="message"
                            className=""
                            placeholder="Tell us something"
                            rows="10"
                            required
                            value=""
                            onChange=""
                        />
                    </div>

                    <div className='submit-btn'>
                        <PrimaryButton buttonLabel="Submit" />
                    </div>
                </form>

                {/* SHOW AFTER MESSAGE SENT */}
                <div className='success-msg'>
                    <Icon.CheckCircle size={32} color='var(--success)'/>
                    <div className='texts'>
                        <p className='success-heading'>Thanks for reaching out to us.</p>
                        <p className='success-texts'>We'll get back to you as soon as we can!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
