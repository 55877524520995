import React, { useState } from 'react';
import PrimaryButton from './PrimaryButton';

function EventCommunication({ selectedEvent }) {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState({
    everyone: false,
    going: false,
    noResponse: false,
    declines: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setRecipients((prevRecipients) => ({
      ...prevRecipients,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    if (!subject || !message) {
      alert("Please enter a subject and message.");
      return;
    }

    const selectedRecipients = Object.keys(recipients).filter((key) => recipients[key]);

    if (selectedRecipients.length === 0) {
      alert("Please select at least one recipient group.");
      return;
    }

    console.log("Sending message with data:", {
      eventId: selectedEvent,
      subject,
      message,
      recipients: selectedRecipients,
    });

    // Here, implement Firestore logic to send messages.
    alert("Message sent successfully!");
    setSubject('');
    setMessage('');
    setRecipients({
      everyone: false,
      going: false,
      noResponse: false,
      declines: false,
    });
  };

  return (
    <div className="communication-section">
      <h3>Send Event Communication</h3>

      <div className="form-group">
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter subject"
        />
      </div>

      <div className="form-group">
        <label>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
        />
      </div>

      <div className="recipient-group">
        <h4>Select Recipients:</h4>
        <label>
          <input
            type="checkbox"
            name="everyone"
            checked={recipients.everyone}
            onChange={handleCheckboxChange}
          /> Everyone
        </label>
        <label>
          <input
            type="checkbox"
            name="going"
            checked={recipients.going}
            onChange={handleCheckboxChange}
          /> Going
        </label>
        <label>
          <input
            type="checkbox"
            name="noResponse"
            checked={recipients.noResponse}
            onChange={handleCheckboxChange}
          /> No Response
        </label>
        <label>
          <input
            type="checkbox"
            name="declines"
            checked={recipients.declines}
            onChange={handleCheckboxChange}
          /> Declines
        </label>
      </div>

      <PrimaryButton onClick={handleSubmit} buttonLabel="Send Message" />
    </div>
  );
}

export default EventCommunication;