import { db } from "../Firebase"; // Ensure this is your Firebase instance
import { doc, collection, addDoc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";


/**
 * Adds the authenticated user to the event's attendees subcollection.
 * @param {string} eventId - The ID of the event.
 * @param {string} authUserId - The ID of the authenticated user.
 */

export const markAsGoing = async (eventId, authUserId) => {
    if (!eventId || !authUserId) {
        console.error("Missing eventId or authUserId:", { eventId, authUserId });
        return;
    }

    try {
        const eventRef = doc(db, "events", eventId); // Reference to the event document
        const attendeesRef = collection(eventRef, "attendees"); // Reference to the nested attendees collection

        // Check if the user is already marked as going
        const userAttendanceRef = doc(attendeesRef, authUserId);
        const userAttendanceSnap = await getDoc(userAttendanceRef);

        if (userAttendanceSnap.exists()) {
            console.warn(`User ${authUserId} is already marked as going to event ${eventId}`);
            return;
        }

        // Add the user to the attendees collection
        await setDoc(userAttendanceRef, {
            userId: authUserId,
            timestamp: serverTimestamp(),
        });

        console.log(`User ${authUserId} successfully marked as going to event ${eventId}`);
    } catch (error) {
        console.error("Error marking user as going:", error);
    }
};
