import styles from '../styles/EventDetailsModal.css'
import { auth, db } from '../Firebase';
import { doc, collection, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import eventPlaceholder from '../assets/event-placeholder.svg'
import performerIcon from '../assets/performer-icon.svg'
import * as Icon from 'react-feather';
import { markAsGoing } from '../utils/markAsGoing';

const EventDetailsModal = ({ isVisible, onClose, event }) => {
    if (!isVisible || !event) return null;
    const user = auth.currentUser

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleGoingClick = () => {
      /*  const user = auth.currentUser */
        console.log("user", user)
        if (!user) {
            alert("You must be logged in to mark yourself as going.");
            return;
        }
        markAsGoing(event.id, user.uid);
    };

    const handleNotGoing = async (eventId) => {
        if (!eventId || !user) {
            console.error("Missing eventId or user:", { eventId, user });
            return;
        }
    
        try {
            const eventRef = doc(db, "events", eventId); // Reference to the event document
            const declinesRef = collection(eventRef, "declines"); // Reference to the nested attendees collection
            
            // Check if the user is already marked as going
            const userDeclinesRef = doc(declinesRef, user.uid);
            const userDeclinesSnap = await getDoc(userDeclinesRef);
    
            if (userDeclinesSnap.exists()) {
                console.warn(`User ${user.uid} is already marked as declining event ${eventId}`);
                return;
            }
    
            // Add the user to the attendees collection
            await setDoc(userDeclinesRef, {
                userId: user.uid,
                timestamp: serverTimestamp(),
            });
    
            console.log(`User ${user}.uid successfully marked as declining event ${eventId}`);
        } catch (error) {
            console.error("Error marking user as declining:", error);
        }  
    }

    // Add to cart function
    const addToCart = () => {
        if (!event || !event.id || !event.ticketPrice) {
            console.error("Event data is incomplete:", event); // Debugging log
            return;
        }
    
        let cart = JSON.parse(localStorage.getItem("cart")) || [];
        console.log("Cart before adding:", cart); // Debugging log
    
        // Check if item already exists in cart
        const existingItem = cart.find((item) => item.id === event.id);
    
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            const newItem = {
                id: event.id,
                title: event.title || "Unknown Event",
                date: event.date ? event.date.toDate().toISOString() : null, // Convert Firebase timestamp
                location: event.location || "No Location",
                ticketPrice: event.ticketPrice,
                quantity: 1,
                thumbnail: event.thumbnail || eventPlaceholder,
            };
    
            console.log("Adding to cart:", newItem); // Debugging log
            cart.push(newItem);
        }
    
        localStorage.setItem("cart", JSON.stringify(cart));
        console.log("Cart after adding:", JSON.parse(localStorage.getItem("cart"))); // Debugging log
    
        alert(`Added ${event.title} ticket to cart!`);
    };

    return (
        <div className="event-details-modal" onClick={handleOverlayClick}>
            <div className="event-details-content">
                <div className="event-details-header">
                    <h2 className="event-title">{event.title}</h2>
                    <div className="close-overlay-button" onClick={onClose} style={{ cursor: 'pointer' }}>
                        <Icon.X color='#fff' size={18} />
                    </div>
                </div>
                <div className="event-thumbnail">
                    <img src={event.thumbnail || eventPlaceholder} alt={event.title} />
                </div>
                <div className="event-date-loc">
                    <div className="event-date sub-details">
                        <Icon.Calendar size={14} style={{ margin: '0 0 -2px' }} />
                        <p>{event.date?.toDate().toLocaleDateString('en-US')}</p>
                    </div>

                    {event.location && (
                        <div className="event-location sub-details">
                            <Icon.MapPin size={14} style={{ margin: '0 0 -2px' }} />
                            <p className="location-text">{event.location}</p>
                        </div>
                    )}

                    <div className="event-ticket sub-details">
                        <div className="event-date-loc-icon">
                            <Icon.DollarSign size={14} style={{ margin: '0 0 -2px' }} />
                        </div>
                        <p className="ticket-text">
                            {console.log("Ticket Price Type: ", typeof event.ticketPrice)}
                            {typeof event.ticketPrice === 'number' 
                                ? `$${event.ticketPrice.toFixed(2)}` 
                                : 'Free Admission'}
                            {console.log("Ticket Price: ", event.ticketPrice)}
                            {console.log("Ticket Count: ", event.ticketPrice.length)}
                        </p>
                    </div>
                </div>

                <p className="event-description" style={{ whiteSpace: 'pre-wrap' }}>
                    {event.description}
                </p>

                <div className="invite-response">
                    <button className="response-option" onClick={() => event.ticketPrice > 0 ? addToCart() : handleGoingClick(event.id)}>
                        <Icon.Check size={16} color="var(--sand-beige)" />
                        <p>{event.ticketPrice > 0 ? "Purchase Tickets" : "Going"}</p>
                    </button>
                    <button className="response-option" onClick={() => handleNotGoing(event.id)}>
                        <Icon.X size={16} color="var(--sand-beige)" />
                        <p>Not Going</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EventDetailsModal;