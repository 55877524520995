import * as Icon from "react-feather";
import { auth } from '../../Firebase';

const PostOptionsModal = ({
  isVisible,
  togglePostOptionsModal,
  post,
  isFollowing,
  toggleFollow,
  toggleEditMode,
  deletePostWithConfirmation,
}) => {
  if (!isVisible) return null;

  return (
    <div className="post-options-modal">
      <ul className="post-options-list">
        {/* Only show Edit & Delete options to the post owner */}
        {post.userID === auth.currentUser?.uid && (
          <>
            <li
              onClick={() => {
                console.log("Edit Post clicked, post.id:", post.id);
                if (!post.id) {
                  console.error("Error: post.id is undefined or invalid.");
                  return;
                }
                toggleEditMode(post.id);
                togglePostOptionsModal();
              }}
            >
              <Icon.Edit3 size={14} className="icon" />
              <span className="option-name">Edit Post</span>
            </li>
            <li
              onClick={() => {
                deletePostWithConfirmation(post.id);
                togglePostOptionsModal();
              }}
            >
              <Icon.Trash size={14} className="icon" />
              <span className="option-name">Delete Post</span>
            </li>
          </>
        )}

        {/* Show Follow/Unfollow option to non-owners */}
        {auth.currentUser?.uid !== post.userID && (
          <li>
            {isFollowing[post.userID] ? (
              <button
                onClick={() => {
                  console.log("Unfollowing user with ID:", post.userID);
                  toggleFollow(post.userID);
                  togglePostOptionsModal();
                }}
              >
                <Icon.UserMinus size={14} />
                <span className="option-name">Unfollow @{post.userHandle}</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  console.log("Following user with ID:", post.userID);
                  toggleFollow(post.userID);
                  togglePostOptionsModal();
                }}
              >
                <Icon.UserPlus size={14} />
                <span className="option-name">Follow @{post.userHandle}</span>
              </button>
            )}
          </li>
        )}

        {/* Other Options (Poop Post) */}
        <li>
          <span>💩</span>
          <span className="option-name">Poop this post</span>
        </li>
      </ul>
    </div>
  );
};

export default PostOptionsModal;