// App.js
import { useEffect, useState, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './Firebase'; 

import './App.css';

import PlayerLayout from "./Layouts/PlayerLayout"; // Layout that uses <Outlet />
import NoPlayerLayout from "./Layouts/NoPlayerLayout"; // Layout without radio player
import Admin from './screens/Admin';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import ForgotPassword from './screens/ForgotPassword';
import MainScreen from './screens/MainScreen';
import Profile from './screens/Profile';
import AccountSettings from './screens/AccountSettings';
import Notifications from './screens/Notifications';
import Messages from './screens/Messages';
import Loading from './components/Loading';
import EventsPage from './screens/EventsPage';
import RadioPage from './screens/RadioPage';
import EventDetailsPage from './screens/EventDetailsPage';
import BetaUsers from './screens/BetaUsers';
import LandingPage from './screens/LandingPage';
import TermsAndConditions from './screens/TermsAndConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactUs from './screens/ContactUs';
import Error404 from './screens/Error404';
import Checkout from './screens/Checkout';
import EventAdmin from './screens/EventAdmin';
import ProfileWrapper from './components/Profile/ProfileWrapper';
import RadioPlayer from './components/RadioPlayer';
import { RadioContext } from './context/RadioContext';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { isRadioVisible } = useContext(RadioContext);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null) {
    return <div><Loading /></div>; // Loading state while checking authentication
  }

  return (
    <div className="App">
      <Routes>
        {/* Authenticated Routes using PlayerLayout */}
        <Route element={ isAuthenticated ? <PlayerLayout /> : <NoPlayerLayout /> }>
          <Route index element={ isAuthenticated ? <MainScreen /> : <LandingPage /> } />
          <Route path="/profile" element={ isAuthenticated ? <MainScreen /> : <Navigate to="/signin" />} />
          <Route path="/profile/id" element={ isAuthenticated ? <Profile /> : <Navigate to="/signin" />} />
          <Route path="/edit_profile" element={ isAuthenticated ? <AccountSettings /> : <Navigate to="/signin" />} />
          <Route path="/notifications" element={ isAuthenticated ? <Notifications /> : <Navigate to="/signin" />} />
          <Route path="/event-admin" element={ isAuthenticated ? <EventAdmin /> : <Navigate to="/signin" />} />
          <Route path="/admin" element={ isAuthenticated ? <Admin /> : <Navigate to="/signin" /> } />
          <Route path="/messages" element={ isAuthenticated ? <Messages /> : <Navigate to="/signin" />} />
          <Route path="/:handle" element={<ProfileWrapper />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/radio" element={<RadioPage />} />
        </Route>

        {/* Public/Unauthenticated Routes using NoPlayerLayout */}
        <Route element={<NoPlayerLayout />}>   
          <Route path="/welcome" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/event-details" element={<EventDetailsPage />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;