import React, { useEffect, useState } from 'react';
import { auth, db } from '../Firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import styles from '../styles/Admin.css';
import AdminSidemenu from '../components/AdminSidemenu';
import PrimaryButton from '../components/PrimaryButton';

function Admin() {
  const [betaUsers, setBetaUsers] = useState([]);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [emailInput, setEmailInput] = useState('');
  const [fileContent, setFileContent] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uidsInField, setUidsInField] = useState([]);
  
  //Notification Section
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [status, setStatus] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
 

  const handleImageUrlChange = (e) => {
    const url = e.target.value.trim();
    setImageUrl(url);
    setPreviewVisible(url !== "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ap = "5CUYXBVW3HSWNB3Y0PZ8ISZKG1IWCD";
    const scheduleTimeISO = scheduleTime ? new Date(scheduleTime).toISOString() : null;
    const contentAvailable = true;
    const functions = getFunctions();
    const sendMessage = httpsCallable(functions, 'sendBroadcastMessage');
    try {
      setStatus("Sending notification....");
      const result = await sendMessage({ 
        password: ap,
        title, 
        body, 
        image: imageUrl || null, 
        scheduleTime: scheduleTimeISO, 
        contentAvailable 
      });
      setStatus("Notification delivered!");
    } catch (error) {
      setStatus("Error: " + error.message);
    }
  };

  useEffect(() => {
    const checkUserAuthorization = async (uid) => {
      try {
        const adminDocRef = doc(db, 'auth', 'admins');
        const adminDoc = await getDoc(adminDocRef);

        if (adminDoc.exists()) {
          const data = adminDoc.data();
          const globalUidsArray = data.Global || [];
          setUidsInField(globalUidsArray);

          if (globalUidsArray.includes(uid)) {
            setIsAuthenticated(true);
          }
        } else {
          console.warn('No data found in Firestore under auth/admin.');
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid);
        checkUserAuthorization(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchBetaUsers = async () => {
    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      const docSnap = await getDoc(authorizedUsersRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const betaUsersArray = data.beta || [];
        setBetaUsers(betaUsersArray.sort((a, b) => a.localeCompare(b))); // Sort alphabetically
      }
    } catch (error) {
      console.error('Error fetching beta users:', error);
    }
  };

  useEffect(() => {
    fetchBetaUsers();
  }, []);

  const handleEmailChange = (e) => setEmailInput(e.target.value);

  const handleEmailSubmit = async () => {
    if (!emailInput) {
      alert('Please enter a valid email.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      await updateDoc(authorizedUsersRef, {
        beta: arrayUnion(emailInput),
      });
      alert('Email added successfully to authorizedUsers.');
      setEmailInput('');
      await fetchBetaUsers(); // Refetch beta users list after adding email
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'text/plain' || file.type === 'text/csv')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const lines = event.target.result.split('\n').map((line) => line.trim()).filter((line) => line);
        setFileContent(lines);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a .txt or .csv file.');
    }
  };

  const handleFileSubmit = async () => {
    if (fileContent.length === 0) {
      alert('No valid content to upload.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      for (const email of fileContent) {
        await updateDoc(authorizedUsersRef, {
          beta: arrayUnion(email),
        });
      }
      alert('File content added successfully to authorizedUsers.');
      setFileContent([]);
      await fetchBetaUsers(); // Refetch beta users list after adding emails from file
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="no-access">
        <div>You do not have access to this page.</div>
      </div>
    );
  }

  return (
    <div className="admin">
      <div className="admin-wrapper" style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <AdminSidemenu />
        <div className="admin-content" style={{ flex: 1, marginLeft: '20px' }}>
          <div className="admin-container">
            <h3 className="admin-title">Beta Authorizations</h3>
            <div className="auth-container">
              <div className="input-email-section">
                <h3>Input Email</h3>
                <div className="form">
                  <input
                    type="email"
                    value={emailInput}
                    onChange={handleEmailChange}
                    placeholder="Enter email to authorize"
                  />
                  <div className="button">
                    <PrimaryButton onClick={handleEmailSubmit} buttonLabel="Add Email" />
                  </div>
                </div>
              </div>

              <div className="file-upload-section">
                <h3>Upload .txt or .csv File</h3>
                <div className="form">
                  <input type="file" accept=".txt, .csv" onChange={handleFileChange} />
                  <div className="button">
                    <PrimaryButton onClick={handleFileSubmit} buttonLabel="Upload File" />
                  </div>
                </div>
              </div>
            </div>

            <h4 className="admin-title">All Beta Users List</h4>
            <ul className="beta-users-list">
              {betaUsers.map((user, index) => (
                <li key={index}>{user}</li>
              ))}
            </ul>
          </div>

          <div className="notifications-section">
            <h3 className="admin-title">Notifications</h3>
            <div className="container">
              <h1>Send FCM Message</h1>
              <form id="msgForm" onSubmit={handleSubmit}>
                <label>
                  Title:
                  <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                    required 
                    placeholder="Message title" 
                  />
                </label>
                <label>
                  Body:
                  <textarea 
                    value={body} 
                    onChange={(e) => setBody(e.target.value)} 
                    required 
                    placeholder="Message body">
                  </textarea>
                </label>
                <label>
                  Image URL (optional):
                  <input 
                    type="url" 
                    value={imageUrl} 
                    onChange={handleImageUrlChange} 
                    placeholder="https://example.com/image.jpg" 
                  />
                </label>
                {previewVisible && (
                  <div className="preview" id="imagePreview">
                    <p>Image Preview:</p>
                    <img src={imageUrl} alt="Image preview" />
                  </div>
                )}
                <label>
                  Schedule Time (optional):
                  <input 
                    type="datetime-local" 
                    value={scheduleTime} 
                    onChange={(e) => setScheduleTime(e.target.value)} 
                  />
                </label>
                <button type="submit">Send Message</button>
              </form>
              <div id="status">{status}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;