import { useState, useEffect } from "react";
import { db } from "../Firebase";
import { collection, doc, addDoc, deleteDoc, updateDoc, getDocs, setDoc } from "firebase/firestore";

export default function EventBudget({ selectedEvent }) {
  const [debits, setDebits] = useState([]);
  const [credits, setCredits] = useState([]);
  const [newDebit, setNewDebit] = useState({ description: "", unitCost: "", quantity: "" });
  const [newCredit, setNewCredit] = useState({ description: "", unitCost: "", quantity: "" });

  console.log("EventBudget mounted. Received selectedEvent:", selectedEvent);

  useEffect(() => {
    if (selectedEvent) {
      console.log("Fetching budget for: ", selectedEvent);
      ensureBudgetDocument();
      fetchBudgetData("debits", setDebits);
      fetchBudgetData("credits", setCredits);
    }
  }, [selectedEvent]);

  // Ensures that a budgetInfo document exists
  const ensureBudgetDocument = async () => {
    if (!selectedEvent) return;
    
    // Fix: Make `budget` a collection and `budgetInfo` a document inside it
    const budgetRef = doc(db, `events/${selectedEvent}/budget/budgetInfo`);
    await setDoc(budgetRef, { createdAt: new Date() }, { merge: true });

    console.log("Ensured budgetInfo document exists for event:", selectedEvent);
  };

  // Fetch data for debits or credits
  const fetchBudgetData = async (type, setter) => {
    if (!selectedEvent) return;
    console.log(`Fetching ${type} from Firestore...`);

    const ref = collection(db, `events/${selectedEvent}/${type}`);
    const querySnapshot = await getDocs(ref);

    const fetchedItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log(`Fetched ${type}:`, fetchedItems);
    setter(fetchedItems);
  };

  // Add a new debit or credit entry
  const handleAddEntry = async (type, entry) => {
    if (!selectedEvent) return;

    console.log(`handleAddEntry called for type: ${type}`);

    // Validate that required fields are filled
    if (!entry || !entry.description || !entry.unitCost || !entry.quantity) {
      console.warn(`Missing input values for ${type}! Entry not added.`);
      return;
    }

    console.log(`New ${type} entry values:`, entry);

    const updatedEntry = {
      description: entry.description.trim(),
      unitCost: parseFloat(entry.unitCost),
      quantity: parseInt(entry.quantity),
      subtotal: parseFloat(entry.unitCost) * parseInt(entry.quantity),
      createdAt: new Date(),
    };

    try {
      // Store in Firestore under the correct collection
      const ref = doc(db, `events/${selectedEvent}/${type}/${entry.description}`);
      await setDoc(ref, updatedEntry);

      console.log(`Firestore write successful for ${type}:`, updatedEntry);

      // Update UI state based on type
      if (type === "debits") {
        setDebits((prev) => [...prev, { id: entry.description, ...updatedEntry }]);
      } else {
        setCredits((prev) => [...prev, { id: entry.description, ...updatedEntry }]);
      }

    } catch (error) {
      console.error(`Error adding ${type} to Firestore:`, error);
    }
  };

  // Update an existing debit or credit
  const handleUpdateEntry = async (type, id, updatedEntry) => {
    if (!selectedEvent) return;

    console.log(`Updating ${type} ID ${id} with data:`, updatedEntry);

    const ref = doc(db, `events/${selectedEvent}/${type}/${id}`);

    try {
      await updateDoc(ref, updatedEntry);
      console.log(`Updated ${type} in Firestore.`);

      if (type === "debit") {
        setDebits(prev => prev.map(entry => (entry.id === id ? { ...updatedEntry, id } : entry)));
      } else {
        setCredits(prev => prev.map(entry => (entry.id === id ? { ...updatedEntry, id } : entry)));
      }
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    }
  };

  // Delete an entry
  const handleDeleteEntry = async (type, id) => {
    if (!selectedEvent) return;

    console.log(`Deleting ${type} ID ${id} from Firestore...`);

    const ref = doc(db, `events/${selectedEvent}/${type}/${id}`);

    try {
      await deleteDoc(ref);
      console.log(`Deleted ${type} from Firestore.`);

      if (type === "debit") {
        setDebits(prev => prev.filter(entry => entry.id !== id));
      } else {
        setCredits(prev => prev.filter(entry => entry.id !== id));
      }
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
    }
  };

  function BudgetInput({ entry, setEntry, onAdd, buttonColor }) {
    const [localEntry, setLocalEntry] = useState(entry);
  
    const handleNewEntryChange = (e) => {  
        const { name, value } = e.target;
        setLocalEntry((prev) => ({
          ...prev,
          [name]: name === "description" ? value : value === "" ? "" : parseFloat(value),
        }));
    };
  
    const handleAddClick = () => {
      if (!localEntry.description || !localEntry.unitCost || !localEntry.quantity) {
        console.warn("Missing input values! Entry not added.");
        return;
      }
      
      console.log("Submitting entry to Firestore:", localEntry)

      setEntry(localEntry); // Update parent state
      onAdd(localEntry); // Pass updated entry directly to add function
      setLocalEntry({ description: "", unitCost: "", quantity: "" }); // Reset input fields
    };
  
    return (
      <div className="budget-input">
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={localEntry.description}
          onChange={handleNewEntryChange}
        />
        <input
          type="number"
          name="unitCost"
          placeholder="Unit Cost"
          value={localEntry.unitCost || ""}
          onChange={handleNewEntryChange}
        />
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={localEntry.quantity || ""}
          onChange={handleNewEntryChange}
        />
        <button style={{ backgroundColor: buttonColor }} onClick={handleAddClick}>
          Add
        </button>
      </div>
    );
  }

  function BudgetSection({ title, data, newEntry, setNewEntry, onAdd, onUpdate, onDelete, buttonColor }) {
    return (
      <div className="budget-section">
        <h2 className="budget-title">{title}</h2>
        <table className="budget-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Unit Cost</th>
              <th>Quantity</th>
              <th>Subtotal</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <BudgetRow key={item.id} item={item} onUpdate={onUpdate} onDelete={onDelete} type={title.toLowerCase()} />
            ))}
          </tbody>
        </table>
        <BudgetInput entry={newEntry} setEntry={setNewEntry} onAdd={onAdd} buttonColor={buttonColor} />
      </div>
    );
  }
  

  function BudgetRow({ item, onUpdate, onDelete }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editEntry, setEditEntry] = useState({ ...item }); // Preserve values when switching modes
  
    // Handles input changes while editing
    const handleEditEntryChange = (e) => {  
        const { name, value } = e.target;
        setEditEntry((prev) => ({
          ...prev,
          [name]: name === "description" ? value : value === "" ? "" : parseFloat(value),
        }));
      
        console.log("Editing field:", name, "New value:", value);
      };
  
    // Saves the updated entry to Firestore and UI state
    const handleSave = () => {
      onUpdate(item.id, { ...editEntry, subtotal: editEntry.unitCost * editEntry.quantity });
      setIsEditing(false);
    };
  
    // Restores original values if user cancels editing
    const handleCancel = () => {
      setEditEntry({ ...item });
      setIsEditing(false);
    };
  
    return (
      <tr>
        {isEditing ? (
          <>
            <td>
              <input
                type="text"
                name="description"
                value={editEntry.description || ""}
                onChange={handleEditEntryChange}
              />
            </td>
            <td>
              <input
                type="number"
                name="unitCost"
                value={editEntry.unitCost || ""}
                onChange={handleEditEntryChange}
              />
            </td>
            <td>
              <input
                type="number"
                name="quantity"
                value={editEntry.quantity || ""}
                onChange={handleEditEntryChange}
              />
            </td>
            <td>${(editEntry.unitCost * editEntry.quantity).toFixed(2)}</td>
            <td>
              <button onClick={handleSave} className="save-button">Save</button>
              <button onClick={handleCancel} className="cancel-button">Cancel</button>
            </td>
          </>
        ) : (
          <>
            <td>{isEditing ? editEntry.description : item.description}</td>
            <td>${item.unitCost.toFixed(2)}</td>
            <td>{item.quantity}</td>
            <td>${item.subtotal.toFixed(2)}</td>
            <td>
              <select
                className="budget-actions"
                onChange={(e) => {
                  if (e.target.value === "edit") setIsEditing(true);
                  if (e.target.value === "delete") onDelete(item.id);
                }}
              >
                <option value="">Actions</option>
                <option value="edit">Edit</option>
                <option value="delete">Delete</option>
              </select>
            </td>
          </>
        )}
      </tr>
    );
  }

  return (
    <div className="budget-container">
      <BudgetSection
        title="Debits"
        data={debits}
        newEntry={newDebit}
        setNewEntry={setNewDebit}
        onAdd={(entry) => handleAddEntry("debits", entry)}
        onUpdate={handleUpdateEntry}
        onDelete={handleDeleteEntry}
        buttonColor="var(--red-500)"
      />
      <BudgetSection
        title="Credits"
        data={credits}
        newEntry={newCredit}
        setNewEntry={setNewCredit}
        onAdd={(entry) => handleAddEntry("credits", entry)}
        onUpdate={handleUpdateEntry}
        onDelete={handleDeleteEntry}
        buttonColor="var(--green-500)"
      />
    </div>
  );
}