import styles from '../styles/Checkout.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'; 
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import * as Icon from 'react-feather';

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);

  // Load cart from local storage
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    console.log("Initial cart load from storage:", savedCart); // Expanded log
    
    if (!Array.isArray(savedCart)) {
      console.error("Cart data is not an array! Resetting storage.");
      localStorage.removeItem("cart");
      setCartItems([]);
      return;
    }
  
    if (savedCart.length === 0) {
      console.warn("⚠️ Cart loaded as empty. This may indicate a reset happening elsewhere.");
    }
  
    setCartItems(savedCart);
  }, []);

  /*
  // Update local storage when cartItems change
  useEffect(() => {
    console.log("Cart updated, saving to storage:", cartItems);
  
    if (cartItems.length === 0) {
      console.warn("⚠️ Cart is empty after update. Something is clearing it.");
    }
  
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  */

  // Handle quantity change
  const handleQuantityChange = (eventId, newQuantity) => {
    console.log(`Updating quantity for item ${eventId}: New quantity = ${newQuantity}`);
  
    let updatedCart = cartItems.map((item) =>
      item.id === eventId ? { ...item, quantity: newQuantity } : item
    );
  
    if (newQuantity === 0) {
      console.warn(`Removing ${eventId} from cart (quantity reached 0).`);
    }
  
    updatedCart = updatedCart.filter((item) => item.quantity > 0);
    
    console.log("Cart after quantity update:", updatedCart);
    setCartItems(updatedCart);
  };

  // Calculate total price
  const totalPrice = cartItems.reduce((sum, item) => {
    console.log(`Calculating price: ${item.title} | Quantity: ${item.quantity} | Price per Ticket: ${item.ticketPrice}`);
    return sum + (item.ticketPrice * item.quantity);
  }, 0).toFixed(2);

  console.log("Final total price:", totalPrice);

  return (
   <div className="checkout-page">
    <Header 
        avatar="true"
        showLogo="true"
        hasSearchbar
    />
      
    <div className="checkout-content">       
        <div className="checkout-header">
                <h2>Checkout</h2>
                <Link to="/">
                    <Icon.X size={24} />
                </Link>
        </div>  

        <div className="cart-items">
            {cartItems.length > 0 ? (
                cartItems.map((item, index) => (
                    <div key={item.id || index} className="cart-item">
                        {console.log("Rendering cart item:", item)} 
                        <img src={item.thumbnail} alt={item.title} className="cart-image" />
                        <div className="cart-item-details">
                            <h4>{item.title}</h4>
                            <p>{item.date ? new Date(item.date).toLocaleDateString('en-US') : "No Date"}</p>
                            <p>${item.ticketPrice.toFixed(2)} per ticket</p>

                            <div className="quantity-control">
                                <button onClick={() => handleQuantityChange(item.id, item.quantity - 1)}>-</button>
                                <span>{item.quantity}</span>
                                <button onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>+</button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="empty-cart">Your cart is empty.</p>
            )}
        </div>

        <div className="checkout-footer">
            <h3>Total: ${totalPrice}</h3>
            <PrimaryButton buttonLabel="Proceed to Payment" />
        </div>
    </div> {/* Closing the new wrapper */}
</div>
  );
};

export default Checkout;