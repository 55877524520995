import React, { useState, useEffect } from 'react';
import { auth, storage } from '../Firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import * as Icon from 'react-feather';
import '../styles/PostPhoto.css';

const PostPhoto = ({ isVisible, onClose, onFileSelect }) => {
  const [deviceType, setDeviceType] = useState('computer');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [previewURL, setPreviewURL] = useState('');

  // Determine device type based on the user agent
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let type = 'computer';
    if (/android/i.test(userAgent)) {
      type = 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      type = 'ios';
    }
    setDeviceType(type);
  }, []);

  if (!isVisible) return null;

  // Handle file selection and generate a local preview
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const preview = URL.createObjectURL(file);
      setPreviewURL(preview);
    } else {
      setPreviewURL('');
    }
  };

  const handleConfirm = () => {
    if (selectedFile && previewURL) {
      onFileSelect(selectedFile, previewURL);
    }
    onClose();
  };

  return (
    <div className="post-photo-overlay" onClick={onClose}>
      <div className="post-photo-card" onClick={(e) => e.stopPropagation()}>
        <div className="post-photo-header">
          <p className="overlay-title">Upload a Photo</p>
          <div className="close-overlay-button" onClick={onClose} style={{ cursor: 'pointer' }}>
            <Icon.X color="#fff" size={18} />
          </div>
        </div>
        <hr />
        <div className="post-photo-form">
          <input
            type="file"
            accept="image/*"
            {...(deviceType === 'ios' || deviceType === 'android' ? {} : { capture: 'environment' })}
            onChange={handleFileChange}
            className="input-field"
          />
          {previewURL && (
            <div className="thumbnail-preview">
              <img src={previewURL} alt="Preview" style={{ maxWidth: '100%' }} />
            </div>
          )}
        </div>
        <div className="post-photo-footer">
          <button onClick={onClose} className="modal-cancel">Cancel</button>
          <button
            onClick={handleConfirm}
            className="primary-button"
            disabled={!selectedFile}
            style={{ marginLeft: '10px' }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostPhoto;