
const ShimmerComment = () => {

    const circle = {
        width: '50px',
        height: '50px',
        minWidth: '50px',
        minHeight: '50px',
        background: 'linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%)',
        backgroundSize: '300%',
        backgroundPositionX: '100%',
        animation: 'shimmer 1s infinite linear',
        borderRadius: '99px',
    }

    const shimmer = {
        height: "80px",
        width: '100%',
        margin: 'auto 0',
        background: 'linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%)',
        backgroundSize: '300%',
        backgroundPositionX: '100%',
        animation: 'shimmer 1s infinite linear',
        borderRadius:' 10px',
      };
  return (
    <div className="shimmerComment" style={{display: 'flex', gap: '16px'}}>
      <div className="circle" style={circle}>
        
      </div>

      <div className="rectangle shimmer" style={shimmer}>

      </div>
    </div>
  );
}

export default ShimmerComment;
