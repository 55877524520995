import { useEffect, useState } from "react";
import { auth, db, storage } from '../Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import styles from '../styles/CreatePost.css';
import * as Icon from 'react-feather';
import BubblePill from './BubblePill';
import profilePlaceholder from '../assets/profile-placeholder.svg';

import CreateEvent from './CreateEvent';
import CreatePostPopup from './CreatePostPopup';
import PrimaryButton from './PrimaryButton';
import UploadTrack from './UploadTrack';

import fetchUserData from '../utils/fetchUserData';
import PostPhoto from './PostPhoto';
import { saveMentions } from '../utils/saveMentions'


const CreatePost = ({ userData, addPost }) => { // Destructure addPost from props
  const [content, setContent] = useState('');
  const [isCreateEventPopupVisible, setIsCreateEventPopupVisible] = useState(false);
  const [isPostPhotoVisible, setIsPostPhotoVisible] = useState(false); // For PostPhoto component
  const [isPostPopupVisible, setIsPostPopupVisible] = useState(false);
  const [isUploadTrackVisible, setIsUploadTrackVisible] = useState(false);
  const [profilePic, setProfilePic] = useState(profilePlaceholder);
  const [selectedFile, setSelectedFile] = useState(null) // Initialize with placeholder image
  const [uploadedImageURL, setUploadedImageURL] = useState(null); // For uploaded images
  const [uploadStatus, setUploadStatus] = useState(''); // For upload status messages
  const [userID, setUserID] = useState(null);
  const [userHandle, setUserHandle] = useState('@username');
  const [userMarket, setUserMarket] = useState("global");

  const togglePopup = () => {
    setIsPostPopupVisible(prev => !prev);
  };

  const toggleCreateEventPopup = () => {
    setIsCreateEventPopupVisible(prev => !prev);
  }  

  const openUploadTrackModal = () => {
    setIsUploadTrackVisible(true);
  };

  useEffect(() => {
    const initializeUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserID(user.uid);
        try {
          const userData = await fetchUserData(user.uid, db);
          if (userData) {
            setUserHandle(`${userData.handle || 'username'}`);
            setProfilePic(userData.profilePic || profilePlaceholder);
            setUserMarket(userData.market || "global");
          }
        } catch (error) {
          console.error('Error initializing user data:', error);
        }
      } else {
        console.warn('No authenticated user found');
      }
    };

    initializeUserData();
  }, []);


  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('No file selected.');
      return '';
    }
  
    const uid = auth.currentUser?.uid || 'anonymous';
    const storagePath = `/Public/${uid}/${selectedFile.name}`;
    const fileRef = ref(storage, storagePath);
  
    try {
      console.log('handleUpload: Uploading file to:', storagePath);
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      setUploadStatus('Upload successful!');
      return downloadURL;
    } catch (error) {
      console.error('Error uploading photo:', error);
      setUploadStatus('Upload failed.');
      return '';
    }
  };

  const handlePost = async () => {
    console.log('handlePost called');

    if (!content.trim()) {
      console.error('Please enter some content before posting.');
      return;
    }

    if (!userID) {
      console.error('User ID is not set');
      return;
    }

    try {
      let userData;
      if (!userHandle || userHandle === '@username') {
        userData = await fetchUserData(userID, db);
        setUserHandle(userData.handle);
        setProfilePic(userData.profilePic);
      } else {
        userData = { market: userMarket };
      }

      if (!userData || !userData.market) {
        console.error('User market is missing');
        return;
      }
           
      const downloadURL = await handleUpload();
      console.log('Download URL:', downloadURL);
      // Create the post in Firestore
      const postRef = await addDoc(collection(db, 'posts'), {
        userID: userID,
        content: content,
        mediaType: downloadURL ? 'image' : 'text',
        mediaURL: downloadURL ? downloadURL : '',
        timestamp: serverTimestamp(),
        topics: ['discuss', userMarket],
        comments: '',
        likes: '',
      });

      console.log('Post created successfully:', postRef.id);
      setContent('');
      setUploadedImageURL(null);

      const newPost = {
        id: postRef.id,
        userID,
        content,
        mediaType: 'text',
        mediaURL: '',
        timestamp: new Date(),
        topics: ['discuss', userMarket],
        comments: '',
        likes: '',
      };

      addPost(newPost); // Update the posts state in the parent component

      await saveMentions(content, userID, postRef.id);
      console.log('Mentions saved successfully.');
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  return (
    <div className="create-post component">
      <div className="container">
        <div className="image-text">
          <div className="user-image">
            <img src={profilePic} alt="User Profile" /> {/* Display fetched profilePic */}
          </div>
          <div className="content">
            <p className="user-handle">{userHandle}</p>
            
            <div className="image-upload">
            {uploadedImageURL && (
              <div className="uploaded-image-preview">
                <img src={uploadedImageURL} alt="Uploaded Preview" style={{ maxWidth: '100%' }} />
              </div>
            )}
            </div>

            <textarea
              className="post-compose"
              placeholder="What's up?"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <div className="post-actions">
              <div className="add-content">
                <div className='add-photos icon-btn' title="Post an Image">
                  <Icon.Image 
                    color='#0094D4' 
                    size={24} 
                    style={{ margin: 'auto 0' }} 
                    onClick={() => {
                      console.log("Add Photo Clicked, toggling PostPhoto component...");
                      setIsPostPhotoVisible(true);
                    }}
                />
                  
                </div>
                <div className='add-track icon-btn' title="Upload Track">
                  <Icon.Music 
                    color='#0094D4' 
                    size={24} 
                    style={{ margin: 'auto 0' }} 
                    onClick={() => {
                      console.log("Upload Track Clicked, opening upload track modal...");
                      openUploadTrackModal();
                    }} 
                  />
                </div>
                <div className='add-event icon-btn' title="Create an Event" >
                  <Icon.Calendar color='#0094D4' size={24} style={{ margin: 'auto 0' }} 
                  onClick={() => {
                    console.log("Create Event Clicked, toggling event modal...");
                    toggleCreateEventPopup();
                    console.log("isCreateEventPopupVisible:", isCreateEventPopupVisible);
                    }} />
                </div>
                
                
                {/*
                !!!!! Bubble Feature is hidden until user topics are implemented !!!!
                <div style={{ cursor: "not-allowed"}}>
                  <BubblePill bubbleText="+ Add Bubble" />
                </div>
                */}
               
              </div>
              <div className="btn">
                <PrimaryButton
                  buttonLabel="POST"
                  onClick={() => {
                    console.log("I got clicked");
                    handlePost();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* POPUPS*/}
      <CreatePostPopup isVisible={isPostPopupVisible} onClose={togglePopup} />
      <CreateEvent isVisible={isCreateEventPopupVisible} onClose={toggleCreateEventPopup} />
      <UploadTrack isVisible={isUploadTrackVisible} onClose={() => setIsUploadTrackVisible(false)} />
      <PostPhoto
        isVisible={isPostPhotoVisible}
        onClose={() => setIsPostPhotoVisible(false)}
        onFileSelect={(file, previewURL) => {
          setSelectedFile(file);
          setUploadedImageURL(previewURL);
        }}
      />
    </div>
  );
};

export default CreatePost;