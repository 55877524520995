import styles from '../../styles/CommentSection.css';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, doc, updateDoc, deleteDoc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { auth, db } from "../../Firebase";
import * as Icon from "react-feather";
import profilePlaceholder from "../../assets/profile-placeholder.svg";
import style from "../../styles/RepliesSection.css";

const RepliesSection = ({ comment, post, visibleReplyOptionsId, setVisibleReplyOptionsId }) => {
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [editReplyContent, setEditReplyContent] = useState("");
  const [replies, setReplies] = useState([...comment.replies]);
  const [replyingToReplyId, setReplyingToReplyId] = useState(null);
  const [replyLikes, setReplyLikes] = useState({});
  const [replyContent, setReplyContent] = useState("");

  useEffect(() => {
    let isMounted = true; // Prevent updates on unmounted components
    const fetchReplies = async () => {
      try {
        const repliesCollection = collection(db, "posts", post.id, "comments", comment.id, "replies");
        const repliesSnapshot = await getDocs(repliesCollection);
        const fetchedReplies = repliesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (isMounted) {
          setReplies((prevReplies) =>
            fetchedReplies.map((newReply) => {
              const existingReply = prevReplies.find((r) => r.id === newReply.id);
              return existingReply
                ? { ...existingReply, content: newReply.content } // Preserve userHandle & profilePic
                : newReply;
            })
          );
        }
      } catch (error) {
        console.error("Error fetching replies:", error);
      }
    };

    fetchReplies();
    return () => {
      isMounted = false;
    };
  }, [comment.id, post.id]); 
  
  const addReply = async (replyToId) => {
    if (!replyContent.trim()) return;
  
    try {
      const replyRef = doc(collection(db, "posts", post.id, "comments", comment.id, "replies"));
      await setDoc(replyRef, {
        content: replyContent,
        replyUserHandle: auth.currentUser?.displayName || "Anonymous",
        replyUserProfilePic: auth.currentUser?.photoURL || profilePlaceholder,
        userID: auth.currentUser?.uid,
        timestamp: new Date(),
        parentReplyId: replyToId, // Support nested replies
      });
  
      const newReplySnap = await getDoc(replyRef);
      const newReply = { id: replyRef.id, ...newReplySnap.data() };
  
      setReplyContent("");
      setReplyingToReplyId(null);
      setReplies((prev) => [...prev, newReply]);  // Directly add the new reply to state without re-fetching
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };
  
  const editReply = async (replyId) => {
    if (!editReplyContent.trim()) return;
  
    try {
      const replyRef = doc(db, "posts", post.id, "comments", comment.id, "replies", replyId);
      await updateDoc(replyRef, { content: editReplyContent });
  
      console.log("Reply updated in Firestore:", replyId);
  
      // Fetch the updated reply
      const updatedReplySnap = await getDoc(replyRef);
      if (!updatedReplySnap.exists()) {
        console.error("Reply does not exist after update:", replyId);
        return;
      }
  
      const updatedReplyData = updatedReplySnap.data();
  
      // Preserve `replyUserHandle` and `replyUserProfilePic` from existing state
      setReplies((prevReplies) =>
        prevReplies.map((reply) =>
          reply.id === replyId
            ? {
                ...reply,  // Preserve existing fields (like userHandle and profilePic)
                content: updatedReplyData.content, // Update only the content
              }
            : reply
        )
      );
  
      setEditingReplyId(null);
      setEditReplyContent("");
    } catch (error) {
      console.error("Error updating reply:", error);
    }
  };

  const deleteReply = async (replyId) => {
    if (!replyId) return;
  
    const confirmation = window.confirm("Are you sure you want to delete this reply? This action cannot be undone.");
    if (!confirmation) return;
  
    try {
      const replyRef = doc(db, "posts", post.id, "comments", comment.id, "replies", replyId);
      await deleteDoc(replyRef);
  
      console.log("Reply deleted:", replyId);
  
      setReplies((prevReplies) => prevReplies.filter((reply) => reply.id !== replyId));
  
      setVisibleReplyOptionsId(null);
    } catch (error) {
      console.error("Error deleting reply:", error);
    }
  };

  const maxLength = 350;
  const handleMaxLength = (e) => {

    let newValue = e.target.value;

    // Enforce maxLength by slicing the string if necessary
    if (newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setEditReplyContent(newValue);
  };

  const toggleReplyLike = async (replyId) => {
    if (!auth.currentUser) {
      console.error("User not logged in.");
      return;
    }

    try {
      const replyRef = doc(db, "posts", post.id, "comments", comment.id, "replies", replyId);
      const replySnap = await getDoc(replyRef);

      if (!replySnap.exists()) return;

      const existingLikes = replySnap.data().likes || {};
      const userId = auth.currentUser?.uid;

      let updatedLikes = { ...existingLikes };
      let isLiked = !!updatedLikes[userId];

      if (isLiked) {
          delete updatedLikes[userId];
      } else {
          updatedLikes[userId] = true;
      }

      await updateDoc(replyRef, { likes: updatedLikes });

      // Update the state properly
      setReplyLikes((prev) => ({
          ...prev,
          [replyId]: updatedLikes,
      }));
    } catch (error) {
      console.error("Error toggling reply like:", error);
    }
  };
  
return (
  <div className="replies-section">
    {replies.map((reply) => (  // ✅ Using `replies` from state
      <div key={reply.id} className="single-reply">

        {/* ✅ Profile Picture */}
        <div className="user-img">
          <img
            src={reply.replyUserProfilePic || profilePlaceholder}
            alt="User profile"
          />
        </div>
        <div className="reply-by">
          <div className="name-and-dots">
            <div className='with-timestamp'>
              <Link to={`/${reply.replyUserHandle}`} style={{ textDecoration: "none" }}>
                <p className="reply-by-name">{reply.replyUserHandle || "Unknown User"}</p>
              </Link>
              <span className="reply-by-date">
                {reply.timestamp && typeof reply.timestamp.toDate === "function"
                  ? `${new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(reply.timestamp.toDate())} | ${
                      new Intl.DateTimeFormat("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }).format(reply.timestamp.toDate())
                    }`
                  : "N/A"}
              </span>
            </div>

            <div className="reply-more-icon"
              onClick={() => setVisibleReplyOptionsId(visibleReplyOptionsId === reply.id ? null : reply.id)}>
              <Icon.MoreVertical size={16} />

              {visibleReplyOptionsId === reply.id && (
                <div className="reply-options-modal">
                  <ul className="reply-options-list">
                    {reply.userID === auth.currentUser?.uid && (
                      <>
                        <li onClick={() => {
                            setEditingReplyId(reply.id);
                            setEditReplyContent(reply.content);
                            setVisibleReplyOptionsId(null);
                          }}
                          className='reply-option-li'>
                          <Icon.Edit3 size={14} className="icon" />
                          <p className="option-name">Edit Reply</p>
                        </li>
                        <li onClick={() => {
                            deleteReply(reply.id);
                            setVisibleReplyOptionsId(null);
                          }}
                          className='reply-option-li'>
                          <Icon.Trash size={14} className="icon" />
                          <p className="option-name">Delete Reply</p>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
            
          </div>
          <div className="reply-by-details">
            
            {/* ✅ Reply Content */}
            {editingReplyId === reply.id ? (
              <div className="edit-reply-input">
                <textarea
                  value={editReplyContent}
                  onChange={(e) => setEditReplyContent(e.target.value)}
                  placeholder="Edit your reply..."
                  onKeyDown={handleMaxLength} // Handles Enter key submission
                  maxLength={maxLength} // Native enforcement
                />
                <p 
                  style={{fontSize: 'var(--text-xs)', width: '80px',textAlign: 'right', color: 'var(--text-grey)', margin: 'auto 0', padding: '0'}}>
                    {replyContent.length + " / " + maxLength}
                </p>
                <div className="edit-reply-buttons">
                  <button onClick={() => editReply(reply.id)}>Post Comment</button>
                  <button onClick={() => setEditingReplyId(null)}>Cancel</button>
                </div>
              </div>
            ) : (
              <p className="reply-body">{reply.content}</p> // ✅ Will now reflect updated state
            )}
          </div>
          {/* Like & Reply Buttons */}
          <div className="like-comment">
            <button onClick={() => toggleReplyLike(reply.id)} className="like-button">
              <Icon.ThumbsUp
                size={16}
                fill={replyLikes[reply.id] && replyLikes[reply.id][auth.currentUser?.uid] ? "#1D599C" : "none"}
                color={replyLikes[reply.id] && replyLikes[reply.id][auth.currentUser?.uid] ? "#0094D4" : "#fff"}
              />
              <p className='like-count'>{Object.keys(replyLikes[reply.id] || {}).length || 0}</p>
            </button>

            <button onClick={() => setReplyingToReplyId(reply.id)} className="reply-button" style={{display: 'none'}}>
              <Icon.MessageSquare size={16} />
            </button>
          </div>


          {/* Reply Input Field for Nested Replies */}
          {replyingToReplyId === reply.id && (
            <div className="reply-input">
              <textarea
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="Reply to this reply..."
                onKeyDown={handleMaxLength} // Handles Enter key submission
                maxLength={maxLength} // Native enforcement
              />
              <p 
                style={{fontSize: 'var(--text-xs)', width: '80px',textAlign: 'right', color: 'var(--text-grey)', margin: 'auto 0', padding: '0'}}>
                  {replyContent.length + " / " + maxLength}
              </p>
              <button onClick={() => addReply(reply.id)}>Reply</button>
              <button onClick={() => setReplyingToReplyId(null)}>Cancel</button>
            </div>
          )}

          {/* Nested Replies */}
          {reply.parentReplyId && (
            <RepliesSection comment={comment} post={post} parentReplyId={reply.id} />
          )}
        </div>
      </div>
    ))}
  </div>
);
};

export default RepliesSection;