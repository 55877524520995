import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../../Firebase"; // Ensure firebase is correctly configured
import { collection, query, where, getDocs } from "firebase/firestore";
import Profile from "../../screens/Profile";
import Loading from "../../components/Loading";

const ProfileWrapper = () => {
  const { handle } = useParams();
  const [userExists, setUserExists] = useState(null);

  useEffect(() => {
    const checkUserExists = async () => {
      console.log("Running checkUserExists for handle:", handle);

      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("handle", "==", handle));
        const querySnapshot = await getDocs(q);

        console.log("Firestore query completed. Documents found:", querySnapshot.size);

        setUserExists(!querySnapshot.empty);
      } catch (error) {
        console.error("Error fetching user:", error);
        setUserExists(false);
      }
    };

    if (handle) {
      checkUserExists();
    }
  }, [handle]);

  if (userExists === null) {
    return <div><Loading /></div>; // Show loading state while checking Firestore
  }

  if (!userExists) {
    return <Navigate to="/404" />;
  }

  return <Profile />;
};

export default ProfileWrapper;