import { useState } from "react";
import { auth, db, storage } from '../Firebase'; // Firestore instance import
import { addDoc, collection, getDocs, query, where, serverTimestamp } from "firebase/firestore"; // Firestore imports
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"; // Storage imports (if needed for thumbnails)

import styles from '../styles/CreateEvent.css';
import * as Icon from 'react-feather';
import PrimaryButton from '../components/PrimaryButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const CreateEvent = ({ isVisible, onClose, children, onEventPosted }) => {
  const [preview, setPreview] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const today = new Date();
  const [uploadStatus, setUploadStatus] = useState('');

  // Form state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [ticketPrice, setTicketPrice] = useState('');
  const [thumbnail, setThumbnail] = useState(null);

  // Performers state
  const [inputPerformersValue, setInputPerformersValue] = useState("");
  const [performersValues, setPerformersValues] = useState([]);

  // Promoters state
  const [inputPromotersValue, setInputPromotersValue] = useState('');
  const [promotersValues, setPromotersValues] = useState([]);

    // Helper function: Convert promoter handles to submergeId if a matching user is found
    const convertPromotersToSubmergeIds = async (promoters) => {
      const usersRef = collection(db, 'users');
      const updatedPromoters = await Promise.all(promoters.map(async (promoter) => {
        const q = query(usersRef, where('handle', '==', promoter));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          return userDoc.data().submergeID;
        }
        return promoter;
      }));
      return updatedPromoters;
    };
 
  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // Set thumbnail preview
        setThumbnail(file); // Save file for later use
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null); // Reset preview if no file
      setThumbnail(null);
    }
  };

  const handleThumbnailUpload = async (selectedFile, setUploadStatus) => {
    if (!selectedFile) {
      setUploadStatus('No file selected.');
      return '';
    }
    
    const uid = auth.currentUser?.uid || 'anonymous';
    const storagePath = `/Events/${uid}/${selectedFile.name}`;
    const fileRef = ref(storage, storagePath);
    
    try {
      console.log('Uploading event thumbnail to:', storagePath);
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);
    
      setUploadStatus('Upload successful!');
      return downloadURL;
    } catch (error) {
      console.error('Error uploading event thumbnail:', error);
      setUploadStatus('Upload failed.');
      return '';
    }
  };
  

  // Generalized handler for Enter/Comma inputs
  const handleKeyDown = (event, inputValue, setInputValue, values, setValues) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault(); // Prevent form submission on Enter
      const trimmedValue = inputValue.trim();

      if (trimmedValue !== '') {
        setValues([...values, trimmedValue]);
        setInputValue(''); // Clear the input field
      }
    }
  };

  // Generalized blur handler
  const handleBlur = (inputValue, setInputValue, values, setValues) => {
    const trimmedValue = inputValue.trim();

    if (trimmedValue !== '') {
      setValues([...values, trimmedValue]);
      setInputValue('');
    }
  };

  // Generalized remove handler
  const removeValue = (indexToRemove, values, setValues) => {
    setValues(values.filter((_, index) => index !== indexToRemove));
  };

  const handlePostEvent = async (e) => {
  e.preventDefault();

  if (!title || !description || !startDate || !location) {
    alert('Please fill in all required fields.');
    return;
  }

  try {
    // Convert promoter handles to submergeId if available
    const updatedPromoters = await convertPromotersToSubmergeIds(promotersValues);
    
    // Upload the thumbnail if available
    let thumbnailURL = '';
    if (thumbnail) {
      thumbnailURL = await handleThumbnailUpload(thumbnail, setUploadStatus);
    }
    
    const eventRef = collection(db, 'events');
    const newEvent = {
      title,
      description,
      date: startDate,
      location: [location.mainText, location.googleMapsLink], // Store location as an array
      ticketPrice: ticketPrice || 'Free',
      performers: performersValues,
      promoters: updatedPromoters,
      thumbnail: thumbnailURL, // Write the downloadURL to the thumbnail field
      timestamp: serverTimestamp(),
    };
    console.log('New event data:', newEvent);
    // Create event and corresponding post in Firestore
    const postsRef = collection(db, 'posts');
    const newPost = {
      userID: auth.currentUser?.uid,
      postType: 'event',
      eventId: '', // Will be replaced with the new event doc ID
      timestamp: serverTimestamp(),
    };
    const eventDocRef = await addDoc(eventRef, newEvent);
    newPost.eventId = eventDocRef.id;
    await addDoc(postsRef, newPost);

    if(onEventPosted) {
      onEventPosted();
    }

    onClose(); // Close the modal after successful post
  } catch (error) {
    console.error('Error posting event:', error);
    alert('Failed to post event. Please try again.');
  }
};

  if (!isVisible) return null;

  return (
    <div className="create-event-overlay">
      <div className="create-event-card" onClick={(e) => e.stopPropagation()}>
        {children}
        <div className="create-event-header">
          <p className="overlay-title">Post an Event</p>
          <div className="close-overlay-button" onClick={onClose} style={{ cursor: 'pointer' }}>
            <Icon.X color="#fff" size={18} />
          </div>
        </div>

        <hr />

        <form className="create-event-form" onSubmit={handlePostEvent}>
          <div className="input-fields-section">
            {/* Thumbnail */}
            <div className="media-uploader form-field">
              <p className="label"> Event Thumbnail </p>
              <div className="upload-thumbnail">
                {preview && (
                  <div className="thumbnail-preview">
                    <img className="image-preview" src={preview} alt="Image Preview" />
                  </div>
                )}
                <div className="file-uploader">
                  <div className="uploader-icon">
                    <Icon.Image size={32} color="var(--acc-blue)" />
                  </div>
                  <label htmlFor="track-thumbnail" className="clickable-label">
                    Click here to upload an event thumbnail
                  </label>
                  <input
                    id="track-thumbnail"
                    type="file"
                    onChange={handleThumbnailChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>

            {/* Title */}
            <div className="form-event-title form-field">
              <label htmlFor="event-title" className="label">Title</label>
              <input
                id="event-title"
                placeholder="Event Title"
                required
                className="input-field"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            {/* Description */}
            <div className="form-event-description form-field">
              <label htmlFor="event-description" className="label">Description</label>
              <textarea
                id="event-description"
                className="post-content input-field"
                placeholder="Event description"
                rows="10"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            {/* Date */}
            <div className="form-event-date form-field" style={{ display: 'block' }}>
              <p className="label">When will this happen?</p>
              <div className="datepicker-container">
              <DatePicker
                className="input-field datepicker"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd MMM yyyy h:mm aa" // Updated format to include time
                showTimeSelect // Enable time selection
                timeFormat="h:mm" // 24-hour time format
                timeIntervals={15} // Time intervals (e.g., every 15 minutes)
                timeCaption="Time" // Caption for the time selector
                minDate={today}
              />
                <Icon.Calendar size={14} color="var(--acc-blue)" className="calendar-icon" />
              </div>
            </div>

            {/* Location with Google Places Autocomplete */}
          <div className="form-event-location form-field">
            <label htmlFor="event-location" className="label">Location</label>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyAqTHgK4dWvAJn3xWKDFDwYHnhSqofOUJo" // Replace with your API key
              selectProps={{
                location,
                onChange: (value) => {
                  if (value) {
                    if (value.structured_formatting) {
                      const mainText = value.structured_formatting.main_text; // Get main text (e.g., city name)
                      const placeId = value.value; // Get the place ID
                      const googleMapsLink = `https://www.google.com/maps/place/?q=place_id:${placeId}`; // Construct Google Maps URL

                      console.log("Selected main text:", mainText);
                      console.log("Google Maps Link:", googleMapsLink);

                      setLocation({ mainText, googleMapsLink }); // Save both main text and hyperlink in state
                    } else {
                      // Fallback: accept the user's input even if it doesn't match a Google Maps place
                      const mainText = value.label || value.description || value.toString();
                      console.log("Fallback location selected:", mainText);
                      setLocation({ mainText, googleMapsLink: '' });
                    }
                  }
                },
                placeholder: "Search for a location",
              }}
            />
          </div>

            {/* Performers */}
            <div className="form-event-performers form-field">
              <label className="label">Performers</label>
              <div className="pill-container">
                {performersValues.map((value, index) => (
                  <p key={index} className="pill">
                    {value}
                    <button
                      className="remove-performer-button"
                      onClick={() => removeValue(index, performersValues, setPerformersValues)}
                    >
                      <Icon.X size={16} />
                    </button>
                  </p>
                ))}
              </div>
              <input
                className='input-field'
                type="text"
                value={inputPerformersValue}
                onChange={(e) => setInputPerformersValue(e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(e, inputPerformersValue, setInputPerformersValue, performersValues, setPerformersValues)
                }
                onBlur={() =>
                  handleBlur(inputPerformersValue, setInputPerformersValue, performersValues, setPerformersValues)
                }
                placeholder="Enter performers, separated by commas or press Enter"
              />
              <p className="subtext">Separate performers with commas or press Enter to add.</p>
            </div>

            {/* Promoters */}
            <div className="form-event-promoters form-field">
              <label className="label">Promoters</label>
              <div className="pill-container">
                {promotersValues.map((value, index) => (
                  <p key={index} className="pill">
                    {value}
                    <button
                      className="remove-promoter-button"
                      onClick={() => removeValue(index, promotersValues, setPromotersValues)}
                    >
                      <Icon.X size={16} />
                    </button>
                  </p>
                ))}
              </div>
              <input
                className='input-field'
                type="text"
                value={inputPromotersValue}
                onChange={(e) => setInputPromotersValue(e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(e, inputPromotersValue, setInputPromotersValue, promotersValues, setPromotersValues)
                }
                onBlur={() =>
                  handleBlur(inputPromotersValue, setInputPromotersValue, promotersValues, setPromotersValues)
                }
                placeholder="Enter promoters, separated by commas or press Enter"
              />
              <p className="subtext">Separate promoters with commas or press Enter to add.</p>
            </div>

            {/* Ticket Price */}
            <div className="form-event-ticket form-field">
              <label htmlFor="event-ticket" className="label">Ticket Price (USD $)</label>
              <input
                id="event-ticket"
                placeholder="Ticket Price"
                className="input-field"
                value={ticketPrice}
                onChange={(e) => setTicketPrice(e.target.value)}
              />
              <p className="subtext">Leave empty if admission's free.</p>
            </div>

            {/* Submit Button */}
            <div className="form-buttons">
              <PrimaryButton buttonLabel="Post" className="button" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEvent;