import * as Icon from 'react-feather';
import { useContext, useEffect, useState, useRef } from "react";
import styles from '../styles/PlayRadioButton.css'
import { RadioContext } from '../context/RadioContext';
import radioOff from '../assets/off.svg';

function PlayRadioButton () {
    const { toggleRadio, setIsRadioVisible } = useContext(RadioContext);
    const [isRadioOn, setIsRadioOn] = useState(false);
    
    // Toggle radio 
    const handleToggleRadio = () => {
        toggleRadio();
        setIsRadioOn((prevState) => !prevState);

        if (isRadioOn) {
            setIsRadioVisible(false); // Ensure the player hides when stopping
        }
    };
  return (
    <div className="toggleRadioOff radio-switch" onClick={handleToggleRadio}>
        <div className="switch-icon">
            <img src={radioOff} />
        </div>
        <h4 className="text-def">Play Live Radio</h4>
    </div>
  );
}

export default PlayRadioButton;
