import React, { useState, useEffect } from 'react';
import { auth } from '../Firebase'; // Firebase authentication import
import { updateEmail, updatePassword } from 'firebase/auth'; // Firebase functions to update email/password
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import styles from '../styles/EditEmailPassword.css'

const EditEmailPassword = () => {
  const [email, setEmail] = useState(''); // State for email input
  const [password, setPassword] = useState(''); // State for password input
  const [error, setError] = useState(''); // Error handling state
  const [success, setSuccess] = useState(''); // Success message state

  useEffect(() => {
    // Set the current user's email in the input field when the component mounts
    if (auth.currentUser) {
      setEmail(auth.currentUser.email);
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setError(''); // Reset error
    setSuccess(''); // Reset success message

    const user = auth.currentUser;

    try {
      if (email !== user.email) {
        // Update email if it has changed
        await updateEmail(user, email);
      }

      if (password) {
        // Update password if it's provided
        await updatePassword(user, password);
      }

      setSuccess('Email and password updated successfully!');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="edit-email-password">
      <form className="edit-emailpass-form" onSubmit={handleSave}>
        <div className="input-fields-section">
          <div className="form-email form-field">
            <p className="label">EMAIL</p>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>

          <div className="form-password form-field">
            <p className="label">PASSWORD</p>
            <input
              type="password"
              placeholder="New Password (leave blank if unchanged)"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}

        <div className="form-buttons">
          {/*<SecondaryButton buttonLabel="Cancel" className="button" />*/}
          <PrimaryButton buttonLabel="Save Changes" className="button" />
        </div>
      </form>
    </div>
  );
};

export default EditEmailPassword;