import * as Icon from 'react-feather';
import Slider from "react-slick";
import sampleImg from '../assets/avatar.jpg';
import fetchRadioStations from '../utils/fetchRadioStations';

import { useContext, useEffect, useRef, useState } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as rtdbRef, child, get } from "firebase/database";
import { RadioContext } from "../context/RadioContext";

import styles from '../styles/RadioPage.css'
import radioPlaceholder from '../assets/cd-transparent.svg';
import NewsfeedCard from '../components/NewsfeedCard';
import Header from '../components/Header';

const RadioCarousel = ({ currentStationId, onHighlight, onPlayChannel }) => {
    const { isRadioVisible, setIsRadioVisible } = useContext(RadioContext); 
    console.log("RadioContext in RadioCarousel:", { isRadioVisible, setIsRadioVisible });

    const [radioStations, setRadioStations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        fetchRadioStations().then((stations) => {
            setRadioStations(stations);
            if (onHighlight && stations.length > 0) {
                onHighlight(stations[0]); // Default to first station
                console.log("Highlighting default station:", stations[0]);
            }
        }).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (currentStationId && radioStations.length > 0) {
            const index = radioStations.findIndex(station => station.id === currentStationId);
            if (index !== -1) {
                setActiveIndex(index);
            }
        }
    }, [currentStationId, radioStations]);

    useEffect(() => {
        setIsRadioVisible(false); 
        console.log("RadioCarousel - Hiding RadioPlayer");
        return () => setIsRadioVisible(true);
    }, []);

    const settings = {
        infinite: true,
        centerPadding: 0,
        centerMode: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: false,
        arrows: true,
        initialSlide: activeIndex,
        beforeChange: (oldIndex, newIndex) => {
            setActiveIndex(newIndex);
            if (onHighlight && radioStations[newIndex]) {
                onHighlight(radioStations[newIndex]);
                console.log("Highlighting station before change:", radioStations[newIndex]);
            }
        },
        afterChange: (current) => {
            if (onHighlight && radioStations[current]) {
                onHighlight(radioStations[current]);
                console.log("Highlighting station after arrow nav:", radioStations[current]);
            }
        }
    }; 

    return (
        <div className="radio-carousel-component">
            <div className="slider-container">
                {loading ? <p>Loading stations...</p> : (
                    <Slider ref={sliderRef} {...settings} slickGoTo={activeIndex}>
                        {radioStations.map((station, index) => (
                            <div key={station.id} className='carousel-container' onClick={() => {
                                if (index !== activeIndex) {
                                    setActiveIndex(index);
                                    if (onHighlight) onHighlight(station);
                                    sliderRef.current?.slickGoTo(index);
                                    console.log("Station selected via click:", station);
                                }
                            }}>
                                <div className='poster'>
                                    <img src={station.logo || sampleImg} alt={station.stationName} />
                                </div>
                                <div className="radio-deets">
                                    <h4>{station.name || "Unknown Station"}</h4>
                                    <div className="action-buttons">
                                        <div className="play-button" onClick={() => {
                                            console.log("RadioCarousel: Play button clicked - station id:", station.id, "index:", index);
                                            if (onPlayChannel) {
                                                onPlayChannel(station, index);
                                                console.log("Play Channel clicked for station:", station);
                                            }
                                        }}>
                                            <Icon.PlayCircle size={14} color="var(--cyan)" />
                                            <p>Play Channel</p>
                                        </div>
                                        <div className="add-to-likes">
                                            <Icon.Heart size={16} color="var(--cyan)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    ); 
}

function RadioPage () {
const { 
      isRadioPlaying, 
      setIsRadioPlaying,
      currentStationId, 
      setCurrentStationId, 
      setChannel
    } = useContext(RadioContext);
    const [radioStations, setRadioStations] = useState([]);
    const [highlightedStation, setHighlightedStation] = useState(null);
    const [requestedStationId, setRequestedStationId] = useState(null);
    const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  const fetchRadioStations = async () => {
    try {
      const database = getDatabase();
      const dbRef = rtdbRef(database);
      const snapshot = await get(child(dbRef, 'Stations'));
      if (snapshot.exists()) {
        const stationsObj = snapshot.val();
        // Convert the stations object into an array
        const stations = Object.keys(stationsObj).map(key => ({
          id: key,
          ...stationsObj[key]
        }));
        setRadioStations(stations);
        setHighlightedStation(stations[0]); // Set default highlighted station
        console.log("Fetched radio stations:", stations);
      } else {
        console.log("No radio stations available");
      }
    } catch (error) {
      console.error("Error fetching radio stations:", error);
    }
  };

  useEffect(() => {
    fetchRadioStations();
  }, []);
  
  useEffect(() => {
    console.log("Highlighted station changed:", highlightedStation);
  }, [highlightedStation]);

  return (
    <div className="radio-page">
      <Header showLogo goBack />
      <div className="radio-stations-carousel">
        <RadioCarousel
          stations={radioStations}
          onHighlight={(station) => {
            console.log("Highlighted station:", station);
            setHighlightedStation(station);
          }}
          onPlayChannel={(station, index) => {
            console.log("RadioPage: Play Channel clicked for station:", station);
            console.log("RadioPage: setChannel called with id:", station.id, "index:", index);
            setChannel(station.id, index);
            setHighlightedStation(station);
          }}
        />
        <div className="station-description">
          <h4 className="station-name">{highlightedStation?.name || "Radio Channel Name"}</h4>
          <p className="description">{highlightedStation?.description || "Electronic, Indie Dance, Indie Pop, Alt R&B, Nu Disco, House, & more."}</p>
        </div>
        <div className="whats-up">
          <div className="title"
            onMouseEnter={() => setIsTooltipHovered(true)}
            onMouseLeave={() => setIsTooltipHovered(false)}>
            <p>What's up?</p>
            <div className="tooltip-icon">
              <Icon.HelpCircle size={14} />
            </div>
          </div>

          {isTooltipHovered && (
          <div className="tooltip">
            <p>Station schedules, events, etc. will be posted here.</p>
          </div>
          )}
          <div className="whats-up-content">
            <p style={{textAlign: 'center', color: 'var(--text-grey)', fontSize: 'var(--text-md)'}}>No updates for this radio channel as of the moment.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RadioPage;