import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import { useContext } from "react";
import { RadioContext } from "../context/RadioContext";
import RadioPlayerNew from "../components/RadioPlayerNew";
import PlayRadioButton from "../components/PlayRadioButton";


const PlayerLayout = () => {
  const { isRadioVisible } = useContext(RadioContext);

  return (
    <div className="layout">
      <Header />
      <main>
        <Outlet />
      </main>
      {/* Render the radio player only if the header is present */}
      {isRadioVisible === true ? (
          <RadioPlayerNew />
      ) : (
          <PlayRadioButton />
      )}
    </div>
  );
};

export default PlayerLayout;