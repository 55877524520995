// src/utils/fetchRadioStations.js

import { getDatabase, ref, get, child } from "firebase/database";

/**
 * Fetches radio stations from Firebase Realtime Database and sorts them by sort_number.
 * @returns {Promise<Array>} Sorted list of radio station objects.
 */
const fetchRadioStations = async () => {
  const dbRef = ref(getDatabase());
  try {
    const snapshot = await get(child(dbRef, 'Stations'));
    if (!snapshot.exists()) {
      console.warn("No stations found in Realtime Database.");
      return [];
    }

    const data = snapshot.val();
    const stations = Object.entries(data).map(([id, station]) => ({
      id,
      ...station,
      // Use the 'server' field if available; default to 's2'
      server: station.server || 's2'
    }));

    return stations.sort((a, b) => (a.sort_number ?? 0) - (b.sort_number ?? 0));
  } catch (error) {
    console.error("Error fetching radio stations:", error);
    return [];
  }
};

export default fetchRadioStations;