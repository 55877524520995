import styles from '../styles/RadioPlayerNew.css';
import radioPlaceholder from '../assets/cd-transparent.svg';
import * as Icon from 'react-feather';
import { useContext, useEffect, useRef, useState } from "react";
import { RadioContext } from "../context/RadioContext"
import { collection, getDocs } from "firebase/firestore";
import { getDatabase, ref as rtdbRef, child, get } from "firebase/database";

const RadioPlayerNew = () => {
    const { isRadioPlaying, setIsRadioPlaying, currentStationId } = useContext(RadioContext);
    const audioRef = useRef(null);
    const [trackInfo, setTrackInfo] = useState({
        title: "•••",
        artist: "Artist",
    });
    const [radioStations, setRadioStations] = useState([]); 
    const [currentStationIndex, setCurrentStationIndex] = useState(0); 
    const [isLiked, setIsLiked] = useState(false);
    const [streamServer, setStreamServer] = useState("s4"); // Default server
    
    useEffect(() => {
        if (isRadioPlaying) {
            console.log("Radio started playing...");
        } else {
            console.log("Radio stopped.");
        }
        return () => {
            console.log("RadioPlayer unmounted.");
        };
    }, [isRadioPlaying]);
        
    

    // Reference to the audio element
    

    const toggleLike = () => {
        setIsLiked((prev) => !prev);
    }

    // Toggle play/pause
    const toggleRadioPlay = () => {
        if (!audioRef.current) return;

        if (isRadioPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }

        setIsRadioPlaying((prev) => !prev);
    };

    // Go to the previous station
    const playPreviousStation = () => {
        setCurrentStationIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : radioStations.length - 1
        );
    };

    // Go to the next station
    const playNextStation = () => {
        setCurrentStationIndex((prevIndex) =>
            prevIndex < radioStations.length - 1 ? prevIndex + 1 : 0
        );
    };

    // Fetch Firestore data
    const fetchRadioStations = async () => {
        try {
            const database = getDatabase();
            const dbRef = rtdbRef(database);
            const snapshot = await get(child(dbRef, 'Stations'));
            if (snapshot.exists()) {
                const stationsObj = snapshot.val();
                // Convert the object to an array of station records
                const stations = Object.keys(stationsObj).map(key => ({
                    id: key,
                    ...stationsObj[key],
                }));
                setRadioStations(stations);
            } else {
                console.log("No radio stations available");
            }
        } catch (error) {
            console.error("Error fetching radio stations:", error);
        }
}

    // Fetch track info for the current station
    const fetchTrackInfo = async () => {
        try {
            // Ensure you use backticks for template literals
            const currentStation = radioStations[currentStationIndex];
            if (!currentStation || !currentStation.id) {
                console.warn("Current station or stationId is missing");
                return;
            }
    
            const response = await fetch(`https://public.radio.co/stations/${currentStation.id}/status`);
            
            // Check if the response is OK
            if (!response.ok) {
                console.error("Error fetching track info: HTTP status", response.status);
                return;
            }
    
            const data = await response.json();
            console.log("Track Data Retrieved:", data);
            
            // If the API returns a server property, update streamServer state
        if (data.server) {
            console.log("RadioPlayerNew.jsx: Found server in track data:", data.server);
            setStreamServer(data.server);
        } else {
            console.log("RadioPlayerNew.jsx: No server info in track data, using default.");
        }
            // Check if the data structure is as expected
            if (!data.current_track) {
                console.warn("Track data validation failed: 'current_track' is missing in the response:", data);
                return;
            }
            
            if (!data.current_track.title) {
                console.warn("Track data validation failed: 'title' is missing in 'current_track':", data);
                return;
            }
                      
            setTrackInfo({
                title: data.current_track.title,
                artist: data.current_track.artist,
            });
        } catch (error) {
            console.error("Error fetching track info:", error);
        }
    };
    
    useEffect(() => {
        // Fetch Firestore data
        fetchRadioStations();
    }, []);

    useEffect(() => {
        if (radioStations.length > 0) {
          const currentStation = radioStations[currentStationIndex];
          console.log("RadioPlayerNew: currentStation from context:", currentStation);
          if (audioRef.current) {
            if (currentStation && currentStation.id) {
                // Use the station-specific server value if available; otherwise, default to 's2'
                const streamServer = currentStation.server || "s4";
                //const streamUrl = `https://${streamServer}.radio.co/${currentStation.id}/listen`;
                const streamUrl = "https://listen.radio.co/seeae9c220/listen"
                console.log("RadioPlayerNew: Computed streamUrl:", streamUrl);
                audioRef.current.src = streamUrl;
                if (isRadioPlaying) {
                    audioRef.current.play().catch((error) => {
                        console.error("RadioPlayerNew: Error playing audio:", error);
                    });
                }
            } else {
                console.warn("RadioPlayerNew: No valid streamUrl found for current station:", currentStation);
            }
                }
          fetchTrackInfo(); // Initial fetch for the current station
      
          const intervalId = setInterval(() => {
            fetchTrackInfo();
          }, 30000);
      
          return () => clearInterval(intervalId);
        }
      }, [currentStationIndex, radioStations, isRadioPlaying, currentStationId]);

    return (
        <div className="radio-player-component-new">
            <div className='radio-player-container'>

                <div className='radio-details'>
                    <div className='radio-player-image'>
                        <img src={radioPlaceholder} alt="Radio Logo" />
                    </div>
                    <div className='radio-music-info'>
                        <div className='radio-channel'>
                            <div className='live-dot'></div>
                            <p className='radio-channel-name'>
                                {radioStations.length > 0 ? radioStations[currentStationIndex].name : "Loading..."}
                            </p>
                        </div>
                        <div className='radio-now-playing'>
                            <p>{trackInfo.title}</p>

                            <span onClick={toggleLike} style={{ cursor: 'not-allowed' }}>
                                <Icon.Heart 
                                    size={16}
                                    fill={isLiked ? '#1D599C' : 'none'} 
                                    color={isLiked ? '#0094D4' : '#fff'} />
                            </span>
                            
                            
                        </div>
                    </div>
                </div>
                
                <div className='radio-controls'>
                    <div className='skip-button' title="next station" onClick={playPreviousStation}>
                        <Icon.SkipBack size={14} color='var(--cyan-50)' />
                    </div>
                    {isRadioPlaying ? (
                        <div className='stop-button' onClick={toggleRadioPlay}>
                            <Icon.PauseCircle size={20} color='var(--cyan)'/>
                        </div>
                    ) : (
                        <div className='play-button' onClick={toggleRadioPlay}>
                            <Icon.PlayCircle size={20} color='var(--cyan)'/>
                        </div>
                    )}
                    <div className='skip-button' title="previous station" onClick={playNextStation}>
                        <Icon.SkipForward size={14} color='var(--cyan-50)' />
                    </div>
                </div>
                {/* Hidden audio element */}
                <audio ref={audioRef} />
            </div>
        </div>
    );
}

export default RadioPlayerNew;
