import { useState, useEffect } from "react";
import { auth, db, storage } from "../Firebase";
import { getAuth, fetchSignInMethodsForEmail, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownURL } from "firebase/storage"
import { useNavigate } from "react-router-dom"

import Avatar from "react-avatar-edit";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import submergeLogo from "../assets/submerge-logo.png";
import styles from "../styles/SignUp.css";
import userAvatar from "../assets/profile-placeholder.svg"

import handleImageUpload from "../utils/handleImageUpload";


function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputHandleValue, setInputHandleValue] = useState('');
  const [inputBioValue, setInputBioValue] = useState('');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [handleError, setHandleError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const navigate = useNavigate();
  const [errorEmail, setErrorEmail] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profilePic, setProfilePic] = useState(userAvatar);
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrorEmail(value !== "" && !emailRegex.test(value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleNameChange = (e) => {
    setInputNameValue(e.target.value);
    setIsSaved(false);
  };

  const handleHandleChange = (e) => {
    const newHandle = e.target.value;
    const isValidHandle = /^[a-zA-Z0-9_]+$/.test(newHandle);
    const isCorrectLength = newHandle.length >= 3 && newHandle.length <= 15;

    if (!isValidHandle) {
      setHandleError('Handles can only contain letters, numbers, and underscores.');
    } else if (!isCorrectLength) {
      setHandleError('Handles must be between 3-15 characters.');
    } else {
      setHandleError('');
    }

    setInputHandleValue(newHandle);
    setIsSaved(false);
  };

  const handleBioChange = (e) => {
    setInputBioValue(e.target.value);
    setIsSaved(false);
  };

  const handleLocationChange = (e) => {
    setInputLocationValue(e.target.value);
    setIsSaved(false);
  };

  const checkHandleAvailability = async (handle) => {
    if (!handle || handle.length < 3 || handle.length > 15) return false;

    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('handle', '==', handle));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking handle availability:', error);
      return false;
    }
  };

  const handleSavePhoto = async () => {
    if (!selectedFile) return;
    try {
      const downloadURL = await handleImageUpload(selectedFile, setHandleError);
      if (downloadURL && auth.currentUser) {
        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true });
        setProfilePic(downloadURL);
        setShowPhotoModal(false);
      }
    } catch (error) {
      console.error('Error uploading image and updating profile:', error);
    }
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;

        const isHandleAvailable = await checkHandleAvailability(inputHandleValue);
        if (!isHandleAvailable) {
          setHandleError('The handle is already taken. Please choose another one.');
          setLoading(false);
          return;
        }

        const userDocRef = doc(db, 'users', uid);
        const updatedData = {
          userName: inputNameValue,
          handle: inputHandleValue,
          bio: inputBioValue,
          location: inputLocationValue,
        };

        await setDoc(userDocRef, updatedData, { merge: true });
        setIsSaved(true);
        setShowProfileModal(false);
        setInputNameValue("");
        setInputBioValue("");
        setInputLocationValue('');
        setSelectedFile('');
        navigate("/");
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      alert('Error saving profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const checkDuplicateEmail = async (email) => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      console.error("Error checking duplicate email:", error);
      return false;
    }
  };

  const checkEmailAuthorization = async (email) => {
    try {
      const authorizedUsersRef = doc(db, "auth", "authorizedUsers");
      const docSnapshot = await getDoc(authorizedUsersRef);

      if (docSnapshot.exists()) {
        const authorizedUsers = docSnapshot.data();
        if (Array.isArray(authorizedUsers.beta)) {
          return authorizedUsers.beta.includes(email);
        }
      }
      return false;
    } catch (error) {
      console.error("Error checking email authorization:", error);
      return false;
    }
  };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    setAuthError(false);
    setDuplicateEmailError(false);

    if (!email.trim() || errorEmail) {
      setAuthError(true);
      return;
    }

    const isAuthorized = await checkEmailAuthorization(email);
    if (!isAuthorized) {
      setAuthError(true);
      return;
    }

    const isDuplicate = await checkDuplicateEmail(email);
    if (isDuplicate) {
      setDuplicateEmailError(true);
      return;
    }

    setShowSignUpForm(true);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    console.log("Submitting sign-up form...");

    if (password !== confirmPassword) {
      setAuthError(true);
      return;
    }

    setPasswordError('');
    if (password.length < 6) {
      setPasswordError('Passwords must be at least 6 characters.');
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log("User created:", userCredential.user);
      setShowProfileModal(true);
      console.log("Modal should now show:", showProfileModal);
    } catch (error) {
      console.error("Error signing up:", error);
      if (error.code === 'auth/email-already-in-use') {
        setDuplicateEmailError(true);
      } else {
        setAuthError(true);
      }
    }
  };

  const isButtonDisabled = () => {
    return (
      !email.trim() ||
      !password.trim() ||
      !confirmPassword.trim() ||
      !termsAccepted
    );
  };

  useEffect(() => {
    if (showProfileModal) {
      console.log("Profile modal is showing");
    }
  }, [showProfileModal]);

  return (
    <div className="sign-up">
      <div className="sign-up-container">
        <div className="left-panel">
          <div className="logo">
            <img className="submerge-logo" src={submergeLogo} alt="Logo" />
            <p className="tagline">UNDERGROUND MUSIC NETWORK</p>
          </div>
        </div>

        <div className="right-panel">
          <div className="sign-up-card">
            <div className="card-header">
              <h1 className="card-title">Sign Up</h1>
            </div>

            {showSignUpForm ? (
              <form className="sign-up-form" onSubmit={handleSignUp}>
                <div className="input-fields-section">
              <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input
                      className="form-input input-field"
                      type="email"
                      value={email}
                      placeholder="you@example.com"
                      readOnly
                    />
                    {duplicateEmailError && (
                      <p className="error-text">This email is already in use.</p>
                    )}
                  </div>

                  <div className="form-password form-field password-field">
                    <label className="label">PASSWORD</label>
                    <input
                      className="form-input"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <span className="toggle-password-icon" onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <Icon.EyeOff size={16} /> : <Icon.Eye size={16} />}
                    </span>
                  </div>

                  <div className="form-confirm-password form-field reenter-password-field">
                    <label className="label">CONFIRM PASSWORD</label>
                    <input
                      className="form-input"
                      type={showPassword ? "text" : "password"}
                      placeholder="Re-enter your password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <span className="toggle-reenter-password-icon" onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <Icon.EyeOff size={16} /> : <Icon.Eye size={16} />}
                    </span>
                  </div>

                  <div className="terms-privacy">
                    <input
                      type="checkbox"
                      id="terms-privacy"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      className="terms-privacy-checkbox"
                    />
                    <label htmlFor="terms-privacy" className="terms-privacy-label">
                      I accept the
                      <span>
                        <Link
                          to="https://submerge.live/terms-conditions"
                          target="_blank"
                          className="link-to-termsprivacy"
                        >
                          Terms & Conditions
                        </Link>
                      </span>
                      , and
                      <span>
                        <Link
                          to="https://submerge.live/privacy-policy"
                          target="_blank"
                          className="link-to-termsprivacy"
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="form-buttons">
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isButtonDisabled()}
                  >
                    <PrimaryButton buttonLabel="Sign Up" />
                  </button>
                </div>
              </form>
            ) : (
              <form className="email-check-form" onSubmit={handleEmailCheck}>
                <div className="input-fields-section">
                  <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input
                      className="form-input input-field"
                      type="email"
                      value={email}
                      placeholder="you@example.com"
                      onChange={handleEmailChange}
                      required
                    />
                    {authError && (
                      <p className="error-text">
                        This email is not authorized for sign-up.
                      </p>
                    )}
                    {duplicateEmailError && (
                      <p className="error-text">
                        This email is already in use.
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-buttons">
                  <PrimaryButton buttonLabel="Check Email" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      {showProfileModal && (
        <div className="modal" onClick={() => setShowProfileModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Complete Your Profile</h2>
            <div className="profile-photo-section">
              <img src={profilePic} className="header-avatar" alt="User Avatar" />
              <button type="button" className="change-photo-button" onClick={() => setShowPhotoModal(true)}>
                Add Photo
              </button>
            </div>
            <form className="edit-profile-form" onSubmit={handleSaveProfile}>
              <div className="input-fields-section">
                <div className="form-field">
                  <p className="label">NAME</p>
                  <input
                    className="input-field"
                    placeholder="Your name"
                    value={inputNameValue}
                    onChange={handleNameChange}
                    disabled={loading}
                  />
                </div>
                <div className="form-field">
                  <p className="label">HANDLE</p>
                  <input
                    className={`input-field ${handleError ? 'input-error' : ''}`}
                    placeholder="Your handle"
                    value={inputHandleValue}
                    onChange={handleHandleChange}
                    disabled={loading}
                  />
                  {handleError && (
                    <div className="input-error-message">
                      <p className="error-text">{handleError}</p>
                    </div>
                  )}
                </div>
                <div className="form-field">
                  <p className="label">BIO</p>
                  <input
                    className="input-field"
                    placeholder="Tell us about yourself"
                    value={inputBioValue}
                    onChange={handleBioChange}
                    disabled={loading}
                  />
                </div>
                <div className="form-field">
                  <p className="label">LOCATION</p>
                  <input
                    className="input-field"
                    placeholder="Your location"
                    value={inputLocationValue}
                    onChange={handleLocationChange}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="form-buttons">
                <PrimaryButton 
                  buttonLabel={isSaved ? "Saved" : "Save"} 
                  disabled={loading}
                />
              </div>
            </form>

            {showPhotoModal && (
              <div className="modal" onClick={() => setShowPhotoModal(false)}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-header">
                    <span className="title">Upload a Photo</span>
                    <button className="close-button" onClick={() => {
                      setShowPhotoModal(false);
                      setImg(null);
                      setSelectedFile(null);
                    }}>
                      &times;
                    </button>
                  </div>
                  <div className="avatar-editor-wrapper">
                    {!img ? (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setImg(URL.createObjectURL(file));
                          }
                        }}
                      />
                    ) : (
                      <Avatar
                        width={300}
                        height={300}
                        src={img}
                        onCrop={(preview) => {
                          fetch(preview)
                            .then(res => res.blob())
                            .then(blob => {
                              const file = new File([blob], 'cropped-image.png', { type: 'image/png' });
                              setSelectedFile(file);
                            });
                        }}
                        onClose={() => {
                          setSelectedFile(null);
                          setImg(null);
                        }}
                      />
                    )}
                  </div>
                  <div className="modal-buttons">
                    <button className="button" onClick={handleSavePhoto}>Save</button>
                    <button className="button" onClick={() => {
                      setShowPhotoModal(false);
                      setImg(null);
                      setSelectedFile(null);
                    }}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUp;