import styles from '../styles/MinimizedMenu.css'
import userAvatar from '../assets/avatar.jpg';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { auth, db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

const MinimizedMenu = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log("Authenticated user ID:", user.uid);
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          console.log("User data from Firestore:", userDoc.data());
          setUserData(userDoc.data());
        } else {
          console.log('User document does not exist');
        }
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return <div></div>;
  }

  return (
    <div className="minimized-menu component">

      <Link className="link-to-profile"
            to={{
              pathname: `/${userData.handle}`, // Use handle for the URL
              //state: { userId: auth.currentUser?.uid} // Pass UID in the state for data fetching
            }} 
            style={{ textDecorationLine: 'none' }}
        >

        <div className="user-avatar">
          <img src={userData.profilePic || userAvatar} alt="User Avatar" />
        </div>

        <p>You</p>

      </Link>

    </div>
  );
}

export default MinimizedMenu;
