import styles from '../styles/LandingPage.css'
import bg from '../assets/lp-bg.png'
import logo from '../assets/submerge-logo_original.png'
import downloadAppStore from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'
import mockup from '../assets/device-mockup.png'
import bmiLogo from '../assets/bmi-logo.png'
import ascapLogo from '../assets/ascap-logo.png'
import sesacLogo from '../assets/sesac-logo.jpeg'
import seLogo from '../assets/soundExchange-logo.png'
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className='lpbg'>
                <img src={bg} />
            </div>

            <div className='landing-page-inner'>
                <div className='lp-heading'>
                    
                    <div className='submerge-logo'>
                        <img src={logo} />
                    </div>
                    <p className='tagline'>
                        UNDERGROUND MUSIC NETWORK 
                    </p>
                    <p className='body'>
                        Submerge gives you exclusive alternative & underground music content without corporate label influence. Hosted and curated by world-positive DJs and tastemakers worldwide. 
                    </p>
                    <div className='download-button'>
                        <Link to="https://apps.apple.com/ph/app/submerge/id6463235608">
                            <img src={downloadAppStore} />
                        </Link>
                    </div>

                    <hr />

                    <div className='partners-logo'>
                        <div className='partners-logo-img'>
                            <img src={bmiLogo} />
                        </div>
                        <div className='partners-logo-img'>
                            <img src={seLogo} />
                        </div>
                        <div className='partners-logo-img'>
                            <img src={ascapLogo} />
                        </div>
                        <div className='partners-logo-img'>
                            <img src={sesacLogo} />
                        </div>
                    </div>
                </div>
                <div className='lp-img'>
                    <div className='img-mockup'>
                        <img src={mockup} />
                    </div>
                </div>
            </div>

            <div className='lp-footer'>
                <div className='footer-links'>
                    <a href='/terms-and-conditions' target="_blank" className='footer-link'>
                        Terms and Conditions
                    </a>
                    <a href='/privacy-policy' target="_blank" className='footer-link'>
                        Privacy Policy
                    </a>
                    <a href='/contact-us' target="_blank" className='footer-link'>
                        Contact Us
                    </a>
                </div>
                <p className='copyright'>Submerge LLC © 2025</p>

            </div>
        </div>
    );
}

export default LandingPage;
