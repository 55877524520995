import React, { useEffect, useState } from 'react';
import { auth, db } from '../Firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, serverTimestamp, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import styles from '../styles/EventAdmin.css';
import PrimaryButton from '../components/PrimaryButton';
import Header from '../components/Header';
import EventAttendance from '../components/EventAttendance';
import EventCommunication from '../components/EventCommunication'; 
import EventBudget from '../components/EventBudget';

function EventAdmin() {
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState("attendee-list"); 
  const [events, setEvents] = useState([]); 
  const [selectedEvent, setSelectedEvent] = useState(""); 
  const [attendees, setAttendees] = useState([]); 
  const [declines, setDeclines] = useState([]);

  const [emailInput, setEmailInput] = useState('');
  const [fileContent, setFileContent] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid);
        fetchUserEvents(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserEvents = async (uid) => {
    try {
      const eventsRef = collection(db, "events");
      const q = query(eventsRef, where("promoters", "array-contains", uid));
      const querySnapshot = await getDocs(q);

      const userEvents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title, 
      }));

      setEvents(userEvents);

      if (userEvents.length > 0 && !selectedEvent) {
        setSelectedEvent(userEvents[0].id);
        fetchAttendees(userEvents[0].id);
      }
    } catch (error) {
      console.error("Error fetching user events:", error);
    }
  };

  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem);
  };

  const handleEventChange = (e) => {
    const eventId = e.target.value;
    setSelectedEvent(eventId);
    fetchAttendees(eventId);
    fetchDeclines(eventId); 
  };

  const fetchAttendees = async (eventId) => {
    if (!eventId) return;
    try {
      console.log(`Fetching attendees for event: ${eventId}`);
  
      const attendeesRef = collection(db, `events/${eventId}/attendees`);
      const querySnapshot = await getDocs(attendeesRef);
  
      const attendeesList = await Promise.all(
        querySnapshot.docs.map(async (docSnap) => {
          const attendee = docSnap.data();
          const userId = attendee.userId;
  
          console.log(`Fetching user data for userId: ${userId}`);
  
          // Fetch user data from Users collection
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);
  
          let userData = { name: "Unknown", handle: "N/A" };
  
          if (userSnap.exists()) {
            userData = userSnap.data();
            console.log(`User found: ${JSON.stringify(userData)}`);
          } else {
            console.warn(`No user found in Users collection for userId: ${userId}`);
          }
  
          return {
            id: docSnap.id,
            name: userData.userName || "Unknown",
            handle: userData.handle || "N/A",
            timestamp: attendee.timestamp?.seconds
              ? new Date(attendee.timestamp.seconds * 1000).toLocaleString()
              : "N/A",
          };
        })
      );
  
      console.log("Final attendees list:", attendeesList);
      setAttendees(attendeesList);
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  };

  const fetchDeclines = async (eventId) => {
    if (!eventId) return;
    try {
      console.log(`Fetching declines for event: ${eventId}`);
  
      const declinesRef = collection(db, `events/${eventId}/declines`);
      const querySnapshot = await getDocs(declinesRef);
  
      const declinesList = await Promise.all(
        querySnapshot.docs.map(async (docSnap) => {
          const decline = docSnap.data();
          const userId = decline.userId;
  
          console.log(`Fetching user data for userId: ${userId}`);
  
          // Fetch user data from Users collection
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);
  
          let userData = { name: "Unknown", handle: "N/A" };
  
          if (userSnap.exists()) {
            userData = userSnap.data();
            console.log(`User found: ${JSON.stringify(userData)}`);
          } else {
            console.warn(`No user found in Users collection for userId: ${userId}`);
          }
  
          return {
            id: docSnap.id,
            name: userData.userName || "Unknown",
            handle: userData.handle || "N/A",
            timestamp: decline.timestamp?.seconds
              ? new Date(decline.timestamp.seconds * 1000).toLocaleString()
              : "N/A",
          };
        })
      );
  
      console.log("Final declines list:", declinesList);
      setDeclines(declinesList); // ✅ Updating the state properly
  
    } catch (error) {
      console.error("Error fetching declines:", error);
    }
  };

  const handleEmailChange = (e) => setEmailInput(e.target.value);

  const handleEmailSubmit = async () => {
    if (!emailInput || !selectedEvent) {
      alert("Please enter a valid email and select an event.");
      return;
    }

    try {
      const attendeeRef = doc(db, `events/${selectedEvent}/attendees`, emailInput);
      await setDoc(attendeeRef, {
        email: emailInput,
        timestamp: serverTimestamp(),
      });

      alert("Attendee added successfully.");
      setEmailInput("");
      await fetchAttendees(selectedEvent);
    } catch (error) {
      console.error("Error adding attendee:", error);
      alert("Error adding attendee.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "text/plain" || file.type === "text/csv")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const lines = event.target.result
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line);
        setFileContent(lines);
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a .txt or .csv file.");
    }
  };

  const handleFileSubmit = async () => {
    if (fileContent.length === 0 || !selectedEvent) {
      alert("No valid content to upload or no event selected.");
      return;
    }

    try {
      for (const email of fileContent) {
        const attendeeRef = doc(db, `events/${selectedEvent}/attendees`, email);
        await setDoc(attendeeRef, {
          email,
          timestamp: serverTimestamp(),
        });
      }

      alert("File uploaded successfully.");
      setFileContent([]);
      await fetchAttendees(selectedEvent);
    } catch (error) {
      console.error("Error uploading attendees:", error);
      alert("Error uploading attendees.");
    }
  };
  // Get the title of the selected event for display
  const selectedEventTitle = events.find((event) => event.id === selectedEvent)?.title || "Select an Event";

  return (
    <div className="admin">
      <Header avatar="true" showLogo="true" hasSearchbar />

      <div className="admin-sidemenu">
        <div className="sidemenu-container">
          <h3>Events Admin</h3>
          <hr />

          {/* Dropdown List for Selecting Event */}
          <div className="event-selector">
            <label htmlFor="event-dropdown">Select Event:</label>
            <select id="event-dropdown" value={selectedEvent} onChange={handleEventChange}>
              <option value="" disabled>{selectedEventTitle}</option> 
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.title}
                </option>
              ))}
            </select>
          </div>

          <div className="menu-list">
            <ul className="list">
              <li 
                className="menu-item" 
                style={{ backgroundColor: selectedMenu === "attendee-list" ? 'var(--black-10)' : 'transparent' }} 
                onClick={() => handleMenuClick("attendee-list")}
              >
                Attendance
              </li>
              <li 
                className="menu-item" 
                style={{ backgroundColor: selectedMenu === "communication" ? 'var(--black-10)' : 'transparent' }} 
                onClick={() => handleMenuClick("communication")}
              >
                Communication
              </li>
              <li 
                className="menu-item" 
                style={{ backgroundColor: selectedMenu === "budget" ? 'var(--black-10)' : 'transparent' }} 
                onClick={() => handleMenuClick("budget")}
              >
                Budget
              </li>
              <li 
                className="menu-item" 
                style={{ backgroundColor: selectedMenu === "qr-code-generator" ? 'var(--black-10)' : 'transparent' }} 
                onClick={() => handleMenuClick("qr-code-generator")}
              >
                QR Code Generator
              </li>
              <li 
                className="menu-item" 
                style={{ backgroundColor: selectedMenu === "ban-list" ? 'var(--black-10)' : 'transparent' }} 
                onClick={() => handleMenuClick("ban-list")}
              >
                Not Welcome
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div className="admin-container">
        <h3 className="admin-title">
          {selectedEvent ? `${selectedEventTitle}` : "Admin Panel"}
        </h3>

        {/* Conditionally Render Attendees Section */}
        {selectedMenu === "attendee-list" && (
          <EventAttendance 
            attendees={attendees}
            declines={declines}
            fetchDeclines={fetchDeclines}  // Pass function to be called inside EventAdminAttendees
            selectedEvent={selectedEvent}
            emailInput={emailInput}
            handleEmailChange={handleEmailChange}
            handleEmailSubmit={handleEmailSubmit}
            fileContent={fileContent}
            handleFileChange={handleFileChange}
            handleFileSubmit={handleFileSubmit}
          />
        )}
        {/* Conditionally Render Communication Section */}
        {selectedMenu === "communication" && (
          <EventCommunication selectedEvent={selectedEvent} />
        )}
        {selectedMenu === 'budget' && (
          console.log("Rendering EventBudget with selectedEvent:", selectedEvent),
          <EventBudget selectedEvent={selectedEvent} />
        )}
      </div>
    </div>
  );
}

export default EventAdmin;