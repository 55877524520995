
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAEery3f0cHRNV-QEpqQmF27M-3-PZ8llM",
  authDomain: "submerge-app-a0bd4.firebaseapp.com",
  databaseURL: "https://submerge-app-a0bd4-default-rtdb.firebaseio.com",
  projectId: "submerge-app-a0bd4",
  storageBucket: "submerge-app-a0bd4.appspot.com",
  messagingSenderId: "841066605614",
  appId: "1:841066605614:web:f729f0da2202f9871943bf",
  measurementId: "G-9J2CPYPWN1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, onAuthStateChanged };
