import { useEffect, useState } from "react";
import { auth, db } from '../Firebase';
import { collection, doc, getDoc, getDocs, orderBy, query  } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from '../styles/MainScreen.css';

import NewsfeedCard from '../components/NewsfeedCard';
import CreatePost from '../components/CreatePost';
import ProfilePanel from '../components/ProfilePanel';
import TopBubbles from '../components/TopBubbles';
import BubblePill from '../components/BubblePill';
import TertiaryButton from '../components/TertiaryButton';
import DiscoverPeoplePanel from '../components/DiscoverPeoplePanel';
import DiscoverPeopleSlider from '../components/DiscoverPeopleSlider';
import Header from '../components/Header';
import RadioPlayer from "../components/RadioPlayer";
import PagesMenu from "../components/PagesMenu";
import MinimizedMenu from "../components/MinimizedMenu";
import PostFeed from "../components/PostFeed";
import SinglePostPopup from "../components/SinglePostPopup";

function MainScreen() {
  const [initialSelectedPostId, setInitialSelectedPostId] = useState(null);
  const [isTrendingActive, setIsTrendingActive] = useState(true);
  const [isFollowingActive, setIsFollowingActive] = useState(false);
  const [trendingTopic, setTrendingTopic] = useState('');
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0); // For refreshing the post feed
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const selectedId = location.state?.selectedPostId;
    if (selectedId) {
      setInitialSelectedPostId(selectedId);
      // Clear the location state so this only runs once
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  useEffect(() => {
    const selectedId = location.state?.selectedPostId;
    if (selectedId) {
      const fetchPost = async () => {
        try {
          const docRef = doc(db, 'posts', selectedId);
          const postSnap = await getDoc(docRef);
          if (postSnap.exists()) {
            setSelectedPost({ id: postSnap.id, ...postSnap.data() });
          } else {
            console.warn("Post not found for selectedPostId");
          }
        } catch (err) {
          console.error('Error fetching post:', err);
        }
      };
      fetchPost();

      // Clear the location state so this only runs once
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const toggleTrending = () => {
    setIsTrendingActive(true);
    setIsFollowingActive(false);
  };

  const toggleFollowing = () => {
    setIsTrendingActive(false);
    setIsFollowingActive(true);
  };

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData({ uid: user.uid, ...userDoc.data() });
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const addPost = (newPost) => {
    setPosts((prevPosts) => [newPost, ...prevPosts]);
    setRefreshKey((prev) => prev + 1);
  }

  const handleClear = () => {
    try {
      console.log('Clear button clicked');
      setTrendingTopic('');
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };


  ////////////////////////// RETURN STATEMENT //////////////////////////
  return (
    <div className="mainscreen">
      <Header 
        avatar="true"
        showLogo="true"
        hasSearchbar
      />

      <main className="mainscreen-content">
        <div className="profile-pane">

          <div className="min-menu">
            <MinimizedMenu />
          </div>

          <hr />

          <div className="profilepanel">
            <ProfilePanel userData={userData} />
          </div>

          <div className="pages-entry-component">
            <PagesMenu />
          </div>
        </div>

        

        <div className="feed">
          <div className="create-post-section">
            <CreatePost userData={userData} addPost={addPost} />
          </div>

          {trendingTopic !== '' && (
            <div className="active-bubble">
              <div className="left-texts">
                <p className="text">Showing posts of</p>
                <BubblePill bubbleText={trendingTopic} />
                <p className="text">bubble.</p>
              </div>
              <div className="right-texts">
                <TertiaryButton buttonLabel="Clear" onClick={() => setTrendingTopic('')} />
              </div>
            </div>
          )}

          <div className="newsfeed-tabs">
            <div className="tab-item" onClick={toggleTrending}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isTrendingActive ? 'var(--cyan)' : 'var(--text-grey)' }}>
                    Trending For You
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isTrendingActive ? 'var(--cyan)' : 'transparent' }}>
              </div>
            </div>

            <div className="tab-item" onClick={toggleFollowing}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isFollowingActive ? 'var(--cyan)' : 'var(--text-grey)' }}>
                    Following
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isFollowingActive ? 'var(--cyan)' : 'transparent' }}>
              </div>
            </div>
          </div>          

          <div className="newsfeed-section">
            {console.log("")}
            {userData && (
              <PostFeed
                userUID={userData.uid} // Pass the authenticated user's UID to NewsfeedCard
                trendingTopic={trendingTopic}
                initialSelectedPostId={initialSelectedPostId}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
                {...(isTrendingActive ? { isTrendingActive } : {})}
                {...(isFollowingActive ? { isFollowingActive } : {})}
              />
            )}
          </div>
          
          
          <DiscoverPeopleSlider />

          <div>
            <p style={{padding: '8px 0', visibility: 'hidden', textAlign: 'center'}}>I'll be just here as spacer. Can't see me, but I'm here.</p>
          </div>
        </div>

        <div className="web-right-panel">
          <TopBubbles setTrendingTopic={setTrendingTopic} />
          {userData && <DiscoverPeoplePanel userData={userData} onFollow={fetchUserData} />}
        </div>

        
      </main>
    </div>
  );
}

export default MainScreen;