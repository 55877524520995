import styles from '../styles/CreateMix.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { auth, db } from '../Firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const CreateMix = ( {isVisible, onClose, children, onMixUploaded}) => {

	const [name, setName] = useState('');
	const [url, setUrl] = useState('');
	const [description, setDescription] = useState('');
	const [uploading, setUploading] = useState(false);

	let mixLinkError = "";
	if (url) {
	  if (url.startsWith("https://soundcloud.com/") || url.startsWith("https://www.mixcloud.com")) {
	    mixLinkError = "This is a share link, please provide the Embed link";
	  } else if (!url.includes("soundcloud") && !url.includes("mixcloud")) {
	    mixLinkError = "Submerge currently accepts Mixcloud and SoundCloud embedded players";
	  }
	}

	if (!isVisible) return null;

	const parseMixUrl = (url) => {
		if (url.includes('soundcloud.com')) {
			const regex = /api\.soundcloud\.com\/tracks\/(\d+)(?=&|$)/;
			const match = url.match(regex);
			if (match) {
				console.log("Detected SoundCloud URL");
				const trackId = match[1];
				return { platform: 'soundcloud', embedUrl: `https://api.soundcloud.com/tracks/${trackId}` };
			}
		} else if (url.includes('mixcloud.com')) {
			const regex = /feed=([^"&]+)/;
			const match = url.match(regex);
			if (match) {
				console.log("Detected Mixcloud URL");
				return { platform: 'mixcloud', embedUrl: decodeURIComponent(match[1]) };
			}
		}
		return { platform: 'unknown', embedUrl: '' };
	};

	const handlePostMix = async (e) => {
		e.preventDefault();
		console.log("handlePostMix called");
		console.log("Raw URL:", url);
	
		const { platform, embedUrl } = parseMixUrl(url);
		console.log("Parsed platform:", platform);
		console.log("Parsed embedUrl:", embedUrl);
		if (!platform || !embedUrl || !auth.currentUser) return;
	
		setUploading(true);
		try {
			const mixData = {
				userId: auth.currentUser.uid,
				name,
				description,
				platform,
				embedUrl,
				createdAt: serverTimestamp(),
			};
	
			console.log("Posting mix data:", mixData);
			await addDoc(collection(db, 'mixes'), mixData);
			if (onMixUploaded) onMixUploaded();
			onClose(); // Close modal after posting
			setName('');
			setUrl('');
		} catch (error) {
			console.error('Error posting mix:', error);
		} finally {
			setUploading(false);
		}
	};

	return(
		<div className="create-mix-overlay">
			<div className="create-mix-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="create-mix-header">
					<p className="overlay-title">Embed a Mix</p>
					<div className="close-overlay-button" onClick={onClose}>
						<Icon.X color='#fff' size={18} />
					</div>
				</div>

				<hr />

				<form className="create-mix-form">
					<div className="input-fields-section">

						<div className="form-mix-name form-field">
				            <label htmlFor="mix-name" className="label">Name</label>
				            <input
				            	id="mix-name"
								placeholder="Mix Name"
								required
								className="input-field"
								value={name}
								onChange={(e) => setName(e.target.value)}
				            />
				        </div>

                        <div className="form-mix-name form-field">
				            <label htmlFor="mix-link" className="label">URL</label>
				            <input
				            	id="mix-link"
								placeholder="Paste URL here"
								required
								className="input-field"
								value={url}
								onChange={(e) => setUrl(e.target.value)}
				            />
				        </div>
                        { mixLinkError && <p style={{ color: "red", marginTop: "0.5em" }}>{mixLinkError}</p> }

				        <div className="form-buttons">
							
						<PrimaryButton
							buttonLabel={uploading ? "Uploading....." : "Post"}
							className="button"
							onClick={(e) => {
								console.log("Post button clicked, calling handlePostMix...");
								handlePostMix(e);
							}}
						/>
						</div>

					</div>
				</form>

			</div>
		</div>
	)
}

export default CreateMix;