import styles from '../styles/PrimaryButton.css';

const PrimaryButton = ({ buttonLabel, type, disabled, onClick, ...props }) => {
  return (
    <div className="prim-button">
      <button
        className='button'
        type={type}
        disabled={disabled}
        onClick={onClick} 
        {...props} // Add onClick prop here
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default PrimaryButton;