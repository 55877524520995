import { useState } from "react";
import { auth } from "../../Firebase";
import profilePlaceholder from "../../assets/profile-placeholder.svg";
import SecondaryButton from "../SecondaryButton";

const CreateComment = ({ postId, fetchCommentCount, addComment }) => {
  const [commentContent, setCommentContent] = useState("");
  const maxLength = 350;

  const handleMaxLength = (e) => {

    let newValue = e.target.value;

    // Enforce maxLength by slicing the string if necessary
    if (newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setCommentContent(newValue);

    // if (e.target.value.length <= maxLength) {
    //   setCommentContent(e.target.value);
    // }
  };

  const handleAddComment = async () => {
    if (!commentContent.trim()) return;
    
    console.log("Post ID:", postId);
    console.log("Comment Content:", commentContent);
    console.log("User ID:", auth.currentUser?.uid);

    try {
      await addComment(postId, commentContent, auth.currentUser?.uid);
      setCommentContent(""); // Clear the input field after success
      fetchCommentCount(postId); // Refresh comment count
      console.log("Comment added successfully!");
    } catch (err) {
      console.error("Error while adding comment:", err);
    }
  };

  return (
    <div className="create-comment">
      <div className="comment-user-image">
        <img src={profilePlaceholder} alt="User Profile" />
      </div>
      <textarea
        className="post-compose-box"
        placeholder="Write a comment..."
        value={commentContent}
        onChange={(e) => setCommentContent(e.target.value)}
        onKeyDown={handleMaxLength} // Handles Enter key submission
        maxLength={maxLength} // Native enforcement
      />
      <p 
        style={{fontSize: 'var(--text-xs)', width: '80px',textAlign: 'right', color: 'var(--text-grey)', margin: 'auto 0', padding: '0'}}>
          {commentContent.length + " / " + maxLength}
      </p>
      <div className="post-comment-button">
        <SecondaryButton buttonLabel="Submit" onClick={handleAddComment} />
      </div>
    </div>
  );
};

export default CreateComment;