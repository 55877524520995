import styles from '../styles/Error404.css'
import bg from '../assets/bg404.png'
import submergeLogo from '../assets/submerge-logo.png';
import errorImg from '../assets/error-404.png'

const Error404 = () => {
  return (
    <div className="error-404-page">
      <div className='bg-img'>
          <img src={bg} />
      </div>

      <div className="inner-content">
        <div className="error-404-img">
          <img src={errorImg} />
        </div>

        <div className="texts">
          <p className="heading">Oops! We can’t seem to find that page.</p>
          <p className="body">It might have been moved, deleted, or the URL could be incorrect.</p>
          <p className="body nexpar">🔍 Try these options:</p>
          <p className="body">
            Check the URL and try again. | Go back to our <a href="/">homepage</a>. | Need help? <a href="/contact-us">Contact us</a> for assistance.
          </p>
        </div>

        <div className="logo">
          <img
            className="submerge-logo"
            src={submergeLogo}
            alt="Submerge Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default Error404;