import styles from '../styles/Messages.css'
import { useEffect, useState } from 'react';
import { auth, db, storage } from '../Firebase';
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Header from '../components/Header';
import CreateMessageModal from '../components/CreateMessageModal';
import { saveMentions } from '../utils/saveMentions'
import parseMentions from '../utils/parseMentions'
import * as Icon from 'react-feather';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import groupImgPlaceholder from '../assets/group-avatar.svg';

const Messages = () => {
  const [isCreateMessageVisible, setIsCreateMessageVisible] = useState(false);
  const [isChatOptionsVisible, setIsChatOptionsVisible] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherUserData, setOtherUserData] = useState({});  
  const [currentUserProfilePic, setCurrentUserProfilePic] = useState(profilePlaceholder);
  const [unreadStatus, setUnreadStatus] = useState({});
  const [searchText, setSearchText] = useState('');
  const currentUser = auth.currentUser;

  // Define toggle functions
  const toggleCreateMessagePopup = () => setIsCreateMessageVisible(!isCreateMessageVisible);
  const toggleChatOptionsModal = () => setIsChatOptionsVisible(!isChatOptionsVisible);

  // Fetch current user's profile picture
  useEffect(() => {
    const fetchCurrentUserProfilePic = async () => {
      if (!currentUser) {
        console.warn("No current user is logged in.");
        return;
      }
  
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          let profilePicUrl = userDoc.data().profilePic || profilePlaceholder;
  
          // Check if the profilePicUrl is from Firebase Storage
          if (profilePicUrl.startsWith('gs://')) {
            const storageRef = ref(storage, profilePicUrl);
            profilePicUrl = await getDownloadURL(storageRef);
          }
  
          setCurrentUserProfilePic(profilePicUrl);
        } else {
          console.warn("User document does not exist for UID:", currentUser.uid);
        }
      } catch (error) {
        console.error("Error fetching current user profile picture:", error);
      }
    };
  
    fetchCurrentUserProfilePic();
  }, [currentUser]);
  
  //Fetch Chats
  const fetchChats = async () => {
    try {
      const chatsRef = collection(db, 'chats');
      const q = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
      const querySnapshot = await getDocs(q);
  
      const fetchedChats = querySnapshot.docs.map((doc) => ({
        chatId: doc.id,
        ...doc.data(),
      }));
  
      const unreadStatusMap = {};
      const otherUserDataMap = {};
  
      // Helper function to fetch unread messages for a chat
      const fetchUnreadMessages = async (chat) => {
        const messagesRef = collection(db, 'messages');
        const unreadQuery = query(messagesRef, where('chatId', '==', chat.chatId));
        const unreadSnapshot = await getDocs(unreadQuery);
      
        // Check if any messages do not have the current user's UID in isReadBy
        const hasUnreadMessages = unreadSnapshot.docs.some(
          (doc) => !doc.data().isReadBy?.includes(currentUser.uid)
        );
      
        return hasUnreadMessages;
      };
  
      // Helper function to fetch other user data
      const fetchOtherUserData = async (userId) => {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const { profilePic, handle } = userDoc.data();
          let profilePicUrl = profilePic || profilePlaceholder;
          if (profilePicUrl.startsWith('gs://')) {
            const storageRef = ref(storage, profilePicUrl);
            profilePicUrl = await getDownloadURL(storageRef);
          }
          return { profilePic: profilePicUrl, handle };
        }
        return { profilePic: profilePlaceholder, handle: 'Unknown User' };
      };
  
      // Fetch unread status and other user data in parallel for all chats
      const fetchedChatsWithDetails = await Promise.all(
        fetchedChats.map(async (chat) => {
          const hasUnread = await fetchUnreadMessages(chat);
          unreadStatusMap[chat.chatId] = hasUnread;
  
          const otherUserId = chat.participants.find((id) => id !== currentUser.uid);
          if (otherUserId && !otherUserDataMap[otherUserId]) {
            const otherUser = await fetchOtherUserData(otherUserId);
            otherUserDataMap[otherUserId] = otherUser;
          }
  
          return chat;
        })
      );
        
      setChats(fetchedChatsWithDetails);
      setUnreadStatus(unreadStatusMap); // Store unread status in state
      setOtherUserData(otherUserDataMap); // Store other user data in state
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  useEffect(() => {
    fetchChats();
  }, [currentUser]);
   
  // Fetch messages for the selected chat
  const fetchMessages = async () => {
    if (!selectedChat?.chatId) return;

    try {
      const messagesRef = collection(db, 'messages');
      const q = query(messagesRef, where('chatId', '==', selectedChat.chatId), orderBy('timestamp'));
      const querySnapshot = await getDocs(q);

      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        messageId: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedChat]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedChat) {
      console.warn('Message content is empty or no chat is selected.');
      return; // Prevent empty message or no selected chat
    }
  
    try {
      console.log('Sending message:', newMessage.trim());
      console.log('Selected chat ID:', selectedChat.chatId);
  
      // Add the new message to the messages collection and get its reference
      const messageRef = await addDoc(collection(db, 'messages'), {
        chatId: selectedChat.chatId,
        senderId: currentUser.uid,
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
        isReadBy: [currentUser.uid],
        messageType: 'text',
      });
  
      console.log('Message created successfully with ID:', messageRef.id);
  
      // Update the lastUpdated and lastMessageId fields in the chats collection
      await updateDoc(doc(db, 'chats', selectedChat.chatId), {
        lastUpdated: serverTimestamp(),
        lastMessageId: messageRef.id,
      });
  
      console.log('Chat metadata updated successfully for chat ID:', selectedChat.chatId);
  
      // Extract mentions from the message content
      const mentions = parseMentions(newMessage.trim());
      console.log('Extracted mentions:', mentions);
  
      // Save mentions if any are found
      if (mentions.length > 0) {
        console.log('Saving mentions for message ID:', messageRef.id);
        await saveMentions(newMessage.trim(), currentUser.uid, messageRef.id);
        console.log('Mentions saved successfully.');
      } else {
        console.log('No mentions found in the message content.');
      }
  
      setNewMessage(''); // Clear the input after sending
      console.log('Message input cleared.');
  
      // Re-fetch messages to include the newly sent message
      console.log('Re-fetching messages for chat ID:', selectedChat.chatId);
      await fetchMessages();
      console.log('Messages re-fetched successfully.');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
 
  const markMessagesAsRead = async (chatId) => {
    try {
      const messagesRef = collection(db, 'messages');
      const unreadQuery = query(
        messagesRef,
        where('chatId', '==', chatId),
        where('isReadBy', 'not-in', [currentUser.uid])
      );
      const unreadSnapshot = await getDocs(unreadQuery);
  
      // Update each unread message to include the current user's UID in isReadBy
      const updatePromises = unreadSnapshot.docs.map((doc) =>
        updateDoc(doc.ref, {
          isReadBy: arrayUnion(currentUser.uid), // Add currentUser.uid to isReadBy array
        })
      );
  
      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const handleChatClick = async (chat) => {
    console.log("Selected chat:", chat);
    setSelectedChat(chat);
    
    setSearchText("");
    // Mark messages as read
    await markMessagesAsRead(chat.chatId);
  
    // Refresh chats to update unread status
    await fetchChats();
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
  if (event.key === 'Enter') {
    // Normalize the search text
    const searchQuery = searchText.toLowerCase();

    // Find a chat where the user's handle or username matches the search query
    const matchingChat = chats.find((chat) => {
      const otherUserId = chat.participants.find((id) => id !== currentUser.uid);
      const userData = otherUserData[otherUserId] || {};
      return (
        userData.handle?.toLowerCase().includes(searchQuery) ||
        userData.userName?.toLowerCase().includes(searchQuery)
      );
    });

    if (matchingChat) {
      handleChatClick(matchingChat);
      console.log("Selected Chat", matchingChat)
    } else {
      console.warn("No matching chat found");
    }
  }
};
  
  /**************  Update Messages  **************/
  useEffect(() => {
    const updateMessages = async () => {
      try {
        const updatedChats = [...chats]; // Copy the current chats state
  
        for (const chat of chats) {
          const chatDoc = await getDoc(doc(db, 'chats', chat.chatId));
          const newLastUpdated = chatDoc.data().lastUpdated?.toMillis();
  
          if (newLastUpdated && newLastUpdated !== chat.lastUpdated?.toMillis()) {
            console.log(`Chat ${chat.chatId} has been updated, re-fetching messages...`);
  
            // Re-fetch messages for this chat
            const messagesRef = collection(db, 'messages');
            const q = query(messagesRef, where('chatId', '==', chat.chatId), orderBy('timestamp'));
            const querySnapshot = await getDocs(q);
  
            const fetchedMessages = querySnapshot.docs.map((doc) => ({
              messageId: doc.id,
              ...doc.data(),
            }));
  
            // Update messages for the currently selected chat
            if (selectedChat?.chatId === chat.chatId) {
              setMessages(fetchedMessages);
            }
  
            // Update the chat's lastUpdated field locally
            const updatedChatIndex = updatedChats.findIndex((c) => c.chatId === chat.chatId);
            if (updatedChatIndex !== -1) {
              updatedChats[updatedChatIndex] = {
                ...chat,
                lastUpdated: chatDoc.data().lastUpdated,
              };
            }
          }
        }
  
        // Update the chats state with any changes
        setChats(updatedChats);
      } catch (error) {
        console.error('Error updating messages:', error);
      }
    };
  
    // Set up interval to run updateMessages every 30 seconds
    const intervalId = setInterval(updateMessages, 30000);
  
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [chats, selectedChat]); 
  
  
  // ******************* HTML ****************************** //
  return (
    <div className="messages component">
    	<div className="messages-container">
      	<Header goBack pageTitle="Messages" avatar
          hasUnreadMessages={Object.values(unreadStatus).some((status) => status)} // Calculate hasUnreadMessages
        />

        <div className="messages-main">

          <div className="messages-list">
            <div className="messages-list-header">
            <input
              className="search-bar input-field"
              type="text"
              placeholder="Search"
              value={searchText} // Bind the input value to the searchText state
              onChange={handleSearchChange} // Trigger handleSearchChange on text input
              onKeyDown={handleSearchKeyDown} // Trigger handleSearchKeyDown when a key is pressed
            />
              <div className="write-icon" onClick={toggleCreateMessagePopup}>
                <Icon.Edit size={20} />
              </div>
            </div>

            {/* CHAT LIST */}
            {chats.map((chat) => {
              const otherUserId = chat.participants.find((id) => id !== currentUser.uid);
              const { profilePic, handle } = otherUserData[otherUserId] || { profilePic: profilePlaceholder, handle: 'Unknown User' };

              return(
                  <div 
                    key={chat.chatId} 
                    className="message-item" 
                    onClick={() => handleChatClick(chat)}
                    style={{
                      backgroundColor: selectedChat?.chatId === chat.chatId ? 'var(--ocean-blue-20)' : 'transparent', // Apply highlight
                    }} // conversation highlight
                  >
                  <div className="image-and-content">
                    <div className="user-image">
                      <img src={chat.isGroupChat === true ? groupImgPlaceholder : profilePic} alt="Profile" />
                    </div>
                    <div className="message-content">
                      <p className="recipient-name">
                      {chat.isGroupChat
                        ? (chat.groupName || chat.participants
                            .filter((id) => id !== currentUser.uid)
                            .map((userId) => otherUserData[userId]?.handle || 'Unknown User')
                            .join(', '))
                        : handle}
                      </p>
                      {/* <p className="message-preview">message preview</p> */}
                    </div>
                  </div>
                  <div className="time-and-indicator">
                    <p className="time-ago">now</p>
                    {unreadStatus[chat.chatId] && (
                      <div style={{ width: '8px', height: '8px', backgroundColor: 'var(--acc-blue)', borderRadius: '50%' }}></div>
                    )}
                    {console.log("Unread messages", unreadStatus[chat.chatId])}
                  </div>
                </div>
              );
              
            })}
          </div>
          
          {/* SELECTED CONVERSATION */}
          <div className="selected-conversation">
            {selectedChat ? (
              <>
                <div className="conversation-header">
                  <p className="conversation-title">
                    {selectedChat.isGroupChat === true
                    ? selectedChat.groupName
                    : otherUserData[selectedChat.participants.find((id) => id !== currentUser.uid)]?.handle || 'Unknown User'}
                  </p>
                  <div className="more-icon" onClick={toggleChatOptionsModal}>
                    <Icon.MoreVertical size={20} />
                  </div>

                  {/* OPTIONS MODAL */}
                  {isChatOptionsVisible && (
                    <div className="chat-options-modal">
                      <ul className="chat-options-list">
                        <li>Delete Conversation</li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* MESSAGES */}
                <div className="conversation">
                  <>
                      <div className="bubbles">
                        {messages.length > 0 ? (
                          messages.map((message) => (
                            <div
                              key={message.messageId}
                              className={`${message.senderId === currentUser.uid ? 'outgoing-message' : 'incoming-message'} message-row`}
                            >
                              {message.senderId !== currentUser.uid ? (
                                <div className="incoming-message message-row">
                                  <div className="user-image">
                                    <img
                                      src={otherUserData[message.senderId]?.profilePic || profilePlaceholder}
                                      alt="Sender Profile"
                                    />
                                  </div>
                                  <div className="incoming-message-bubble">
                                    <p className="message-body" style={{ whiteSpace: 'pre-wrap' }}>
                                      {parseMentions(message.content)} {/* Apply parseMentions to the message content */}
                                    </p>
                                    <p className="time">
                                    {message.timestamp?.toDate().toLocaleDateString('en-GB')} {' | '}  
                                    {message.timestamp?.toDate().toLocaleTimeString('en-US', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                    })}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="outgoing-message message-row">
                                  <div className="outgoing-message-bubble">
                                  <p className="message-body" style={{ whiteSpace: 'pre-wrap' }}>
                                    {parseMentions(message.content)} {/* Apply parseMentions to the message content */}
                                  </p>
                                    <p className="time">
                                      {message.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}  
                                      {message.timestamp?.toDate().toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                      })}
                                    </p>
                                  </div>
                                  <div className="user-image">
                                    <img src={currentUserProfilePic} alt="Your Profile" />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="empty-conversation">
                            <p className="empty-message">No messages in this conversation.</p>
                          </div>
                        )}

                        <div className="compose-message-section">
                          
                          <div className="compose-message-content">
                            <textarea
                              className="compose-message"
                              placeholder="Aa"
                              value={newMessage}
                              onChange={(e) => setNewMessage(e.target.value)}
                            />
                            <div className="send-icon" onClick={handleSendMessage}>
                              <div className="btn-icon">
                                <Icon.Send size={16} color="var(--cyan)" />
                              </div>
                              <p className="btn-label">Send</p>
                            </div>
                            <div className="vertical-divider"></div>
                            <div className="attachments">
                              <div className="image-attachment attachment-icon">
                                <Icon.Image size={20} color="var(--cyan)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> {/* closing div for bubbles */}
                  </>       
                </div> {/* closing div for conversation */}
              </>) : 
              ( 
                <div className="empty-conversation">
                  <p className="empty-message">
                    Start a conversation by clicking on the &nbsp;
                    <span> <Icon.Edit size={16} color="var(--faded-white)" style={{marginBottom: '-3px'}}/> </span> &nbsp; icon.
                  </p>
                </div>
              )
            }
 
          </div>

        </div> {/* closing div for messages main */}
    	</div>

      <div className="popup">
      <CreateMessageModal
        isVisible={isCreateMessageVisible}
        onClose={toggleCreateMessagePopup}
        onChatCreated={fetchChats} // Pass fetchChats as a callback
      />
      </div>
    </div>
  );
}

export default Messages;
