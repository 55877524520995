import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import userAvatar from '../assets/profile-placeholder.svg';
import styles from '../styles/EditProfileInformation.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import handleImageUpload from '../utils/handleImageUpload';
import Avatar from 'react-avatar-edit';



const EditProfile = () => {
  const location = useLocation();
  
  const editUID = location.state?.editUID
  const [editedFile, setEditedFile] = useState(null);
  const [handleError, setHandleError] = useState('');
  const [img, setImg] = useState(null);
  const [inputBioValue, setInputBioValue] = useState('');
  const [inputHandleValue, setInputHandleValue] = useState('');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [inputNameValue, setInputNameValue] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const [originalHandle, setOriginalHandle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [userData, setUserData] = useState(null);

  const handleNameChange = (e) => {
    setInputNameValue(e.target.value);
    setIsSaved(false);
  };

  const handleBioChange = (e) => {
    setInputBioValue(e.target.value);
    setIsSaved(false);
  };

  const handleLocationChange = (e) => {
    setInputLocationValue(e.target.value);
    setIsSaved(false);
  };

  const handleHandleChange = (e) => {
    const newHandle = e.target.value;
    const isValidHandle = /^[a-zA-Z0-9_]+$/.test(newHandle);
    const isCorrectLength = newHandle.length >= 3 && newHandle.length <= 15;

    if (!isValidHandle) {
      setHandleError('Handles can only contain letters, numbers, and underscores.');
    } else if (!isCorrectLength) {
      setHandleError('Handles must be between 3-15 characters.');
    } else {
      setHandleError('');
    }

    setInputHandleValue(newHandle);
    setIsSaved(false);
  };

  useEffect(() => {
    const fetchUserData = async (editUID) => {
      try {
        const userDocRef = doc(db, 'users', editUID);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.profilePic && userData.profilePic.startsWith('gs://')) {
            const storageRef = ref(storage, userData.profilePic);
            userData.profilePic = await getDownloadURL(storageRef);
          }
          setUserData(userData);
          setInputNameValue(userData.userName || '');
          setInputHandleValue(userData.handle || '');
          setOriginalHandle(userData.handle || '');
          setInputBioValue(userData.bio || '');
          setInputLocationValue(userData.location || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (editUID) {
        fetchUserData(editUID);
      } else if (currentUser) {
        fetchUserData(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, [editUID]);

  const checkHandleAvailability = async (handle) => {
    if (!handle || handle.length < 3 || handle.length > 15) return false;
    if (handle === originalHandle) return true;

    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('handle', '==', handle));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking handle availability:', error);
      return false;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const currentUid = editUID || auth.currentUser?.uid;
      if (editUID) {

        let isHandleAvailable = true;
        if (inputHandleValue !== originalHandle) {
          const isValidHandle = /^[a-zA-Z0-9_]+$/.test(inputHandleValue);
          const isCorrectLength = inputHandleValue.length >= 3 && inputHandleValue.length <= 15;

          if (!isValidHandle || !isCorrectLength) {
            setHandleError('Handles must be 3-15 characters and contain only letters, numbers, or underscores.');
            setLoading(false);
            return;
          }

          isHandleAvailable = await checkHandleAvailability(inputHandleValue);
          if (!isHandleAvailable) {
            setHandleError('The handle is already taken. Please choose another one.');
            setLoading(false);
            return;
          }
        }

        const userDocRef = doc(db, 'users', currentUid);
        const updatedData = {
          userName: inputNameValue,
          handle: inputHandleValue,
          bio: inputBioValue,
          location: inputLocationValue,
        };

        if (editedFile) {
          const storageRef = ref(storage, `Public/${currentUid}/${editedFile.name}`);
          await uploadBytes(storageRef, editedFile);
          updatedData.profilePic = await getDownloadURL(storageRef);
        }

        await setDoc(userDocRef, updatedData, { merge: true });
        setIsSaved(true);
        setOriginalHandle(inputHandleValue);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSavePhoto = async () => {
    if (!selectedFile) return;
    try {
      const downloadURL = await handleImageUpload(selectedFile, setUploadStatus);
      if (downloadURL && auth.currentUser) {
        const currentUid = editUID || auth.currentUser.uid;
        const userDocRef = doc(db, 'users', currentUid);
        await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true });
        setUserData((prev) => ({ ...prev, profilePic: downloadURL }));
        setEditedFile(selectedFile);
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error uploading image and updating profile:', error);
    }
  };

  return (
    <div className="edit-profile-information">
      <form className="edit-profile-form" onSubmit={handleSave}>
        <div className="profile-mobile-userphoto">
          <img src={userData?.profilePic || userAvatar} className="header-avatar" alt="User Avatar" />
          <button type="button" className="change-photo-button" onClick={() => setShowModal(true)}>
            Change Photo
          </button>
          {showModal && (
            <div className="modal" onClick={() => setShowModal(false)}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                  <span className="title">Upload a Photo</span>
                  <button className="close-button" onClick={() => {
                    setShowModal(false);
                    setImg(null);
                    setSelectedFile(null);
                  }}>
                    &times;
                  </button>
                </div>
                <div className="avatar-editor-wrapper">
                  {!img ? (
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setImg(URL.createObjectURL(file));
                        }
                      }}
                    />
                  ) : (
                    <Avatar
                      width={300}
                      height={300}
                      src={img}
                      onCrop={(preview) => {
                        fetch(preview)
                          .then(res => res.blob())
                          .then(blob => {
                            const file = new File([blob], 'cropped-image.png', { type: 'image/png' });
                            setSelectedFile(file);
                          });
                      }}
                      onClose={() => {
                        setSelectedFile(null);
                        setImg(null);
                      }}
                    />
                  )}
                </div>
                <div className="modal-buttons">
                  <button className="button" onClick={handleSavePhoto}>Save</button>
                  <button className="button" onClick={() => {
                    setShowModal(false);
                    setImg(null);
                    setSelectedFile(null);
                  }}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="input-fields-section">
          <div className="form-name form-field">
            <p className="label">NAME</p>
            <input
              className="input-field"
              placeholder="Your name"
              value={inputNameValue}
              onChange={handleNameChange}
              disabled={loading}
            />
          </div>

          <div className="form-handle form-field">
            <p className="label">HANDLE</p>
            <input
              className={`input-field ${handleError ? 'input-error' : ''}`}
              placeholder="Your handle"
              value={inputHandleValue}
              onChange={handleHandleChange}
              disabled={loading}
            />
            {handleError && (
              <div className="input-error-message">
                <p className="error-text">{handleError}</p>
              </div>
            )}
          </div>

          <div className="form-bio form-field">
            <p className="label">BIO</p>
            <input
              className="input-field"
              placeholder="Tell us about yourself"
              value={inputBioValue}
              onChange={handleBioChange}
              disabled={loading}
            />
          </div>

          <div className="form-buttons">
            <PrimaryButton 
              buttonLabel={isSaved ? "Saved" : "Save"} 
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;