// src/utils/handleImageUpload.js

import { auth, storage } from '../Firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

/**
 * Uploads an image file to Firebase Storage and returns the download URL.
 * @param {File} selectedFile - The file to upload.
 * @param {Function} setUploadStatus - Callback to update UI upload status.
 * @returns {Promise<string>} - The download URL or empty string on failure.
 */
const handleImageUpload = async (selectedFile, setUploadStatus) => {
  if (!selectedFile) {
    setUploadStatus('No file selected.');
    return '';
  }

  const uid = auth.currentUser?.uid || 'anonymous';
  const storagePath = `/Public/${uid}/${selectedFile.name}`;
  const fileRef = ref(storage, storagePath);

  try {
    console.log('handleUpload: Uploading file to:', storagePath);
    await uploadBytes(fileRef, selectedFile);
    const downloadURL = await getDownloadURL(fileRef);

    setUploadStatus('Upload successful!');
    return downloadURL;
  } catch (error) {
    console.error('Error uploading photo:', error);
    setUploadStatus('Upload failed.');
    return '';
  }
};

export default handleImageUpload;