import { collection, onSnapshot } from 'firebase/firestore';

/**
 * Fetches likes for a given post and updates state with the like count and user like status.
 * Ensures cleanup by returning an unsubscribe function.
 *
 * @param {string} postId - The ID of the post.
 * @param {object} db - Firestore database instance.
 * @param {Function} setLikeCounts - State updater for like counts.
 * @param {Function} setIsLiked - State updater for liked status.
 * @param {string} currentUserId - The ID of the current user.
 * @returns {Function} - Function to unsubscribe from Firestore listener.
 */
export const fetchLikes = (postId, db, setLikeCounts, setIsLiked, currentUserId) => {
  if (!postId || !db) {
    console.warn(`fetchLikes: Invalid postId (${postId}) or database instance.`);
    return () => {}; // Return a no-op function if invalid input is provided
  }

  console.log(` Initializing fetchLikes for post ID: ${postId}, Current User ID: ${currentUserId}`);

  const likesRef = collection(db, 'posts', postId, 'likes');
  console.log(`Firestore collection reference created: ${likesRef.path}`);

  const unsubscribe = onSnapshot(likesRef, (snapshot) => {
    console.log(`Received likes snapshot for post ID: ${postId}`);
    
    // Update like count
    const likeCount = snapshot.size;
    console.log(`Like count for post ${postId}: ${likeCount}`);

    setLikeCounts((prevCounts) => ({
      ...prevCounts,
      [postId]: likeCount,
    }));

    // Check if the current user has liked the post
    const userLiked = snapshot.docs.some((doc) => doc.id === currentUserId);
    console.log(`User liked status for post ${postId}: ${userLiked}`);

    setIsLiked((prevLikes) => ({
      ...prevLikes,
      [postId]: userLiked,
    }));
  });

  return unsubscribe; // Allow cleanup in useEffect
};