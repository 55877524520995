import { useEffect, useState, useRef } from "react";
import { auth, db, storage } from '../../Firebase';
import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import styles from '../../styles/FeaturedSection.css'
import * as Icon from 'react-feather';
import trackPlaceholder from '../../assets/track-placeholder.svg'
import merchPlaceholder from '../../assets/merch-placeholder.svg'
import eventPlaceholder from '../../assets/event-placeholder.svg'
import TertiaryButton from '../TertiaryButton'
import UploadTrack from '../UploadTrack'
import CreateEvent from '../CreateEvent'
import CreateMerch from '../CreateMerch'
import TrackDetails from '../TrackDetails'
import MerchDetails from '../MerchDetails'
import EventDetailsModal from '../EventDetailsModal'
import CreateMix from "../CreateMix";


const FeaturedSection = ( {viewUserName, userId}) => {
  console.log("User", )
  const audioRef = useRef(new Audio());
  const [currentUser, setCurrentUser] = useState(null);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null);
  const [duration, setDuration] = useState("0:00");
  const [events, setEvents] = useState([]);
  const [isEventsActive, setIsEventsActive] = useState(false);
  const [isMediaActive, setIsMediaActive] = useState(true);
  const [isMerchActive, setIsMerchActive] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [refreshEvents, setRefreshEvents] = useState(0);
  const [refreshTracks, setRefreshTracks] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [mixes, setMixes] = useState([]);
  const [refreshMixes, setRefreshMixes] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  
  const updateProgress = () => {
    if (audioRef.current && audioRef.current.duration) {
      const percentage = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(percentage);
      setCurrentTime(formatTime(audioRef.current.currentTime));
    }
  };
  
  const setAudioDuration = () => {
    if (audioRef.current && audioRef.current.duration) {
      setDuration(formatTime(audioRef.current.duration));
    }
  };
  
  const handleAudioEnd = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentTime("0:00");
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };
  
  const handleSliderChange = (e) => {
    const newTime = (e.target.value / 100) * audioRef.current.duration;
    audioRef.current.currentTime = newTime;
    setProgress(e.target.value);
    setCurrentTime(formatTime(newTime));
  };
  
  const togglePlayTrack = async (trackUrl) => {
    if (!trackUrl) {
      console.error("No audio URL found for this track.");
      return;
    }

    if (currentPlayingTrack === trackUrl) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        try {
          await audioRef.current.play();
          setIsPlaying(true);
        } catch (error) {
          console.error("Error playing audio:", error);
        }
      }
    } else {
      audioRef.current.src = trackUrl;
      audioRef.current.load();
      try {
        await audioRef.current.play();
        setCurrentPlayingTrack(trackUrl);
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const handleDeleteMix = async (mixId) => {
    try {
      await deleteDoc(doc(db, 'mixes', mixId));
      console.log("Mix deleted:", mixId);
      setRefreshMixes(prev => prev + 1);
    } catch (error) {
      console.error("Error deleting mix:", error);
    }
  };

  const handleDeleteTrack = async (trackId) => {
    try {
      await deleteDoc(doc(db, 'tracks', trackId));
      console.log("Track deleted:", trackId);
      setRefreshTracks(prev => prev + 1);
    } catch (error) {
      console.error("Error deleting track:", error);
    }
  };
  
  const authUser = auth.currentUser.uid;
  console.log("Auth User: ", authUser)

  console.log("viewUser", viewUserName)
  console.log("Received userId", userId)

  const toggleMediaTab = () => {
    setIsMediaActive(true)
    setIsMerchActive(false)
    setIsEventsActive(false)
  }

  const toggleMerchTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(true)
    setIsEventsActive(false)
  }

  const toggleEventsTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(false)
    setIsEventsActive(true)
  }

  const [isUploadTrackVisible, setIsUploadTrackVisible] = useState(false);
  const [isCreateEventVisible, setIsCreateEventVisible] = useState(false);
  const [isCreateMerchVisible, setIsCreateMerchVisible] = useState(false);
  const [isCreateMixVisible, setIsCreateMixVisible] = useState(false);
  const [isTrackDetailsVisible, setIsTrackDetailsVisible] = useState(false);
  const [isMerchDetailsVisible, setIsMerchDetailsVisible] = useState(false);
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);

  const toggleUploadTrackPopup = () => {
    setIsUploadTrackVisible(!isUploadTrackVisible);
  }

  const toggleCreateMerchPopup = () => {
    setIsCreateMerchVisible(!isCreateMerchVisible);
  }

  const toggleCreateEventPopup = () => {
    setIsCreateEventVisible(prev => !prev);
  }

  const toggleCreateMixPopup = () => {
    setIsCreateMixVisible(prev => !prev);
  }

  const toggleTrackDetailsPopup = () => {
    setIsTrackDetailsVisible(!isTrackDetailsVisible);
  }

  const toggleMerchDetailsPopup = () => {
    setIsMerchDetailsVisible(!isMerchDetailsVisible);
  }

  const toggleEventDetails = (event = null) => {
    console.log("CLICK!")
    setSelectedEvent(event);
    setIsEventDetailsVisible(!!event); // Open the modal only if an event is passed
  };



  //fetch events
  useEffect(() => {
    const fetchEvents = async () => {
      if (!userId) return;
  
      try {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef, where('promoters', 'array-contains', userId));
        // Note: orderBy should be included in the query. For example:
        // const q = query(eventsRef, where('promoters', 'array-contains', userId), orderBy('date', 'asc'));
        
        const querySnapshot = await getDocs(q);
  
        // Process each event. If thumbnail starts with 'gs://', get its downloadURL.
        const fetchedEvents = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            let eventData = doc.data();
            if (eventData.thumbnail && eventData.thumbnail.startsWith("gs://")) {
              try {
                const thumbRef = ref(storage, eventData.thumbnail);
                const downloadURL = await getDownloadURL(thumbRef);
                eventData.thumbnail = downloadURL;
              } catch (error) {
                console.error(`Error getting download URL for event ${doc.id}:`, error);
              }
            }
            return {
              id: doc.id,
              ...eventData,
            };
          })
        );
  
        // Optionally sort if needed
        const sortedEvents = fetchedEvents.sort((a, b) => a.date.toDate() - b.date.toDate());
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [currentUser, refreshEvents, userId]);
  
  //fetch tracks
  useEffect(() => {
    const fetchTracks = async () => {
      if (!userId) return;
    
      try {
        const tracksRef = collection(db, "tracks");
        const q = query(tracksRef, where("userId", "==", userId), orderBy("uploadedAt", "desc"));
        const querySnapshot = await getDocs(q);
    
        const fetchedTracks = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const trackData = doc.data();
    
            // Check if thumbnail exists and starts with "gs://"
            if (trackData.thumbnail && trackData.thumbnail.startsWith("gs://")) {
              try {
                const thumbRef = ref(storage, trackData.thumbnail);
                const downloadURL = await getDownloadURL(thumbRef);
                trackData.thumbnail = downloadURL;
              } catch (error) {
                console.error("Error generating download URL for track thumbnail:", error);
              }
            }
            
            return {
              id: doc.id,
              userId: trackData.userId,
              title: trackData.title || "Untitled Track",
              artists: Array.isArray(trackData.artists)
                ? trackData.artists.join(", ")
                : typeof trackData.artists === "string"
                  ? trackData.artists
                  : "Unknown Artist",
              releasedYear: trackData.releasedYear || "Unknown Year",
              url: trackData.url,
              thumbnail: trackData.thumbnail || null,
            };
          })
        );
    
        setTracks(fetchedTracks);
    
        console.log("Fetched Tracks: ", fetchedTracks);
        setTracks(fetchedTracks);
      } catch (error) {
        console.error("Error fetching tracks:", error);
      }
    };
  
    fetchTracks();
  }, [userId, refreshTracks]);
  
  useEffect(() => {
    const fetchMixes = async () => {
      if (!userId) return;
      try {
        const mixesRef = collection(db, "mixes");
        const q = query(mixesRef, where("userId", "==", userId), orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        console.log("Searching for mixes for userId:", userId);
        const fetchedMixes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMixes(fetchedMixes);
      } catch (error) {
        console.error("Error fetching mixes:", error);
      }
    };
    fetchMixes();
  }, [userId, refreshMixes]);

  const SoundCloudPlayer = ({ trackUrl }) => {
    return (
      <div style={{ width: "100%" }}>
        <iframe
          width="270"
          height="270"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(trackUrl)}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
        ></iframe>
        <div
          style={{
            fontSize: "10px",
            color: "#cccccc",
            lineBreak: "anywhere",
            wordBreak: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily: "'Noto Sans', sans-serif",
            fontWeight: "100",
            display: 'none'
          }}
        >
          <a href="https://soundcloud.com/eks1200" title="eks" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>
            eks
          </a>{" "}
          ·{" "}
          <a href={trackUrl} title="Back To Me - A House Mix" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>
            Back To Me - A House Mix
          </a>
        </div>
      </div>
    );
  };

const MixcloudPlayer = ({ mixUrl }) => {
  return (
    <div style={{ width: "100%" }}>
      <iframe
        width="270"
        height="270"
        src={`https://player-widget.mixcloud.com/widget/iframe/?dark=1&feed=${encodeURIComponent(mixUrl)}`}
        frameBorder="0"
      ></iframe>
    </div>
  );
};

  return (
    <div className="featured-section-component">
    	<div className="featured-section-container">

    		<div className="featured-tabs">
    			<div className="featured-tab-item tab" onClick={toggleMediaTab}>
            <p style={{ color: isMediaActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Tracks</p>
            <div className="tab-underline" style={{ backgroundColor: isMediaActive ? 'var(--cyan)' : 'transparent' }}></div>
    			</div>
          <div className="featured-tab-item tab" onClick={toggleMerchTab}>
            <p style={{ color: isMerchActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Mixes</p>
            <div className="tab-underline" style={{ backgroundColor: isMerchActive ? 'var(--cyan)' : 'transparent' }}></div>
          </div>
          <div className="featured-tab-item tab" onClick={toggleEventsTab}>
            <p style={{ color: isEventsActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Events</p>
            <div className="tab-underline" style={{ backgroundColor: isEventsActive ? 'var(--cyan)' : 'transparent' }}></div>
          </div>
    		</div>

        {/* MEDIA/MUSIC TAB */}
        { isMediaActive && (
          <div className="media-music">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            {userId === authUser && (
              <div className="upload-track" onClick={toggleUploadTrackPopup}>
                <div className="icon">
                  <Icon.Music size={18} color = "#0094D4"/>
                </div>
                <p className="">Upload new track</p>
              </div>
            )}
            
            
            {/* LIST OF TRACKS */}
            <div className="track-list">
              {tracks.length > 0 ? (
                tracks.map((track) => (
                  <div key={track.id} className="track-item">
                    <div className="track-thumbnail" onClick={toggleTrackDetailsPopup}>
                      <img src={track.thumbnail || trackPlaceholder} alt="Track Thumbnail" />
                      {console.log("displaying thumbnail for track:", track.thumbnail)}
                    </div>
                    <div className="track-details">
                      <p className="track-title">{track.title || "Untitled Track"}</p>
                      <p className="track-artists">{track.artists}</p>
                      <p className="track-album-year">{track.releasedYear || "Unknown Year"}</p>
                    </div>
                    <div className="play-button" style={{ cursor: "pointer" }} onClick={() => togglePlayTrack(track.url)}>
                      {currentPlayingTrack === track.url && isPlaying ? (
                        <Icon.Pause size={18} />
                      ) : (
                        <Icon.Play size={18} />
                      )}
                    </div>
                    {track.userId === authUser && (
                      <div
                        className="delete-button"
                        title="delete track"
                        onClick={(e) => { e.stopPropagation(); handleDeleteTrack(track.id); }}
                        style={{
                          marginLeft: 'auto',
                          cursor: 'pointer'
                        }}
                      >
                        <Icon.Trash2 size={18} color="red" />
                      </div>
                    )}
{/*}
                    { currentPlayingTrack && currentPlayingTrack === track.url && (
                      <div className="slider">
                        <audio ref={audioRef} 
                          onTimeUpdate={updateProgress} 
                          preload="metadata" 
                          onLoadedMetadata={setAudioDuration}
                          onEnded={handleAudioEnd} >
                          <source src={currentPlayingTrack} type="audio/mp3" />
                        </audio>
                        <span>{currentTime}</span>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={progress}
                          onChange={handleSliderChange}
                          className="progress-bar"
                          style={{
                            accentColor: "var(--cyan)",
                            cursor: "pointer",
                            WebkitAppearance: "none",
                            height: "3px",
                            borderRadius: "5px",
                            background: "var(--text-grey)",
                          }}
                        />
                        <span>{duration}</span>
                      </div>
                    )}
*/}
                  </div>
                ))
              ) : (
                <p className="empty-state">No tracks uploaded yet.</p>
              )}
            </div>  
          </div>
        )}

        { isMerchActive && (
          <div className="merch">
            <div className="mixes-header">
              <h3>Your Mixes</h3>
              {userId === authUser && (
                <div className="upload-merch" onClick={toggleCreateMixPopup}>
                  <div className="icon">
                    <Icon.Plus size={18} color = "#0094D4"/>
                  </div>
                  <p className="">Showcase a Mix</p>
                </div>
              )}
            </div>
             {/* LIST OF MERCH */}
            <div className="merch-list">
            {mixes.length > 0 ? (
                mixes.map((mix) => (
                  <div key={mix.id} className="merch-item" onClick={toggleMerchDetailsPopup} style={{ position: 'relative' }}>
                    {mix.platform === 'soundcloud' ? (
                      <SoundCloudPlayer trackUrl={mix.embedUrl} />
                    ) : mix.platform === 'mixcloud' ? (
                      <MixcloudPlayer mixUrl={mix.embedUrl} />
                    ) : (
                      <div>Unknown platform</div>
                    )}
                    {mix.userId === authUser && (
                      <div
                        className="delete-button"
                        title="delete mix"
                        onClick={(e) => { e.stopPropagation(); handleDeleteMix(mix.id); }}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '-12px',
                          cursor: 'pointer'
                        }}
                      >
                        <Icon.Trash2 size={25} color="red" />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="empty-state">No mixes uploaded yet.</p>
              )}
            </div>
          </div>
        )}

        {/*****  EVENTS TAB *****/}
        {isEventsActive && (
          <div className="events">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            {userId === authUser && (
              <div className="create-event" onClick={toggleCreateEventPopup} style={{ cursor: 'pointer' }}>
                <div className="icon-text">
                  <div className="icon">
                    <Icon.Calendar size={18} color="#0094D4" />
                  </div>
                  <p>Create Event</p>
                </div>
              </div>
            )}

            {/* LIST OF EVENTS */}
            {events.map((event, index) => (
              <div
                className="event-item"
                key={index}
                onClick={() => toggleEventDetails(event)}
              >
                <div className="event-thumbnail">
                  <img src={event.thumbnail || eventPlaceholder} alt={event.title || 'Event Thumbnail'} />
                </div>
                <div className="event-details">
                  <h3 className="event-title">{event.title}</h3>
                  <p className="event-date-loc">
                    <div className="event-date sub-details">
                      <span>
                        <Icon.Calendar size={11} style={{ margin: 'auto' }} />
                      </span>
                      <span className="date-text" style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}>
                      {event.date
                        ? `${event.date.toDate().toLocaleDateString('en-US')} | ${event.date.toDate().toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })}`
                        : 'Date'}
                      </span>
                    </div>
                    •

                    {event.location && (
                      <div className="event-location sub-details">
                        <span>
                          <Icon.MapPin size={11} style={{ margin: 'auto' }} />
                        </span>
                        {Array.isArray(event.location) ? (
                          event.location[1] ? (
                            <a
                              href={event.location[1]} // Google Maps link
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ fontSize: 'var(--text-md)', margin: 'auto 4px', color: 'var(--cyan)', textDecoration: 'underline' }}
                            >
                              {event.location[0]} {/* mainText */}
                            </a>
                          ) : (
                            <span
                              className="date-text"
                              style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}
                            >
                              {event.location[0]} {/* mainText */}
                            </span>
                          )
                        ) : (
                          <span
                            className="date-text"
                            style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}
                          >
                            {event.location} {/* Display string value directly */}
                          </span>
                        )}
                      </div>
                    )}
                    •

                    <div className="event-ticket sub-details">
                      <span>
                        <Icon.DollarSign size={11} style={{ margin: 'auto' }} />
                      </span>
                      <span className="date-text" style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}>
                        {event.ticketPrice ? `${event.ticketPrice}` : 'Free Admission'}
                      </span>
                    </div>
                  </p>
                  <p className="event-description" style={{ whiteSpace: 'pre-wrap'}}>
                    {event.description}
                  </p>
                  <p className="event-date-posted">Posted {event.postedAgo || 'Recently'}</p>
                </div>
              </div>
            ))}
          </div>
        )}
    	</div>

      {/* OVERLAYS / POPUPS */}

      <div className="overlays">
          <UploadTrack  isVisible={isUploadTrackVisible} onClose={toggleUploadTrackPopup} onTrackUploaded={() => setRefreshTracks(prev => prev + 1)}/>
          <CreateEvent  isVisible={isCreateEventVisible} onClose={toggleCreateEventPopup} onEventPosted={() => setRefreshEvents(prev => prev +1)}/>
          <CreateMerch  isVisible={isCreateMerchVisible} onClose={toggleCreateMerchPopup}/>
          <CreateMix  isVisible={isCreateMixVisible} onClose={toggleCreateMixPopup} onMixUploaded={() => setRefreshMixes(prev => prev + 1)}/>

          <TrackDetails isVisible={isTrackDetailsVisible} onClose={toggleTrackDetailsPopup}/>
          <MerchDetails isVisible={isMerchDetailsVisible} onClose={toggleMerchDetailsPopup}/>
          <EventDetailsModal
            isVisible={isEventDetailsVisible}
            onClose={() => toggleEventDetails(null)}
            event={selectedEvent}
          />
      </div>
    </div>
  );
}

export default FeaturedSection;